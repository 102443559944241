import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Container from '../Container'
import { useConfig } from '../Store'
import { useSession } from '../../contexts/AuthContext'

const numberOfItems = 32

const MediaViewer = ({
  value, onChange, onClose, type,
}) => {
  const history = useHistory()
  const appConfig = useConfig()
  const [data, setData] = useState([])
  const [page, setPage] = useState(0)
  const [hasMorePages, setHasMorePages] = useState(false)
  const [loading, setLoading] = useState(false)
  const token = useSession()

  useEffect(() => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}media-list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ type, page, numberOfItems }),
    })
      .then(response => response
        .json()
        .then(responseData => ({ status: response.status, body: responseData })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setData(response.body.data)
          setHasMorePages(response.body.data.length === numberOfItems)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
        }
      })
  }, [page])

  const images = []
  for (let i = 0; i < numberOfItems; i++) {
    if (typeof data[i] !== 'undefined') {
      images.push(data[i])
    } else {
      images.push(false)
    }
  }

  const ImagePreview = ({ image, index }) => {
    const url = image
      ? `${appConfig.media.public}${appConfig.media.resized}${image.preview}`
      : ''

    return (
      <div
        className="relative w-full bg-grayLight"
        style={{ paddingBottom: '100%' }}
      >
        <div
          onClick={image ? () => onChange(image._id, url) : null}
          className={`absolute inset-0 bg-center bg-no-repeat bg-contain ${image ? 'cursor-pointer' : ''}`}
          style={{ backgroundImage: `url(${url})` }}
        >
          {image.extension && (
            <div
              className="absolute bottom-0 right-0 p-1 m-2 text-xs text-center text-white"
              style={{ background: 'rgba(0, 0, 0, 0.6)' }}
            >
              {image.extension}
            </div>
          )}
        </div>
      </div>
    )
  }

  const FilePreview = ({ image }) => (
    <div
      onClick={image ? () => onChange(image._id, image.originalName, image.preview) : null}
      className="col-span-1 md:col-span-2 lg:col-span-2"
    >
      <div>
        {image ? (
          <div
            className={`${image.id === value ? 'border-2 border-black' : ''
              } bg-grayLight block p-2 cursor-pointer`}
            title="Preview"
          >
            <span>
              {image.originalName}
              &nbsp;
              <a href={`${process.env.REACT_APP_API_URL}media/download/${image._id}`}>↗</a>
            </span>
          </div>
        ) : (
          <div className="block p-2 bg-grayLight">&nbsp;</div>
        )}
      </div>
    </div>
  )

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex flex-col p-8 bg-white">
      <div>
        <Container>
          <div className="flex items-center justify-between mb-8">
            <h2 className="text-xl wght-semibold">
              {type === 'image' ? 'Images' : 'Files'}
            </h2>
            <span
              className="cursor-pointer hover:wght-semibold"
              onClick={onClose}
            >
              Close
            </span>
          </div>
        </Container>
      </div>
      <div className="flex-1 overflow-scroll">
        <div className="grid grid-cols-2 gap-1 md:grid-cols-4 lg:grid-cols-6">
          {images.map((image, index) => {
            if (type === 'image') {
              return <ImagePreview image={image} key={index} index={index} />
            }
            return <FilePreview image={image} key={index} index={index} />
          })}
        </div>
      </div>
      <div>
        <Container>
          <div className="flex justify-between mt-8">
            <span
              onClick={() => !loading && setPage(page - 1)}
              className={`mr-4 cursor-pointer hover:wght-semibold ${page > 0 ? '' : 'invisible pointer-events-none'
                }`}
            >
              Previous page
            </span>
            {hasMorePages && (
              <span
                onClick={() => !loading && setPage(page + 1)}
                className="mr-4 cursor-pointer hover:wght-semibold"
              >
                Next page
              </span>
            )}
          </div>
        </Container>
      </div>
    </div>
  )
}

export default MediaViewer
