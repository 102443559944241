import React from 'react'
import { Modal, CloseButton } from "./Modal"
import EditMultiple from './EditMultiple'

const EditMultipleModal = ({
  selectedItems,
  showModal,
  setShowModal,
  modelName,
}) => {
  if (!showModal) return null

  return (
    <Modal setShowModal={setShowModal} showModal={showModal}>
      <div className="relative w-2/3 pb-0 overflow-auto bg-white z-1 max-h-128">
        <CloseButton setShowModal={setShowModal} />
        <div className="w-full p-8 pb-2 h-fit">
          <h2 className="text-xl wght-semibold">Edit multiple invitations</h2>
          <h3 className="text-sm text-gray wght-light">
            {selectedItems.length} invitations selected
          </h3>
        </div>
        <div className="w-full p-8 pt-2 pb-0">
          <h3 className="text-sm text-orange wght-light">
            Empty fields will not update the related property of invitation.
          </h3>
        </div>
        {selectedItems ? (
          <EditMultiple
            modelsToShow={['ticketType', 'rulesZone']}
            selectedItems={selectedItems}
            modelName={modelName}
          />
        ) : (
          <div>Any selected items</div>
        )}
      </div>
    </Modal>
  )
}

export default EditMultipleModal