import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Container from './Container'
import NoAccessPublic from './Public/NoAccessPublic'
import GetZoneInfo from './Public/GetZoneInfo'

const Public = () => {
  return (
    <div className='h-screen px-4 py-4 md:py-8 md:mx-auto md:w-10/12'>
      <Container>
        <Switch>
          <Route path="/public/zones/:id">
            <GetZoneInfo />
          </Route>
          <Route path="/public/no-access">
            <NoAccessPublic />
          </Route>
          <Route path="/public/">
            <NoAccessPublic />
          </Route>
        </Switch>
      </Container>
    </div>
  )
}

export default Public