import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { ListDiceTicketsForPhoneNumber } from '../../externalApi/gql.services'
import { useSession } from '../../contexts/AuthContext'

const PhoneDice = () => {
  const title = 'Phone Dice tickets'
  const { name: modelName, page: pageParam } = useParams()
  const [data, setData] = useState(false)
  const [page, setPage] = useState(pageParam - 1 || false)
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const token = useSession()

  useEffect(() => {
    setLoading(true)
    getData()
  }, [page, modelName])

  useEffect(() => {
  }, [loading])

  const getData = async () => {
    setData(false)

    try {
      const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
        method: 'POST',
        headers: {
          Authorization: `${token}`,
          'AccessTicket-App': 'manager',
        },
        body: JSON.stringify({
          query: ListDiceTicketsForPhoneNumber,
          variables: {
            phoneNumber: searchValue,
          },
        }),
      })
      if (res) {
        const response = await res.json()
        const rawBody = [...response.data.listDiceTicketsForPhoneNumber]
        setData(rawBody)
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const clearFilters = () => {
    setSearchValue('')
    filterPhones(true)
  }
  
  const filterPhones = (clear = false) => {
    if (clear) {
      setData()
      return
    }
    setLoading(true)
    if (page === 0) {
      getData()
    } else {
      setPage(0)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      filterPhones()
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {loading ? <div className="mt-8">Loading...</div> :
        <>
          <div className="w-full mb-2 sm:mb-0 sm:w-auto">
            <div className="flex flex-col md:flex-row">
              <input
                name="search"
                className={`rounded px-2 bg-white outline-none placeholder-gray mr-2`}
                type="text"
                placeholder="Search phone"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              {loading ? <div>Loading...</div> : <>
                <span className="mr-2 cursor-pointer hover:wght-semibold" onClick={() => filterPhones()}>Search</span>
                <span className="mr-2">|</span>
                <span className="mr-2 cursor-pointer hover:wght-semibold" onClick={() => clearFilters()}>Reset</span>
              </>}
            </div>
          </div>
          <div className="mt-4 overflow-scroll text-xs"><table className="w-full">
            <thead className="w-full text-left bg-white border-b border-grayLight">
              <tr>
              <th className=""></th>
              <th className="px-4 py-2">Ticket ID</th>
              <th className="px-4 py-2">Claimed At</th>
              <th className="px-4 py-2">Email</th>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">Birthdate</th>
              <th className="px-4 py-2">Title</th>
              </tr>
            </thead>
            <tbody>
              {data && data.map((item) => 
                <tr key={item._id} className={`relative border-b bg-white border-grayLight hover:bg-primaryLight`}>
                    
                  <td className="w-8 p-0">
                    {item.exchanged === true && <div title={'Exchanged'} className={`w-8 h-9 text-xxs bg-green-300 cursor-default`}></div>}
                    {item.exchanged === false && <div title={'Exchanged'} className={`w-8 h-9 text-xxs bg-warning cursor-default`}></div>}
                  </td>

                  <td><div className={`cursor-pointer px-4 py-2 whitespace-nowrap`}>{item.code}</div></td>
                  <td><div className={`cursor-pointer px-4 py-2 whitespace-nowrap`}>{item.claimedAt}</div></td>
                  <td><div className={`cursor-pointer px-4 py-2 whitespace-nowrap`}>{item.holder?.email}</div></td>
                  <td><div className={`cursor-pointer px-4 py-2 whitespace-nowrap`}>{item.holder?.firstName}{item.holder?.lastName && ` ${item.holder?.lastName}`}</div></td>
                  <td><div className={`cursor-pointer px-4 py-2 whitespace-nowrap`}>{item.holder?.dob}</div></td>
                  <td><div className={`cursor-pointer px-4 py-2 whitespace-nowrap`}>{item.title}</div></td>
                </tr>
              )}
            </tbody>
          </table></div>
        </>
      }
      {data && data.length === 0 && <div className='mt-2'>Nothing to list</div>}
    </>
  )
}

export default PhoneDice