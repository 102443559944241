import React from 'react'

const conditionalRender = ({
  conditionalRender,
  conditionalRenderMessage,
  storedData,
  label,
}) => {
  let hideInput = true
  if (!conditionalRender) return false

  Object.entries(conditionalRender).forEach(([key, value]) => {
    if (value === 'empty') {
      if (storedData[key] === null ||
        storedData[key] === undefined ||
        storedData[key] === '' ||
        storedData[key].length === 0
      ) {
        hideInput = false
      } else {
        hideInput = true
      }
    }
  })

  if (hideInput) {
    if (conditionalRenderMessage) {
      return (
        <div>
          <label>{label}</label>
          <div className="text-gray">
            {conditionalRenderMessage}
          </div>
        </div>
      )
    }
    return <></>
  } else {
    return false
  }
}

export default conditionalRender
