import Cookies from "js-cookie";

const request = async ({ url, method, headers, body }) => {
  try {
    const defaultBody = {
      ...body,
      organization: Cookies.get('organization'),
      evet: Cookies.get('event'),
    }

    const response = await fetch(url, {
      method,
      headers,
      body: JSON.stringify(defaultBody),
    });

    return await response.json();
  } catch (error) {
    return error
  }
}

const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#FFC4AD',
    primary50: '#ffb293',
    primary: '#0300c9',
    neutral0: '#eee',
  },
  input: (styles) => ({ ...styles, borderColor: 'red' }),
})

const selectStyles = {
  control: (styles) => ({ ...styles, borderColor: "#eee", minHeight: '20px' }),
  valueContainer: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
  input: (styles) => ({ ...styles, marginTop: 2, marginBottom: 1 }),
  dropdownIndicator: (styles) => ({ ...styles, padding: 2 }),
  clearIndicator: (styles) => ({ ...styles, padding: 2 }),
  menuList: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
}


export {
  request,
  selectTheme,
  selectStyles,
}