import React, { useState, useEffect } from 'react'
import { useParams, useHistory, NavLink } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import useLocalStorage from '../hooks/useLocalStorage'
import dayjs from 'dayjs'
import { useSession } from '../contexts/AuthContext'
import useOrganizationInfo from '../hooks/useOrganizationInfo'

const StatsEventZone = () => {
  const { name: modelName, id, page: pageParam, type: typeParam, event } = useParams()
  const { getOrganization, getEvent } = useOrganizationInfo(event)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(`Stats: ${modelName}`)
  const [page, setPage] = useState(pageParam - 1 || 0)
  const [hasMorePages, setHasMorePages] = useState(false)
  const pageLimit = 500
  const [typeEphemerals, setTypeEphemerals] = useState(false)
  // TODO change pageLimit from manager

  const [userSortSettings, setUserSortSettings] = useLocalStorage(
    'user_sort_settings',
    {}
  )
  const [sortedValue, setSortedValue] = useState('date')
  const [sortAscending, setSortAscending] = useState(false)
  const [sortedItems, setSortedItems] = useState()
  const [statsType, setStatsType] = useState(typeParam || 'live')
  const [showPurchacsesTab, setShowPurchacsesTab] = useState(false)

  const [capacity, setCapacity] = useState()
  const [occupancy, setOccupancy] = useState()
  const [graphMarks, setGraphMarks] = useState([])
  const [graphPosition, setGraphPosition] = useState(0)

  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'month').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const token = useSession()


  useEffect(() => {
    if (loading) {
      getData()
    }
  }, [loading])

  useEffect(() => {
    setLoading(true)

    const urlParts = history.location.pathname.split(id)
    if (page) {
      const updatePageUrl = `${urlParts[0]}${id}/${statsType}/${page + 1}`
      history.replace({ pathname: updatePageUrl })
    } else {
      const updatePageUrl = `${urlParts[0]}${id}/${statsType}`
      history.replace({ pathname: updatePageUrl })
    }
  }, [page, startDate, endDate])

  useEffect(() => {
    const urlParts = history.location.pathname.split(id)
    history.replace({ pathname: `${urlParts[0]}${id}/${statsType}` })
    setHasMorePages()
    setLoading(true)
  }, [statsType])

  useEffect(() => {
    if (statsType === 'live' && capacity) {
      updateGraph(parseInt(occupancy) || 0, parseInt(capacity) || 0)
    }
  }, [capacity, occupancy])

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}model-stats`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        id,
        statsType,
        startDate,
        endDate: dayjs(endDate).add(1, 'day').format('YYYY-MM-DD'),
        page,
        pageLimit,
        organization: getOrganization(),
        event: getEvent(),
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setTitle(`Stats: ${response.body.eventLocation.title}`)
          if (response.body.eventLocation.accessType !== 'bar') {
            setShowPurchacsesTab(false)
            setTypeEphemerals(false)
          } else {
            setShowPurchacsesTab(true)
            setTypeEphemerals(true)
          }
          setData(response.body)
          if (response.body && response.body.stats) {
            setHasMorePages(response.body.stats.length === pageLimit)
          }

          const rawCapacity =
            typeof response.body.capacity === 'number'
              ? response.body.capacity.toString()
              : response.body.capacity
          setCapacity(rawCapacity)

          setOccupancy(response.body.occupancy)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.body.error === 'accestype_not_bar') {
            setStatsType('live')
            setShowPurchacsesTab(false)
          }
          setData({})
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
        }
      })
  }

  const updateGraph = (value, currentCapacity) => {
    const step = Math.round(currentCapacity / 6)
    const marks = []
    let newMark = step || 1
    while (newMark < currentCapacity) {
      marks.push(newMark)
      newMark += step || 1
    }
    setGraphMarks(marks)
    const bar = document.querySelector('.occupancyBarGraph')
    if (bar) {
      setGraphPosition((value / currentCapacity) * bar.offsetWidth)
    }
  }

  useEffect(() => {
    const settings = { ...userSortSettings }
    if (
      typeof sortedValue === 'undefined' ||
      typeof sortAscending === 'undefined'
    ) {
      delete settings[modelName]
    } else {
      settings[modelName] = {
        value: sortedValue,
        ascending: sortAscending,
      }
    }
    setUserSortSettings(settings)
  }, [sortedValue, sortAscending])

  const handleSort = (key) => {
    if (sortedValue === key) {
      if (sortAscending) {
        setSortAscending(false)
      } else {
        setSortedValue(undefined)
        setSortAscending(undefined)
      }
    } else {
      setSortedValue(key)
      setSortAscending(true)
    }
  }

  useEffect(() => {
    if (
      typeof data === 'object' &&
      typeof data.stats !== 'undefined' &&
      data.stats
    ) {
      const stats = [...data.stats]
      if (typeof sortedValue !== 'undefined') {
        const sorted = stats.sort((a, b) => {
          const itemA =
            typeof a[sortedValue] == 'string'
              ? a[sortedValue].toLowerCase()
              : undefined
          const itemB =
            typeof b[sortedValue] == 'string'
              ? b[sortedValue].toLowerCase()
              : undefined

          if (typeof itemA === 'undefined' && typeof itemB === 'undefined')
            return 0
          if (typeof itemA === 'undefined') return sortAscending ? 1 : -1
          if (typeof itemB === 'undefined') return sortAscending ? -1 : 1

          if (itemA < itemB) return sortAscending ? -1 : 1
          if (itemA > itemB) return sortAscending ? 1 : -1
          return 0
        })
        setSortedItems(sorted)
      } else {
        setSortedItems(data.stats)
      }
    }
  }, [data, sortedValue, sortAscending, modelName])

  const onChangeDate = (e, type) => {
    const newDate = e.target.value
    if (type === 'startDate') {
      setStartDate(newDate)
    } else {
      setEndDate(newDate)
    }
  }

  const changeStatsType = (value) => {
    if (!loading) {
      setData()
      setStatsType(value)
    }
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mb-2">
          <p className="mr-2 wght-semibold">Type:</p>
          <p
            className={`mx-1 cursor-pointer hover:wght-semibold ${statsType === 'live' ? 'text-primary' : ''
              } hover:text-primary`}
            onClick={() => changeStatsType('live')}
          >
            Live entrances
          </p>
          &nbsp;|&nbsp;
          <p
            className={`mx-1 cursor-pointer hover:wght-semibold ${statsType === 'historical' ? 'text-primary' : ''
              } hover:text-primary`}
            onClick={() => changeStatsType('historical')}
          >
            Historical entrances
          </p>
          {showPurchacsesTab &&
            <>
              &nbsp;|&nbsp;
              <p
                className={`mx-1 cursor-pointer hover:wght-semibold ${statsType === 'purchases' ? 'text-primary' : ''
                  } hover:text-primary`}
                onClick={() => changeStatsType('purchases')}
              >
                Historical purchases
              </p>
            </>
          }
        </div>
      </div>

      {(statsType === 'historical' || statsType === 'purchases') && (
        <div className="flex items-center my-4">
          <span className="mr-2 wght-semibold">Period</span>
          <div>
            <div className="flex flex-col md:flex-row">
              <input
                name="startDate"
                className={`rounded px-2 bg-white outline-none placeholder-gray mr-2`}
                type="date"
                placeholder="Start date"
                value={startDate}
                onChange={(e) => onChangeDate(e, 'startDate')}
              />
              <input
                name="endDate"
                className={`rounded px-2 bg-white outline-none placeholder-gray mr-2`}
                type="date"
                placeholder="End date"
                value={endDate}
                onChange={(e) => onChangeDate(e, 'endDate')}
              />
            </div>
          </div>
        </div>
      )}

      {(!loading || occupancy) && statsType === 'live' ? (
        <div>
          <div>
            <h3 className="mb-2">
              Occupancy:
              {capacity && (
                <span className="wght-semibold">
                  &nbsp;&nbsp;&nbsp;{occupancy}
                  {capacity !== '0' ? `/ ${capacity}` : ''}
                </span>
              )}
            </h3>
          </div>
          {capacity !== '0' && (
            <div className={`w-full mb-2`}>
              <div className="relative w-full h-4 bg-white">
                <div
                  className="absolute top-0 left-0 w-full h-4 bg-gradient-to-r from-red-500 occupancyBarGraph"
                  style={{
                    background:
                      'linear-gradient(90deg, rgba(82,238,204,1) 0%, rgba(255,125,87,1) 73%, rgba(187,26,0,1) 100%)',
                    clip: `rect(0, ${graphPosition}px, 1000px, 0)`,
                    transition: 'clip 1s',
                  }}
                ></div>
              </div>
              {statsType === 'live' && (
                <div className="flex justify-between w-full">
                  <span className="text-xs">0</span>
                  {graphMarks.map((mark, index) => (
                    <span key={index} className="text-xs">
                      {mark}
                    </span>
                  ))}
                  <span className="text-xs">{capacity}</span>
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}

      {data && (!data.stats || data.stats.length === 0) && (
        <div className="mt-8">Nothing to list</div>
      )}
      {data && data.stats && data.stats.length > 0 && (
        <div className="overflow-x-scroll">
          {(statsType !== 'purchases') ?
            <table className="w-full text-xs">
              <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                <tr>
                  <th className="px-4 py-2">
                    <span
                      className="relative cursor-pointer wght-semibold"
                      onClick={() => handleSort('date')}
                    >
                      Date
                    </span>
                    {sortedValue === 'date' && (
                      <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                    )}
                  </th>
                  {statsType === 'historical' &&
                    <th className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort('type')}
                      >
                        Type
                      </span>
                      {sortedValue === 'type' && (
                        <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                      )}
                    </th>
                  }
                  <th className="px-4 py-2">
                    <span
                      className="relative cursor-pointer wght-semibold"
                      onClick={() => handleSort('name')}
                    >
                      Name
                    </span>
                    {sortedValue === 'name' && (
                      <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                    )}
                  </th>
                  {!typeEphemerals &&
                    <th className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort('email')}
                      >
                        Email
                      </span>
                      {sortedValue === 'email' && (
                        <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                      )}
                    </th>
                  }
                  <th className="px-4 py-2">
                    <span className="relative cursor-pointer wght-semibold">
                      {!typeEphemerals ? 'Ticket ID' : 'Access ID'}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedItems &&
                  sortedItems.map((info, index) => (
                    <tr
                      key={index}
                      className={`relative text-left border-b border-grayLight hover:bg-primaryLight
                      ${statsType !== 'historical' ? 'bg-white' : ''}
                      ${(statsType === 'historical' && info.type === 1) ? 'bg-lightGreen' : ''}
                      ${(statsType === 'historical' && info.type === -1) ? 'bg-lightRose' : ''}
                      `}
                    >
                      <th className="px-4 py-2">
                        {dayjs(info.date).format('DD/MM/YYYY HH:mm:ss')}
                      </th>
                      {statsType === 'historical' &&
                        <th className="px-4 py-2">
                          {(info.type === 1) ? 'IN' : 'OUT'}
                        </th>
                      }
                      <th className="px-4 py-2">
                        {!typeEphemerals ?
                          <NavLink
                            className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                            to={`/admin/user/${info.userId}/${info.profileId}`}
                          >
                            {info.firstName || info.lastName
                              ? `${info.firstName} ${info.lastName}`
                              : `${info.profileId}`}
                          </NavLink>
                          : `${info.name}`
                        }
                      </th>
                      {!typeEphemerals &&
                        <th className="px-4 py-2">
                          <NavLink
                            className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                            to={`/admin/user/${info.userId}/${info.profileId}`}
                          >
                            {info.email ? `${info.email}` : `${info.profileId}`}
                          </NavLink>
                        </th>
                      }
                      <th className="px-4 py-2">
                        {!typeEphemerals ?
                          <NavLink
                            className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                            to={`/admin/user/${info.userId}/${info.profileId}/${info.id}`}
                          >
                            {info.id}
                          </NavLink>
                          : `${info.id}`
                        }
                      </th>
                    </tr>
                  ))}
              </tbody>
            </table>
            :
            <table className="w-full text-xs">
              <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                <tr>
                  <th className="px-4 py-2">
                    {/* // todo change creationDate for updateDate sort */}
                    <span
                      className="relative cursor-pointer wght-semibold"
                      onClick={() => handleSort('creationDate')}
                    >
                      Date
                    </span>
                    {sortedValue === 'creationDate' && (
                      <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th className="px-4 py-2">
                    <span className="relative cursor-default wght-semibold">Items</span>
                  </th>
                  <th className="px-4 py-2">
                    <span
                      className="relative cursor-pointer wght-semibold"
                      onClick={() => handleSort('totalPrice')}
                    >
                      Total price
                    </span>
                    {sortedValue === 'totalPrice' && (
                      <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th className="px-4 py-2">
                    <span
                      className="relative cursor-pointer wght-semibold"
                      onClick={() => handleSort('fullName')}
                    >
                      Client
                    </span>
                    {sortedValue === 'fullName' && (
                      <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th className="px-4 py-2">
                    <span
                      className="relative cursor-pointer wght-semibold"
                      onClick={() => handleSort('age')}
                    >
                      Age
                    </span>
                    {sortedValue === 'age' && (
                      <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th className="px-4 py-2">
                    <span
                      className="relative cursor-pointer wght-semibold"
                      onClick={() => handleSort('employeeFullName')}
                    >
                      Employee
                    </span>
                    {sortedValue === 'employeeFullName' && (
                      <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                    )}
                  </th>
                  <th className="px-4 py-2">
                    <span className="relative cursor-default wght-semibold">View</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedItems &&
                  sortedItems.map((info, index) => (
                    <tr
                      key={index}
                      className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight"
                    >
                      <th className="px-4 py-2">
                        {info.updateDate ?
                          dayjs(info.updateDate).format('DD/MM/YYYY HH:mm:ss')
                          :
                          dayjs(info.creationDate).format('DD/MM/YYYY HH:mm:ss')
                        }
                      </th>
                      <th className="px-4 py-2 whitespace-pre">
                        {info.products}
                      </th>
                      <th className="px-4 py-2">
                        {info.totalPrice}
                      </th>
                      <th className="px-4 py-2">
                        <NavLink
                          className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                          to={`/admin/user/${info.userId}/${info.profileId}`}
                        >
                          {info.fullName
                            ? `${info.fullName}`
                            : `${info.profileId}`}
                        </NavLink>
                      </th>
                      <th className={`px-4 py-2 ${(info.age < 18) ? 'text-error' : ''}`}>
                        {info.age}
                      </th>
                      <th className="px-4 py-2">
                        {info.employee && (
                          info.employee.name
                            ? `${info.employee.name}`
                            : `${info.employee}`
                        )}
                      </th>
                      <th className="px-4 py-2">
                        <NavLink
                          className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                          to={`/admin/${event}/purchase/${info.id}`}
                        >
                          View
                        </NavLink>
                      </th>
                    </tr>
                  ))}
              </tbody>
            </table>
          }
        </div>
      )}
      {loading && <div className={`mt-8 ml-1`}>Loading...</div>}
      {(hasMorePages || page > 0) && !loading && (
        <div className="flex justify-between mt-8">
          <div>
            {page > 0 && (
              <span
                onClick={() => setPage(page - 1)}
                className="ml-1 cursor-pointer hover:wght-semibold"
              >
                Previous page
              </span>
            )}
          </div>
          {hasMorePages && (
            <span
              onClick={() => setPage(page + 1)}
              className="mr-1 cursor-pointer hover:wght-semibold"
            >
              Next page
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default StatsEventZone
