import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'
import { Modal, CloseButton } from './Modal'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import { useSession } from '../contexts/AuthContext'

const ModalPublicPage = ({
  data,
  event,
  modelName,
  showModalPublicPage,
  setShowModalPublicPage,
  publicPageId,
  setPublicPageId,
}) => {
  const [updatingPublicPage, setUpdatingPublicPage] = useState(false)
  const { getOrganization, getEvent } = useOrganizationInfo(event)
  const [showCopiedText, setShowCopiedText] = useState(false)
  const token = useSession()

  const updatePublicPage = async () => {
    setUpdatingPublicPage(true)

    const response = await fetch(`${process.env.REACT_APP_API_URL}public-page-update`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        organization: getOrganization(),
        event: getEvent(),
        modelName,
        id: showModalPublicPage,
        publicPageId: publicPageId,
      })
    })

    const data = await response.json()
    setPublicPageId(data.publicPageId || false)
    setUpdatingPublicPage(false)
  }

  const url = `/public/${data?.modelConfig?.publicUrl}/${publicPageId}`
  
  return <>
    <Modal setShowModal={setShowModalPublicPage} showModal={!!showModalPublicPage}>
      <div className="relative w-2/3 pb-2 overflow-auto bg-white sm:w-1/2 xl:w-1/3 z-1 max-h-128">
        <CloseButton setShowModal={setShowModalPublicPage} />
        <div className="w-full p-8 pb-2 h-fit">
          <h2 className="text-xl wght-semibold">Public page</h2>
        </div>
        <div className="w-full p-8 pt-2">
          { updatingPublicPage && <p className="text-base">Updating...</p> }
          { !updatingPublicPage && publicPageId &&
            <p className={`text-base`}>
              {!showCopiedText && <a target="_blank" href={`${window.location.origin}${url}`} className=' hover:wght-semibold text-primary'>{url}</a> }
              {showCopiedText && <span className="text-success">Copied!</span>}
            </p>
          }
        </div>

        <div className="flex justify-center w-full p-8 pt-2">
          <Button disable={updatingPublicPage} visibility={!publicPageId} onClick={updatePublicPage} color='primary'>Enable public page</Button>
          <Button disable={updatingPublicPage} visibility={!!publicPageId} onClick={updatePublicPage} color='cancel' className='mr-2'>Disable public page</Button>
          <Button disable={updatingPublicPage} visibility={!!publicPageId} onClick={() => {
            navigator.clipboard.writeText(`${window.location.origin}${url}`)
            setShowCopiedText(true)
            setTimeout(() => {
              setShowCopiedText(false)
            }, 3000)
          }} color='primary'>Copy URL</Button>
        </div>
      </div>
    </Modal>
  </>
}

export default ModalPublicPage
