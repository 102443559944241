import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'
import { parseField } from '../Lib/utils'
import Pagination from '../Pagination'
import { getUserPreference, updateCookiePreferences } from '../Lib/utils'
import Button from '../Button'
import debounce from 'lodash/debounce'

const BatchMessages = () => {
  const history = useHistory()
  const { page: pageParam, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [data, setData] = useState(false)
  const [page, setPage] = useState((pageParam || 1) - 1 || false)
  const [hasMorePages, setHasMorePages] = useState(false)
  const pageLimit = 50
  const [loading, setLoading] = useState(true)
  const [notificationText, setNotificationText] = useState('')
  const [role, setRole] = useState(false)
  const token = useSession()
  const [modelName, setModelName] = useState(getUserPreference({ parentKey: 'BatchMessages', key: 'modelName', defaultValue: 'BatchEmailsTemplate' }))
  const [selectedBatch, setSelectedBatch] = useState(history.location.search && history.location.search.split('&')?.find(param => param.includes('batch='))?.split('=')[1])
  const title = !selectedBatch ? 'Batch Messages' : `Batch details - ${selectedBatch}`
  const [invitationInputValue, setInvitationInputValue] = useState("")
  const debounced = useRef(debounce((fn, value) => fn(value), 300))
  const [searching, setSearching] = useState(false)

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}batch-messages`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        endpoint: 'batchMessages',
        organization: getOrganization(),
        event: getEvent(),
        selectedBatch,
        page,
        pageLimit,
        searchValue: invitationInputValue,
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        setSearching(false)
        if (response.status === 200) {
          setRole(response.body.role)
          const rawBody = { ...response.body }
          setData(rawBody)
          if (response.body.pageLimit) {
            setHasMorePages(response.body.items?.length === response.body.pageLimit)
          } else {
            setHasMorePages(response.body.items?.length === pageLimit)
          }
        } else {
          setData(false)
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }
  useEffect(() => {
    if (pageParam && (pageParam - 1) !== page) {
      setPage(pageParam - 1)
    }
  }, [pageParam])
  

  useEffect(() => {
    updateCookiePreferences({ parentKey: 'BatchMessages', key: 'modelName', value: modelName })
    setLoading(true)
    getData()
  }, [modelName, selectedBatch])

  useEffect(() => {
    if (!loading) {
      setLoading(true)
      getData()
  
      const urlParts = history.location.pathname.split('BatchMessages')
  
      if (urlParts[0].includes('no-access')) {
        return
      }

      if (page) {
        const updatePageUrl = `${urlParts[0]}${'BatchMessages'}/${page + 1}`
        history.push({ pathname: updatePageUrl, search: history.location.search })
      } else {
        const updatePageUrl = `${urlParts[0]}${'BatchMessages'}`
        history.push({ pathname: updatePageUrl, search: history.location.search })
      }
    }
  }, [page])

  useEffect(() => {
    if (notificationText) {
      setTimeout(() => {
        setNotificationText('')
      }, 3000);
    }
  }, [notificationText])

  useEffect(() => {
    if (history.location.search) {
      const batchId = history.location.search.split('&').find(param => param.includes('batch=')).split('=')[1]
      if (selectedBatch !== batchId) {
        setSelectedBatch(batchId)
        setPage(0)
      }
    } else {
      if (selectedBatch) {
        setSelectedBatch(false)
        setPage(0)
      }
    }
  }, [history.location.search])

  const removeBatchId = () => {
    setSelectedBatch(false)
    setPage(0)
    const url = history.location.pathname
    history.push(url)
  }

  useEffect(() => {
    if (invitationInputValue && invitationInputValue.length > 2 || invitationInputValue === '') {
      debounced.current(getData, data)
      setSearching(true)
    }
  }, [invitationInputValue])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
        {selectedBatch &&
          <div className="mt-4 mb-2 h-fit">
            <Button size="small" color="white" onClick={() => removeBatchId()}>Back to list</Button>
          </div>
        }
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      {
        !selectedBatch &&
        <div className="mt-4 mb-2 h-fit">
          <p className="text-xs select-none">Type:</p>
          <select className="px-2 py-1 mt-1 text-xs border rounded" name="template" id="template" onChange={(e) => setModelName(e.target.value)} value={modelName}>
            <option value="BatchEmailsTemplate">Email</option>
            <option value="BatchNotificationsTemplate">Notification</option>
          </select>
        </div>
      }


      {notificationText &&
        <span className='fixed z-10 px-2 py-1 text-sm border-2 rounded bottom-5 right-5 bg-purple'>{notificationText}</span>
      }

      {loading && <div className="mt-8">Loading...</div>}

      {!loading && data?.batchInfo &&
        <div className="p-4 mt-8 bg-white ">
          <div className="flex items-center justify-between">
            <h3 className="text-lg wght-semibold">Batch details</h3>
          </div>
          <div className='flex flex-col md:flex-row'>
            <div className='flex flex-col md:w-1/2'>
              <div className="flex items-center mt-4">
                <span className="mr-1">Status:</span>
                <span className={`px-2 py-1 text-xs rounded ${data.batchInfo.status === 'sent' ? 'bg-green' : 'bg-grayLight'}`}>{data.batchInfo.status}</span>
              </div>

              <div className="flex flex-wrap mt-4">
                <div className="flex items-center">
                  <span className="mr-1">Sent:</span>
                  <span className={`px-2 text-xs rounded mr-2 ${data.batchInfo.sentEmails === '0' || !data.batchInfo.sentEmails ? 'bg-grayLight' : 'bg-success text-white'}`}>
                    {data.batchInfo.sentEmails || '0'}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="mr-1">Pending:</span>
                  <span className={`px-2 text-xs rounded mr-2 ${data.batchInfo.emailsToSend === '0' || !data.batchInfo.emailsToSend ? 'bg-grayLight' : 'bg-yellow'}`}>
                    {data.batchInfo.emailsToSend || '0'}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="mr-1">Rejected:</span>
                  <span className={`px-2 text-xs rounded mr-2 ${data.batchInfo.rejectedEmails === '0' || !data.batchInfo.rejectedEmails ? 'bg-grayLight' : 'bg-orange'}`}>
                    {data.batchInfo.rejectedEmails || '0'}  
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="mr-1">Opened:</span>
                  <span className={`px-2 text-xs rounded mr-2 ${data.batchInfo.opened === '0' || !data.batchInfo.opened ? 'bg-grayLight' : 'bg-purpleDark text-white'}`}>
                    {data.batchInfo.opened || '0'}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="mr-1">Clicked:</span>
                  <span className={`px-2 text-xs rounded mr-2 ${data.batchInfo.clicked === '0' || !data.batchInfo.clicked ? 'bg-grayLight' : 'bg-purpleDark text-white'}`}>
                    {data.batchInfo.clicked || '0'}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="mr-1">Bounced:</span>
                  <span className={`px-2 text-xs rounded mr-2 ${data.batchInfo.bounced === '0' || !data.batchInfo.bounced ? 'bg-grayLight' : 'bg-orange'}`}>
                    {data.batchInfo.bounced || '0'}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="mr-1">Spam:</span>
                  <span className={`px-2 text-xs rounded mr-2 ${data.batchInfo.spam === '0' || !data.batchInfo.spam ? 'bg-grayLight' : 'bg-orange'}`}>
                    {data.batchInfo.spam || '0'}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="mr-1">Rejected:</span>
                  <span className={`px-2 text-xs rounded mr-2 ${data.batchInfo.rejectedEmails === '0' || !data.batchInfo.rejectedEmails ? 'bg-grayLight' : 'bg-orange'}`}>
                    {data.batchInfo.rejectedEmails || '0'}
                  </span>
                </div>
                <div className="flex items-center">
                  <span className="mr-1">Missing email:</span>
                  <span className={`px-2 text-xs rounded mr-2 ${data.batchInfo.missingEmails === '0' || !data.batchInfo.missingEmails ? 'bg-grayLight' : 'bg-orange'}`}>
                    {data.batchInfo.missingEmails || '0'}
                  </span>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex items-center">
                  <span className="mr-2">Template:</span>
                  <span className="px-2 py-1 text-xs rounded bg-grayLight">{data.batchInfo.template}</span>
                </div>
              </div>
              <div className="flex flex-col mt-4 xl:items-top xl:flex-row">
                <div className="mb-4 mr-4 xl:mb-0">
                  <span className="mr-2">Created:</span>
                  <span className="px-2 py-1 text-xs rounded whitespace-nowrap bg-grayLight">{dayjs(data.batchInfo.creationDate).format('YYYY-MM-DD HH:mm:ss')}</span>
                </div>
                <div className="">
                  <span className="mr-2">Last update:</span>
                  <span className="px-2 py-1 text-xs rounded whitespace-nowrap bg-grayLight">{dayjs(data.batchInfo.updateDate).format('YYYY-MM-DD HH:mm:ss')}</span>
                </div>
              </div>

            </div>

            <div className='flex flex-col md:ml-2'>
              <div className="mt-4">
                <div className="flex items-center">
                  <span className="mr-2">Segment:</span>
                  <span className="px-2 py-1 text-xs rounded bg-grayLight">{data.batchInfo.segment?.label || '-'}</span>
                </div>
              </div>
              <div className="mt-4">
                <p className="mr-2">Filters:</p>
                <span className="">{data.batchInfo.filters ? <table className='px-2 py-1 text-xs rounded bg-grayLight'>
                  <thead >
                    <tr className='border-b border-white'>
                      <th>Filter</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(data.batchInfo.filters).filter(i => !['', 'filterTemplate', 'event', 'organization'].includes(i) && data.batchInfo.filters[i]).map((filterKey, index) => {
                      return (
                        <tr key={index} className={``} >
                          <td className={`px-2 py-1 ${index !== (Object.keys(data.batchInfo.filters).length - 1) && 'border-b border-white'}`}>{filterKey.startsWith('ticketType') ? filterKey.split('-')[0] : filterKey}</td>
                          <td className={`px-2 py-1 ${index !== (Object.keys(data.batchInfo.filters).length - 1) && 'border-b border-white'}`}>{data.batchInfo.filters[filterKey].replace('\\', '')}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                  : <span className='px-2 py-1 text-xs rounded bg-grayLight'>-</span>}</span>
              </div>

            </div>
          </div>
        </div>
      }

      {!loading && selectedBatch && <div className="flex items-center p-4 mt-4 bg-white">
        <p className='mr-2'>Search email:</p>
        <input
          name="invitation"
          id="invitation"
          className="px-2 py-1 text-xs bg-white border rounded outline-none"
          type="text"
          placeholder="Search..."
          onChange={(e) => setInvitationInputValue(e.target.value)}
          value={invitationInputValue}
        />
        {
          searching && <div className="ml-4">
            <span className="text-xs">Searching...</span>
          </div>
        }
      </div>}

      {!loading && data?.items?.length > 0 &&
        <>
          <div className="mt-8 overflow-scroll">
            <table className="w-full">
              <thead className="w-full text-left bg-white border-b border-grayLight whitespace-nowrap">
                <tr>
                  {Object.keys(data.modelConfig.list).map(key => {
                    if (key === '_id') return
                    if (typeof data.modelConfig.list[key] === 'undefined') return
                    if (typeof data.modelConfig.list[key] === 'object' && data.modelConfig.list[key].hidden) return
                    return (
                      <th key={key} className="px-4 py-2">
                        <span
                          className="relative cursor-pointer wght-semibold"
                        >
                          {typeof data.modelConfig.list[key] === 'string' && data.modelConfig.list[key]}
                          {typeof data.modelConfig.list[key] === 'object' && data.modelConfig.list[key].label}
                        </span>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody className='whitespace-nowrap'>

                {data && data.items.map((item, index) =>
                  <tr key={item._id} className={`relative bg-white border-b border-grayLight hover:bg-grayLighter ${modelName === 'ticket' ? 'text-sm' : ''} ${selectedBatch || modelName === 'BatchNotificationsTemplate' ? '' : 'cursor-pointer'} `}
                    onClick={() => {
                      if (!selectedBatch && modelName !== 'BatchNotificationsTemplate') {
                        history.push(`/admin/all/list/BatchMessages?batch=${item._id}`)
                      }
                    }}
                  >
                    {Object.keys(data.modelConfig.list).map(key => {
                      if (key === '_id') return
                      if (typeof data.modelConfig.list[key] === 'undefined') return
                      if (typeof data.modelConfig.list[key] === 'object' && data.modelConfig.list[key].hidden) return
                      if (key === 'ticketId') {
                        return <td key={key} className={`px-4 py-2`}>
                          <Link to={`/admin/all/edit/Ticket/${item[key]}`} className="cursor-pointer hover:text-primary hover:wght-semibold" >
                            View
                          </Link>
                        </td>
                      }
                      if (item[key] && ['creationDate', 'updateDate'].some(i => i === key)) {
                        return <td key={key} className={`px-4 py-2`}>
                          {dayjs(item[key]).format('DD/MM/YYYY HH:mm:ss')}
                        </td>
                      }
                      if (selectedBatch && item[key] && ['creationDate', 'updateDate', 'opened', 'clicked', 'spam', 'bounced'].some(i => i === key)) {
                        return <td key={key} className={`px-4 py-2`}>
                          YES
                        </td>
                      }
                      if (!item[key] || item[key] === '0') {
                        return <td key={key} className={`px-4 py-2`}>
                          -
                        </td>
                      }
                      return (
                        <td key={key} className={`px-4 py-2`}>
                          {parseField(key, item)}
                        </td>
                      )
                    })}
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Pagination
            page={page}
            setPage={setPage}
            hasMorePages={hasMorePages}
            loading={loading}
          />
        </>
      }

      {
        !loading && data?.items?.length === 0 &&
        <p className="mt-8">Nothing to list</p>
      }
    </>
  )
}

export default BatchMessages