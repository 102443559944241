import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { useSession } from '../contexts/AuthContext'
import StatsProgressBar from './StatsProgressBar'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import { getUserPreference, updateCookiePreferences } from "./Lib/utils"
import Loader from "./Loader"
import Button from "./Button"

const StatsTickets = ({ organizationTitle = '' }) => {
  const history = useHistory()
  const { name: modelName, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [loading, setLoading] = useState(true)
  const title = 'Stock Marketplace (Ticket Types)'
  const [eventTitle, setEventTitle] = useState()
  const [totalTickets, setTotalTickets] = useState()
  const [totalSold, setTotalSold] = useState()
  const [ticketTypesState, setTicketTypesState] = useState([])
  const [role, setRole] = useState(false)
  const token = useSession()
  const [showNoStock, setShowNoStock] = useState(false)
  const [autoRefresh, setAutoRefresh] = useState(getUserPreference({
    parentKey: 'statsTickets',
    key: 'autoRefresh',
    defaultValue: false,
  }))

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsTickets',
      key: 'autoRefresh',
      value: autoRefresh,
    })
  }, [autoRefresh])

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        setLoading(true)
      }, 10000)
      return () => clearInterval(interval)
    }
  }, [autoRefresh])

  useEffect(() => {
    getData()
  }, [loading])

  useEffect(() => {
    setTicketTypesState([])
    getData()
  }, [showNoStock, event])

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}model-stats-tickettypes`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        organization: getOrganization(),
        event: getEvent(),
        showNoStock,
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        const rawData = response.body
        if (response.status === 200) {
          setRole(rawData.role)
          setEventTitle(rawData.eventTitle)
          setTotalTickets(rawData.statsEvent.total)
          setTotalSold(rawData.statsEvent.paid)
          if (event === 'all') {
            if (rawData.statsTicketTypes.length > 0) {
              const groupedByEvent = rawData.statsTicketTypes.reduce(
                (acc, curr) => {
                  const eventTitle = curr.event.title
                  if (!acc[eventTitle]) {
                    acc[eventTitle] = []
                  }
                  acc[eventTitle].push(curr)
                  return acc
                }
                , {})
              setTicketTypesState(groupedByEvent)
            }
          } else {
            setTicketTypesState(rawData.statsTicketTypes)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
          if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {role && (
        <div className="flex">
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className="wght-semibold">{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      )}

      <div className='flex items-end justify-between mt-2'>
        <div className={`wght-semibold ${loading ? '' : 'opacity-0'}`}>Loading...</div>
        <div className='flex'>
          <Button
            className='mr-1'
            onClick={() => setAutoRefresh(!autoRefresh)}
            size='small'
            color={autoRefresh ? 'green' : 'white'}
          >
            Auto refresh
          </Button>
        </div>
      </div>

      {!event ? <h3 className="mt-8">Select an event</h3> :
        <>
          {totalTickets ? (
            <div className="mt-4">
              <h2 className="block mt-4 mb-2 text-lg wght-semibold">
                {event === 'all' ? `Organization: ${organizationTitle}` : `Event: ${eventTitle}`}
              </h2>
              <StatsProgressBar
                index={0}
                title="Total sold tickets"
                total={totalTickets}
                current={totalSold}
              />

              {(ticketTypesState.length || Object.keys(ticketTypesState)) && (
                <div>
                  <input className="cursor-pointer" type='checkbox' checked={showNoStock} onChange={() => setShowNoStock(!showNoStock)} id="showNoStock" />
                  <label className="ml-2 cursor-pointer" htmlFor="showNoStock" >Show ticket types with no stock</label>


                  {event !== 'all' && ticketTypesState.length ? ticketTypesState.map((ticketType, index) => {
                    if (ticketType && ticketType.stock?.total) {
                      return (
                        <StatsProgressBar
                          key={ticketType.title}
                          index={index}
                          title={ticketType.title}
                          total={ticketType.stock.total}
                          current={ticketType.stock.paid}
                          url={`/admin/${event}/edit/TicketType/${ticketType._id}`}
                        />
                      )
                    } else {
                      return (
                        <Link
                          to={`/admin/${event}/edit/TicketType/${ticketType._id}`}
                          key={ticketType.title}
                          className="block p-2 my-2 bg-white border border-white hover:border-primary"
                        >
                          <h3 className="">{ticketType.title}</h3>
                          <p className="text-sm text-orange">No stock</p>
                        </Link>
                      )
                    }
                  }) : null}

                  {event === 'all' && Object.keys(ticketTypesState).map((eventTitle, index) => {

                    const statsEvent = ticketTypesState[eventTitle].reduce((acc, curr) => {
                      acc.total += curr.stock?.total || 0
                      acc.paid += curr.stock?.paid || 0
                      return acc
                    }, { total: 0, paid: 0 })

                    return (
                      <div key={eventTitle}>
                        <h3 className="mt-8 text-lg wght-semibold">Event: {eventTitle}</h3>
                        <StatsProgressBar
                          index={index}
                          title="Total event sold tickets"
                          total={statsEvent.total}
                          current={statsEvent.paid}
                        />

                        {ticketTypesState[eventTitle].map((ticketType, index) => {
                          if (ticketType && ticketType.stock?.total) {
                            return (
                              <StatsProgressBar
                                key={ticketType.title}
                                index={index}
                                title={ticketType.title}
                                total={ticketType.stock.total}
                                current={ticketType.stock.paid}
                                url={`/admin/${event}/edit/TicketType/${ticketType._id}`}
                              />
                            )
                          } else {
                            return (
                              <Link
                                to={`/admin/${event}/edit/TicketType/${ticketType._id}`}
                                key={ticketType.title}
                                className="block p-2 my-2 bg-white border border-white hover:border-primary"
                              >
                                <h3 className="">{ticketType.title}</h3>
                                <p className="text-sm text-orange">No stock</p>
                              </Link>
                            )
                          }
                        })}
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          ) : (
            <Loader loading={loading} className="mt-8" text="">
              <h3 className="mt-8">No stock for this event</h3>
            </Loader>
          )}
        </>
      }

    </div >
  )
}

export default StatsTickets
