import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import QRCode from 'qrcode.react'
import Form from './Form/Form'
import flatten from './Lib/flatten'
import { useSession } from '../contexts/AuthContext'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import getEventFromExternalVendor from './Lib/getEventFromExternalVendor'

const modelsWithoutList = []

const Edit = ({ setUpdateEvents, setUpdateOrganizations }) => {
  const history = useHistory()
  const location = useLocation()
  const { name: modelName, id: urlId, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [id, setId] = useState(urlId)
  const [data, setData] = useState(false)
  const [originalData, setOriginalData] = useState(false)
  const [name, setName] = useState(modelName)
  const [title, setTitle] = useState(name)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [notUniqueId, setNotUniqueId] = useState(false)
  const [role, setRole] = useState(false)
  const token = useSession()
  const page = location.state?.page || 0

  const currentOrganization = getOrganization()

  const canImportFromDice = data?.vendors?.some(item => item === 'dice')

  const updateStored = useCallback((key, value, errs = []) => {
    setNotUniqueId()

    if (!data.stored) {
      data.stored = {}
    }

    if (typeof data.stored.modelName === 'undefined') {
      data.stored.modelName = name
    }

    data.stored[key] = key === 'email' ? value.trim() : value
    errors[key] = errs
    setData({ ...data })
    setErrors({ ...errors })
  }, [data])


  useEffect(() => {
    if (data && data?.modelConfig?.autosave) {
      const myInterval = setInterval(() => {
        save();
      }, Number(data?.modelConfig?.autosave));
      return () => clearInterval(myInterval);
    }
  }, [data, saving])

  useEffect(() => {
    setData(false)
    setOriginalData(false)
    setName(modelName)

    fetch(`${process.env.REACT_APP_API_URL}model-edit`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        organization: currentOrganization,
        event: getEvent(),
        _id: id || undefined,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setRole(response.body.role)
          setTitle(`${id ? 'Edit' : 'Create'} ${response.body.modelConfig.title}`)
          setData(response.body)
          if (response.body?.stored) {
            setOriginalData(JSON.parse(JSON.stringify(response.body?.stored)))
          }
        } else {
          console.log('Error', response.status, response.body.error)
          setData({})
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/${event}/edit/${modelName}`);
          }
        }
      })
      .catch(error => {
        console.log('Error', error)
      })

  }, [modelName, id])

  const orderModels = function (data) {
    data && Object.keys(data).forEach(key => {
      const staticElement = data[key]
      if (Array.isArray(staticElement)) {
        staticElement.forEach((jsonElement, key2) => {
          if (jsonElement['items']) {
            data[key][key2]['items'] = jsonElement['items'].sort((a, b) => a.position - b.position)
          }
        });
      }
    })
  }

  const checkIfTicketIdIsUnique = async (data, ticketModel) => {
    if (!ticketModel) return false
    const bodyData = { ...data }

    let isUnique = false
    await fetch(`${process.env.REACT_APP_API_URL}ticket-unique`, {
      body: JSON.stringify(bodyData),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.body.unique) {
          isUnique = true
        }
      })
      .catch((err) => console.log(err))
    return isUnique
  }

  const save = async (type = 'default', callback = false) => {
    const errs = flatten(errors)
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setSaving(true)
      orderModels(data.stored)

      const bodyData = {
        ...data.stored,
        modelName,
        filters: {
          event: getEvent(),
          organization: currentOrganization,
        },
        originalData,
      }

      const ticketModel = (modelName === 'Ticket')
      const uniqueTicketId = await checkIfTicketIdIsUnique(data.stored, ticketModel)
      if (!ticketModel || uniqueTicketId) {
        fetch(`${process.env.REACT_APP_API_URL}model-update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        })
          .then(response => response
            .json()
            .then(data => ({ status: response.status, body: data })))
          .then(response => {
            setSaving(false)
            if (callback) callback(response)
            if (response.status === 200) {
              if (data.stored) {
                setOriginalData(JSON.parse(JSON.stringify(data.stored)))
              }
              if (modelName === 'Event') {
                setUpdateEvents(true)
              }
              if (modelName === 'Organization') {
                setUpdateOrganizations(true)
              }
              if (type === 'default') {
                if (typeof id === 'undefined') {
                  setId(response.body._id)
                  history.push(`/admin/${event}/edit/${modelName}/${response.body._id}`)
                }
                setSaved(true)
                setTimeout(() => setSaved(false), 3000)
                return
              } else if (type === 'save-and-another') {
                setId(null)
                history.push(`/admin/${event}/edit/${modelName}`)
                history.go(0)
                return
              } else if (type === 'save-and-close') {
                if (modelName === 'Organization') {
                  history.push(`/admin/${event}/list/Event`)
                } else {
                  history.push(
                    page > 0
                      ? `/admin/${event}/list/${modelName}/${page + 1}`
                      : `/admin/${event}/list/${modelName}`
                  )
                }
              }
            } else {
              console.log('Error', response.status, response.body.error)
              if (response.status === 403) {
                Cookies.remove('user')
                history.push('/login')
              } else if (response.status === 401) {
                history.push(`/admin/no-access?url=/admin/${event}/edit/${modelName}`)
              }
            }
          })
          .catch((e) => {
            setSaving(false)
          })
      } else {
        setSaving(false)
        if (!uniqueTicketId) {
          setNotUniqueId(true)
        }
      }
    }
  }

  const handleDiceImport = async (event) => {
    event.preventDefault()
    const formData = new FormData(event.target);
    const externalId = formData.get('dice-import')

    const { body } = await getEventFromExternalVendor({ vendor: 'dice', eventId: externalId, token, organization: currentOrganization })

    if (!body) return

    if (modelName === 'Event') {
      delete body.data.image
      delete body.marketplacesettings.image
    }
    // NOTE: this config should set on the event
    if (modelName === 'TicketType' && currentOrganization === '642be117091b470008a2dafd' ) {
      delete body.data.title
      delete body.marketplacesettings.startDate
      delete body.marketplacesettings.endDate
    }

    const diceEventData = {
      ...data,
      stored: {
        ...data.stored,
        ...body.data,
        appInfo: {
          ...data.stored.appInfo,
          ...body.appInfo,
        },
        marketplacesettings: {
          ...data.stored.marketplacesettings,
          ...body.marketplacesettings
        }
      }
    }  
    console.log('diceEventData', diceEventData)
    setData(diceEventData)
  }

  return (
    <div>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <div className="flex items-center   justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
        <Link
          to={modelName === 'Organization' ? `/admin/`
            : page > 0
              ? `/admin/${event}/list/${modelName}/${page + 1}`
              : `/admin/${event}/list/${modelName}`
          }
          className="cursor-pointer hover:wght-semibold hover:text-primary"
        >
          Cancel
        </Link>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }



      {!data && <div className="mt-8 mb-8">Loading...</div>}
      {data && !Object.keys(data).length && <div className="mt-8 mb-8">Item not found</div>}
      {data && Object.keys(data).length && modelName === name ? (
        <div className="mt-8">
          {
            canImportFromDice && (modelName === 'Event' || modelName === 'TicketType')  ? (
              <form onSubmit={handleDiceImport} className="w-1/3 mb-4 flex flex-col items-end justify-end ml-auto">
                <label htmlFor="dice-import">
                  Import from DICE
                </label>
                <input type="text" name="dice-import" id="dice-import" className="w-full rounded outline-none placeholder-gray text-right px-2 py-1" placeholder='DICE id'/>
                <button>Import</button>
              </form>
            ) : null
          }
          <Form
            onChange={updateStored}
            data={data}
            name={name}
            showErrors={showErrors}
            mode={id ? 'edit' : 'create'}
            currentData={data}
            readMode={role === 'read'}
          />
          {modelName === 'Ticket' && data && data.stored && data.stored.ticketId && <div className="p-4 bg-white border-t border-grayLight"><QRCode size={300} value={`${data.stored.ticketId}`} /><span>{data.stored.ticketId}</span></div>}
          {
            role === 'read' ?
              <div className="sticky bottom-0 flex justify-end p-4 text-xs bg-white border-t border-grayLight">
                <span className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary">
                  {modelsWithoutList.includes(modelName) ?
                    <Link to={`/admin/${event}/edit/${modelName}`}>Back to list</Link>
                    :
                    <Link to={`/admin/${event}/list/${modelName}`}>Back to list</Link>
                  }
                </span>
              </div>
              :
              <div className="sticky bottom-0 z-10 flex justify-between p-4 text-xs bg-white border-t border-grayLight">
                <div>
                  {saving ? (
                    <span className="text-black wght-bold">Saving...</span>
                  ) : (
                    <span
                      onClick={() => save()}
                      className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                    >
                      Save &#8599;{' '}
                    </span>
                  )}
                  {saved && <span className="ml-4 text-black">Saved successfully!</span>}
                  {modelName === 'Ticket' && notUniqueId && <span className="pl-2 text-red">Ticket ID must be unique</span>}
                </div>
                <div>
                  {saving ? null : (
                    <span
                      onClick={() => save('save-and-another')}
                      className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                    >
                      Save &amp; create &#8599;{' '}
                    </span>
                  )}
                </div>
                <div>
                  {saving ? null : (
                    <span
                      onClick={() => save('save-and-close')}
                      className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                    >
                      Save &amp; close &#8599;
                    </span>
                  )}
                </div>
              </div>
          }
        </div>
      ) : <></>}
    </div>
  )
}

export default Edit
