import React from "react";

const templates = {
    en: `<re-html>
    <re-head>
        <re-title>
            Access Ticket
        </re-title>
    </re-head>
    <re-body background-color="#F5F5EE" padding="40px 0 0px 0" font-family="Aptos">
        <re-main background-color="#F5F5EE" border-radius="6px" padding="0 10px 0 10px" margin="0 10px 0 10px">
            <re-block border-radius="0px" align="center" margin=" 0 10px 0 10px" padding="0 52px 0 52px" background-color="#F5F5EE">
                <re-image src="https://cdn.braze.eu/appboy/communication/assets/image_assets/images/6244394e0eada66127efdc6f/original.gif?1648638286" href="https://transactional.accessticket.app/f/a/a60faFvubIagHXPywF0saA~~/AABCMAA~/RgRmPSJVP0QcaHR0cHM6Ly93d3cuYWNjZXNzdGlja2V0LmFwcFcDc3BjQgpkUVWdWmQ_WQ-aUhphbmRyZXUrYXJhc2lAZGFsbG9uc2VzLmNvbVgEAAAAAA~~" width="135px"></re-image>
            </re-block>
            <re-block background-color="#F5F5EE">
                <re-block border-radius="20px" align="center" margin="10px 5px 0 5px" padding="52px 25px 20px 25px" background-color="#fff">
                    <re-heading href="http://google.com" margin="10px 0 30px 0" level="h1" color="#555555">
                        Title<br>
                    </re-heading>
                    <re-text margin="0 0 36px 0" color="#555555">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                        Qui est in parvis malis. Duo Reges: constructio interrete.
                    </re-text>
                    <re-text margin="0 0 36px 0" color="#555555">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                        Qui est in parvis malis. Duo Reges: constructio interrete.
                    </re-text>
                    <re-button href="https://transactional.accessticket.app/f/a/6-ZRIUlMDjPHTzk1idCFxA~~/AABCMAA~/RgRmPSJVP0QtaHR0cHM6Ly93d3cuYWNjZXNzdGlja2V0LmFwcC90L0xKalZlNWJ4cnBGT1JlVwNzcGNCCmRRVZ1aZD9ZD5pSGmFuZHJldSthcmFzaUBkYWxsb25zZXMuY29tWAQAAAAA" border-radius="15px" background-color="#C3F062" font-size="16px" color="#555555">
                        CTA
                    </re-button>
                    <re-text align="center" margin="30px 0" color="#555555">
                        All the best,<br>
                        AT Team
                    </re-text>
                </re-block>
            </re-block>
            <re-block border-radius="0px" align="center" padding="40px 52px 0 52px" background-color="#F5F5EE">
                <re-image src="https://cdn.braze.eu/appboy/communication/assets/image_assets/images/624443630eada62ccfefdcd8/original.png?1648640867" href="https://transactional.accessticket.app/f/a/a60faFvubIagHXPywF0saA~~/AABCMAA~/RgRmPSJVP0QcaHR0cHM6Ly93d3cuYWNjZXNzdGlja2V0LmFwcFcDc3BjQgpkUVWdWmQ_WQ-aUhphbmRyZXUrYXJhc2lAZGFsbG9uc2VzLmNvbVgEAAAAAA~~" width="72px"></re-image>
                <re-text margin="10px 0 40px 0" font-size="18px" color="#555555" font-weight="bold">
                    <strong>AccessTicket</strong><br>
                </re-text>
            </re-block>
        </re-main>
        <re-footer padding="50px 0px 60px 0px" background-color="#fff" border-radius="" width="100%" color="#656565">
            <re-block align="center">
                <re-text font-size="12px" padding="0px 50px 20px 50px">
                    AccessTicket / Roc Boronat 142 Barcelona
                </re-text>
                <re-text font-size="12px" margin="20px 15% 0 15%">
                    This email contains private and confidential information and is intended to be sent only to the person(s) to whom it is addressed as recipient(s). Your personal data is processed by Primavera Sound, S.L. to enable the development of your user status and the proper management of the contractual relationship between us, with the legal basis of the processing being the execution of our contract.<br>
                    <br>
                    Your personal data will be retained for the duration of the contractual relationship and, once resolved, as long as liabilities may arise. Your personal data will not be transferred to third parties, except in cases of contractual or legal obligation. You can exercise your rights of access, rectification, erasure, restriction, objection, and portability, as provided for in applicable legislation, by sending an email to the indicated address. For more information, please refer to our <a href="https://transactional.accessticket.app/f/a/cAz7Nf5sC6WqTBhEb96BNA~~/AABCMAA~/RgRmPSJVP0QwaHR0cHM6Ly93d3cucHJpbWF2ZXJhc291bmQuY29tL2VzL3ByaXZhY3ktcG9saWN5VwNzcGNCCmRRVZ1aZD9ZD5pSGmFuZHJldSthcmFzaUBkYWxsb25zZXMuY29tWAQAAAAA" style="color: rgb(0, 145, 255);">privacy policy.</a>
                </re-text>
            </re-block>
        </re-footer>
    </re-body>
    </re-html>`,

    es: `<re-html>
    <re-head>
        <re-title>
            My Email
        </re-title>
    </re-head>
    <re-body background-color="#F5F5EE" padding="40px 0 0px 0" font-family="Aptos">
        <re-preheader>
            This is preheader and it will show as a preview in some mail clients.
        </re-preheader>
        <re-main background-color="#F5F5EE" border-radius="6px" padding="0 10px 0 10px" margin="0 10px 0 10px">
            <re-block border-radius="0px" align="center" margin=" 0 10px 0 10px" padding="0 52px 0 52px" background-color="#F5F5EE">
                <re-image src="https://cdn.braze.eu/appboy/communication/assets/image_assets/images/6244394e0eada66127efdc6f/original.gif?1648638286" href="https://transactional.accessticket.app/f/a/a60faFvubIagHXPywF0saA~~/AABCMAA~/RgRmPSJVP0QcaHR0cHM6Ly93d3cuYWNjZXNzdGlja2V0LmFwcFcDc3BjQgpkUVWdWmQ_WQ-aUhphbmRyZXUrYXJhc2lAZGFsbG9uc2VzLmNvbVgEAAAAAA~~" width="135px"></re-image>
            </re-block>
            <re-block background-color="#F5F5EE">
                <re-block border-radius="20px" align="center" margin="10px 5px 0 5px" padding="52px 25px 20px 25px" background-color="#fff">
                    <re-heading href="http://google.com" margin="10px 0 30px 0" level="h1" color="#555555">
                        Titulo<br>
                    </re-heading>
                    <re-text margin="0 0 36px 0" color="#555555">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                        Qui est in parvis malis. Duo Reges: constructio interrete.
                    </re-text>
                    <re-text margin="0 0 36px 0" color="#555555">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                        Qui est in parvis malis. Duo Reges: constructio interrete.
                    </re-text>
                    <re-button href="https://transactional.accessticket.app/f/a/6-ZRIUlMDjPHTzk1idCFxA~~/AABCMAA~/RgRmPSJVP0QtaHR0cHM6Ly93d3cuYWNjZXNzdGlja2V0LmFwcC90L0xKalZlNWJ4cnBGT1JlVwNzcGNCCmRRVZ1aZD9ZD5pSGmFuZHJldSthcmFzaUBkYWxsb25zZXMuY29tWAQAAAAA" border-radius="15px" background-color="#C3F062" font-size="16px" color="#555555">
                        CTA
                    </re-button>
                    <re-text align="center" margin="30px 0" color="#555555">
                        Saludos,<br>
                        Equipo AT
                    </re-text>
                </re-block>
            </re-block>
            <re-block border-radius="0px" align="center" padding="40px 52px 0 52px" background-color="#F5F5EE">
                <re-image src="https://cdn.braze.eu/appboy/communication/assets/image_assets/images/624443630eada62ccfefdcd8/original.png?1648640867" href="https://transactional.accessticket.app/f/a/a60faFvubIagHXPywF0saA~~/AABCMAA~/RgRmPSJVP0QcaHR0cHM6Ly93d3cuYWNjZXNzdGlja2V0LmFwcFcDc3BjQgpkUVWdWmQ_WQ-aUhphbmRyZXUrYXJhc2lAZGFsbG9uc2VzLmNvbVgEAAAAAA~~" width="72px"></re-image>
                <re-text margin="10px 0 40px 0" font-size="18px" color="#555555" font-weight="bold">
                    <strong>AccessTicket</strong><br>
                </re-text>
            </re-block>
        </re-main>
        <re-footer padding="50px 0px 60px 0px" background-color="#fff" border-radius="" width="100%" color="#656565">
            <re-block align="center">
                <re-text font-size="12px" padding="0px 50px 20px 50px">
                    AccessTicket / Roc Boronat 142 Barcelona
                </re-text>
                <re-text font-size="12px" margin="20px 15% 0 15%">
                    Este correo contiene información privada y confidencial, y pretende ser remitida únicamente a la(s) persona(s) a la(s) que se dirige como destinataria(s).Tus datos personales son tratados por Primavera Sound, S.L. para permitir el desarrollo de tu condición de usuario y la correcta gestión de la relación contractual que nos une, siendo por tanto la base jurídica del tratamiento la ejecución de nuestro contrato.<br>
                    <br>
                    Tus datos personales se conservarán mientras dure la relación contractual y, una vez resuelta, mientras puedan derivarse responsabilidades. No se cederán tus datos personales a terceras partes, salvo en caso de obligación contractual o legal. Puedes ejercer tus derechos de acceso, rectificación, supresión, limitación, oposición y portabilidad, en los términos previstos en la legislación aplicable, enviando un correo electrónico a la dirección indicada. Para más información, consulta nuestra <a href="https://transactional.accessticket.app/f/a/cAz7Nf5sC6WqTBhEb96BNA~~/AABCMAA~/RgRmPSJVP0QwaHR0cHM6Ly93d3cucHJpbWF2ZXJhc291bmQuY29tL2VzL3ByaXZhY3ktcG9saWN5VwNzcGNCCmRRVZ1aZD9ZD5pSGmFuZHJldSthcmFzaUBkYWxsb25zZXMuY29tWAQAAAAA" style="color: rgb(0, 145, 255);">política de privacidad.</a>
                </re-text>
            </re-block>
        </re-footer>
    </re-body>
    </re-html>`,

    ca: `<re-html>
    <re-head>
        <re-title>
            My Email
        </re-title>
    </re-head>
    <re-body background-color="#F5F5EE" padding="40px 0 0px 0" font-family="Aptos">
        <re-preheader>
            This is preheader and it will show as a preview in some mail clients.
        </re-preheader>
        <re-main background-color="#F5F5EE" border-radius="6px" padding="0 10px 0 10px" margin="0 10px 0 10px">
            <re-block border-radius="0px" align="center" margin=" 0 10px 0 10px" padding="0 52px 0 52px" background-color="#F5F5EE">
                <re-image src="https://cdn.braze.eu/appboy/communication/assets/image_assets/images/6244394e0eada66127efdc6f/original.gif?1648638286" href="https://transactional.accessticket.app/f/a/a60faFvubIagHXPywF0saA~~/AABCMAA~/RgRmPSJVP0QcaHR0cHM6Ly93d3cuYWNjZXNzdGlja2V0LmFwcFcDc3BjQgpkUVWdWmQ_WQ-aUhphbmRyZXUrYXJhc2lAZGFsbG9uc2VzLmNvbVgEAAAAAA~~" width="135px"></re-image>
            </re-block>
            <re-block background-color="#F5F5EE">
                <re-block border-radius="20px" align="center" margin="10px 5px 0 5px" padding="52px 25px 20px 25px" background-color="#fff">
                    <re-heading href="http://google.com" margin="10px 0 30px 0" level="h1" color="#555555">
                        Títol<br>
                    </re-heading>
                    <re-text margin="0 0 36px 0" color="#555555">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                        Qui est in parvis malis. Duo Reges: constructio interrete.
                    </re-text>
                    <re-text margin="0 0 36px 0" color="#555555">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>
                        Qui est in parvis malis. Duo Reges: constructio interrete.
                    </re-text>
                    <re-button href="https://transactional.accessticket.app/f/a/6-ZRIUlMDjPHTzk1idCFxA~~/AABCMAA~/RgRmPSJVP0QtaHR0cHM6Ly93d3cuYWNjZXNzdGlja2V0LmFwcC90L0xKalZlNWJ4cnBGT1JlVwNzcGNCCmRRVZ1aZD9ZD5pSGmFuZHJldSthcmFzaUBkYWxsb25zZXMuY29tWAQAAAAA" border-radius="15px" background-color="#C3F062" font-size="16px" color="#555555">
                        CTA
                    </re-button>
                    <re-text align="center" margin="30px 0" color="#555555">
                        Salutacions,<br>
                        Equip AT
                    </re-text>
                </re-block>
            </re-block>
            <re-block border-radius="0px" align="center" padding="40px 52px 0 52px" background-color="#F5F5EE">
                <re-image src="https://cdn.braze.eu/appboy/communication/assets/image_assets/images/624443630eada62ccfefdcd8/original.png?1648640867" href="https://transactional.accessticket.app/f/a/a60faFvubIagHXPywF0saA~~/AABCMAA~/RgRmPSJVP0QcaHR0cHM6Ly93d3cuYWNjZXNzdGlja2V0LmFwcFcDc3BjQgpkUVWdWmQ_WQ-aUhphbmRyZXUrYXJhc2lAZGFsbG9uc2VzLmNvbVgEAAAAAA~~" width="72px"></re-image>
                <re-text margin="10px 0 40px 0" font-size="18px" color="#555555" font-weight="bold">
                    <strong>AccessTicket</strong><br>
                </re-text>
            </re-block>
        </re-main>
        <re-footer padding="50px 0px 60px 0px" background-color="#fff" border-radius="" width="100%" color="#656565">
            <re-block align="center">
                <re-text font-size="12px" padding="0px 50px 20px 50px">
                    AccessTicket / Roc Boronat 142 Barcelona
                </re-text>
                <re-text font-size="12px" margin="20px 15% 0 15%">
                    Aquest correu conté informació privada i confidencial, i pretén ser enviat únicament a la(s) persona(s) a la(s) que es dirigeix com a destinatària(es). Les teves dades personals són tractades per Primavera Sound, S.L. per permetre el desenvolupament de la teva condició d'usuari i la correcta gestió de la relació contractual que ens uneix, sent per tant la base legal del tractament l'execució del nostre contracte.<br>
                    <br>
                    Les teves dades personals es conservaran mentre duri la relació contractual i, una vegada resolta, mentre puguin derivar-se responsabilitats. No es cediran les teves dades personals a tercers, llevat que hi hagi obligació contractual o legal. Pots exercir els teus drets d'accés, rectificació, supressió, limitació, oposició i portabilitat, en els termes previstos a la legislació aplicable, enviant un correu electrònic a l'adreça indicada. Per a més informació, consulta la nostra <a href="https://transactional.accessticket.app/f/a/cAz7Nf5sC6WqTBhEb96BNA~~/AABCMAA~/RgRmPSJVP0QwaHR0cHM6Ly93d3cucHJpbWF2ZXJhc291bmQuY29tL2VzL3ByaXZhY3ktcG9saWN5VwNzcGNCCmRRVZ1aZD9ZD5pSGmFuZHJldSthcmFzaUBkYWxsb25zZXMuY29tWAQAAAAA" style="color: rgb(0, 145, 255);">política de privacitat.</a>
                </re-text>
            </re-block>
        </re-footer>
    </re-body>
    </re-html>`,
}

export default templates