import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import ModalPhoneRecovery from '../ModalPhoneRecovery'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'

const PhoneRecovery = () => {
  const title = 'Phone recovery'
  const history = useHistory()
  const { event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [data, setData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [role, setRole] = useState(false)
  const token = useSession()


  useEffect(() => {
  }, [loading])

  const getData = (clear = false) => {
    setLoading(true)
    setData()
    const queryParams = new URLSearchParams({
      modelName: 'phoneRecovery',
      endpoint: 'phoneRecovery',
      organization: getOrganization(),
      event: getEvent(),
      phoneNumber: !clear && searchValue,
    }).toString();

    fetch(`${process.env.REACT_APP_API_URL}get-phone-validation-code?${queryParams}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
          setData(response.body)  
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const clearFilters = () => {
    setSearchValue('')
  }


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getData()
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      {loading ?
        <div className="mt-8">Loading...</div> :
        <>
          <div className="w-full mt-8 mb-2 sm:mb-0 sm:w-auto">
            <div className="flex flex-col md:flex-row">
              <input
                name="search"
                className={`rounded px-2 bg-white outline-none placeholder-gray mr-2`}
                type="text"
                placeholder="Search phone"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              <span className="mr-2 cursor-pointer hover:wght-semibold" onClick={() => getData()}>Search</span>
              <span className="mr-2">|</span>
              <span className="mr-2 cursor-pointer hover:wght-semibold" onClick={() => clearFilters()}>Reset</span>
            </div>
          </div>
          <div className="mt-4 overflow-scroll"><table className="w-full">
            <thead className="w-full text-left bg-white border-b border-grayLight">
              <tr>
                <th className=""></th>
                <th className="px-4 py-2">Creation Date</th>
                <th className="px-4 py-2">Expiration Date</th>
                <th className="px-4 py-2">Country</th>
                <th className="px-4 py-2">Phone</th>
                <th className="px-4 py-2">Code</th>
                <th className="px-4 py-2">User</th>
              </tr>
            </thead>
            <tbody>
              {data && data.items?.length === 0 &&
                <td>
                  <div className="px-4 py-2 cursor-pointer">Nothing to list</div>
                </td>
              }
              {data && data.items?.map((item, index) =>
                <tr key={item._id} className={`relative border-b bg-white border-grayLight hover:bg-primaryLight`}>
                  <td className="w-1 p-0">
                    {item.verified ?
                      <div title={'All messages sent'} className={`w-1 h-9 text-xxs bg-green-300 cursor-default`}></div>
                      :
                      <div title={'Pending messages'} className={`w-1 h-9 text-xxs bg-orange cursor-default`}></div>
                    }
                  </td>
                  <td><div className="px-4 py-2">{dayjs(item.creationDate).format('DD/MM/YYYY HH:mm:ss')}</div></td>
                  <td><div className="px-4 py-2">{dayjs(item.expirationDate).format('DD/MM/YYYY HH:mm:ss')}</div></td>
                  <td><div className="px-4 py-2">{item.phoneNumber.phoneNumberPrefix}</div></td>
                  <td><div className="px-4 py-2">{item.phoneNumber.phoneNumber}</div></td>
                  <td><div className="px-4 py-2">{item.code}</div></td>
                  <td><div className="px-4 py-2">{item.user}</div></td>
                </tr>
              )}
            </tbody>
          </table></div>
        </>
      }
    </>
  )
}

export default PhoneRecovery