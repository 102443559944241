import React from 'react'
import Button from './Button'

const ImportExport = ({ uploading, handleImport, handleExport, description }) => {
  return (
    <div>
      {uploading ? (
        <div className="px-2 py-1 mr-2 text-xs rounded text-primaryLighter bg-gray">
          Uploading...
        </div>
      ) : (
        <div className='flex'>
          <Button visibility={!!handleImport} color="primary" className='ml-0 mr-2'>
            <label className='cursor-pointer'>
              Import
              <input
                onChange={handleImport}
                type="file"
                name="file"
                value=""
                title="&nbsp;"
                style={{ display: 'none' }}
              />
            </label>
          </Button>
          <Button visibility={!!handleExport} color="primary" className='ml-0' onClick={handleExport}>Export</Button>    
        </div>
      )}
      { description &&
        <p className='mt-2 text-xs'>{description}</p>
      }
    </div>
  )
}

export default ImportExport
