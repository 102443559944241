import React from 'react'

const NoAccessPublic = () => {

  return (
    <div>
      <h1 className="mb-4 text-xl wght-semibold">Permission denied</h1>
      <p>You don't have permission to access the section.</p>
    </div>
  )
}

export default NoAccessPublic