import React from 'react'
import generateToken from './Lib/generateToken'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const Button = ({
  key = generateToken(6),
  children,
  onClick = () => {},
  color = 'primary',
  className = '',
  size,
  disable = false,
  visibility = true,
  to = null,
}) => {
  let sizeStyle = 'px-4 py-1'
  if (size === 'small') {
    sizeStyle = 'py-1 px-2 mx-0.5 text-xs'
  }

  let textColor = 'white'
  let textColorHover = color
  let bgColor = color
  let bgColorHover = 'transparent'
  let borderColor = color
  let borderColorHover = borderColor

  switch (color) {
    case 'white':
      textColor = 'black'
      bgColor = 'white'
      borderColor = 'gray'
      textColorHover = 'primary'
      bgColorHover = 'white'
      borderColorHover = 'primary'
      if (disable) {
        textColorHover = 'black'
      }
      break
    case 'cancel':
      textColor = 'error'
      textColorHover = 'white'
      bgColor = 'white'
      bgColorHover = 'error'
      borderColor = 'error'
      borderColorHover = 'error'
      break
    case 'invertedOrange':
      textColor = 'black'
      bgColor = 'white'
      textColorHover = 'orange'
      borderColor = 'orange'
      bgColorHover = 'white'
      if (disable) {
        textColorHover = 'black'
      }
      break
    case 'orange':
      textColor = 'black'
      bgColor = 'orange'
      textColorHover = 'error'
      borderColor = 'orange'
      if (disable) {
        textColorHover = 'black'
      }
      break
    case 'green':
    case 'success':
      textColor = 'white'
      textColorHover = 'success'
      bgColor = 'success'
      borderColor = 'success'
      if (disable) {
        textColorHover = 'black'
      }
      break
    case 'invertedGreen':
      textColor = 'success'
      textColorHover = 'white'
      bgColor = 'white'
      bgColorHover = 'success'
      borderColor = 'success'
      break
    case 'warningWhite':
      textColor = 'white'
      textColorHover = 'warning'
      bgColor = 'warning'
      borderColor = 'white'
      bgColorHover = 'white'
      borderColorHover = 'white'
      if (disable) {
        textColorHover = 'black'
      }
      break
  }

  if (!visibility) {
    return null
  }

  if (disable) {
    bgColor = 'gray'
    borderColor = 'gray'
    textColor = 'white'
    textColorHover = 'white'
    bgColorHover = 'gray'
    borderColorHover = 'gray'
  }

  const style = `
    flex items-center border rounded
    text-${textColor}
    ${disable ? '' : 'cursor-pointer'}
    bg-${bgColor}
    hover:bg-${bgColorHover}
    hover:text-${textColorHover}
    border-${borderColor}
    hover:border-${borderColorHover}
    ${sizeStyle}
    ${className}
  `

  return to ? (
    <Link
      key={key}
      disabled={disable}
      className={style}
      to={to}
    >
      {children}
    </Link>
  ) : (
    <button
      key={key}
      disabled={disable}
      className={style}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
