import React, { useEffect, useState } from 'react'
import Button from '../Button'
import { useSession } from '../../contexts/AuthContext'

const TicketPermissions = ({
  storedData,
  onChange: updateStored,
  name,
  translatable,
  languages,
  models,
  currentData,
  options: { types },
  readMode,
}) => {
  const [ticketType, setTicketType] = useState()
  const [value, setValue] = useState((storedData && storedData.permissions) ? storedData.permissions : [])
  const [errorNoZones, setErrorNoZones] = useState(false)
  const [zones, setZones] = useState()
  const [zoneSelector, setZoneSelector] = useState({})
  const token = useSession()


  useEffect(() => {
    updateStored(name, value)
  }, [value])

  useEffect(() => {
    if (currentData && currentData.stored && currentData.stored.ticketType) {
      setTicketType(currentData.stored.ticketType)
    }
  }, [currentData])

  useEffect(() => {
    if (ticketType && parseInt(ticketType) === -1) {
      setZones([])
    }
    if (ticketType && parseInt(ticketType) !== -1) {
      getZones()
    }
  }, [ticketType])

  const getZones = async () => {

    const bodyData = {}
    if (ticketType && parseInt(ticketType) !== -1) {
      bodyData.ticketType = ticketType
    }

    fetch(`${process.env.REACT_APP_API_URL}ticket-permissions`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setZones(response.body.zones)
        } else {
          setZones([])
        }
      })
  }


  const onChange = (event, key) => {
    const rawValue = [...value]
    rawValue.zones = rawValue.zones.map(item => {
      if (item.key === key) {
        item.zone = event.target.value
      }
      return item
    })
    const rawSelector = zoneSelector
    rawSelector[key] = event.target.value
    setZoneSelector(rawSelector)
    setValue(rawValue)
  }


  const generateToken = length => {
    const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split(
      '',
    )
    const b = []
    for (let i = 0; i < length; i++) {
      const j = (Math.random() * (a.length - 1)).toFixed(0)
      b[i] = a[j]
    }
    return b.join('')
  }

  const addModel = async () => {
    if (readMode) return false
    const rawValue = value.length > 0 ? [...value] : []
    rawValue.push({
      key: generateToken(30),
      type: false,
      data: {},
    })
    setValue(rawValue)
  }

  const removeItem = (key) => {
    if (readMode) return false
    const rawValue = value.filter(item => item.key !== key)
    setValue(rawValue)
  }

  const onChangeTypePermission = (event, key) => {
    const rawValue = [...value]
    let permissionType = event.target.value
    rawValue.map(permission => {
      if (permission.key === key) {
        permission.type = permissionType
      }
      return permission
    })
    setValue(rawValue)
  }

  const Fields = (permission) => {
    switch (permission.type) {
      case 'adminAllZone':
      case 'barEmployeeAll':
        return false
      case 'adminSingleZone':
      case 'barEmployeeSingle':
        return SingleZonesFields(permission)
      default:
        return false
    }
  }

  const SingleZonesFields = ({ key, data }) => {
    const onChangeZone = (event, key) => {
      const rawValue = value.map(item => {
        if (item.key === key) {
          item.data = { zone: event.target.value }
        }
        return item
      })
      setValue(rawValue)
    }

    return <div className="flex items-center">
      <p className="pr-2">Zone: </p>
      <select
        name="zone"
        type="text"
        value={data.zone || false}
        onChange={(event) => onChangeZone(event, key)}
        className={`h-full w-full rounded px-2 py-1 outline-none placeholder-gray`}
      >
        {zones && zones.length > 0 ? <option value="-1">Select</option> : <option value="-1">No zones for this event</option>}
        {zones && zones.length > 0 ? zones.map((zone, index) => (
          <option key={index} value={zone._id}>{zone.title}</option>
        )) : false}
      </select>
    </div>
  }

  return <div>
    <div className="flex flex-row justify-between w-full mb-2">
      <div className="flex flex-col w-1/2">
        <label>Control Permissions</label>
      </div>
      <div className="flex flex-row items-end justify-between">
        <Button onClick={() => addModel()} color="primary" size="small" className='mx-0'>Add permission</Button>
      </div>
    </div>
    {(value && value.length > 0) ?
      <div className="">
        {value.map((permission) => (
          <div className="w-full px-2 py-1 my-1 text-xs rounded bg-grayLight" key={permission.key}>
            <div className="flex items-center w-full">
              <div className="flex flex-row items-center w-full pr-4">
                <p className="pr-2">Type: </p>
                <div className="w-1/4 mr-2">
                  <select
                    name="type"
                    type="text"
                    placeholder="Type"
                    value={permission.type || false}
                    onChange={e => onChangeTypePermission(e, permission.key)}
                    className={`h-full w-full rounded px-2 py-1 outline-none placeholder-gray ${errorNoZones ? 'border border-error' : ''
                      }`}
                    disabled={readMode}
                  >
                    <option value="-1">Select</option>
                    {Object.keys(types).map(type => (
                      <option key={type} value={type}>{types[type]}</option>
                    ))}
                  </select>
                </div>
                {Fields(permission)}
              </div>
              <span className="pl-1 ml-auto cursor-pointer" onClick={() => removeItem(permission.key)}>
                ✕
              </span>
            </div>
          </div>
        ))}
      </div>
      :
      <div className="w-full px-4 py-2 my-2 text-xs rounded bg-grayLight">
        No specific permissions
      </div>
    }
  </div>

}

export default TicketPermissions
