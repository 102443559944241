import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const Address = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label },
  validations,
  languages,
  innerForm,
  readMode,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [errors, setErrors] = useState([])
  const [apiReady, setApiReady] = useState(false)
  let autocomplete

  useEffect(() => {
    if (window.apiReady) {
      setApiReady(true)
    } else {
      const googleMapScript = document.createElement('script');
      googleMapScript.src=`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
      googleMapScript.addEventListener('load', () => {
        setApiReady(true)
        window.apiReady = true
      })
    }
  }, [])

  useEffect(() => {
    if (apiReady) {
      // eslint-disable-next-line no-undef, react-hooks/exhaustive-deps
      autocomplete = new google.maps.places.Autocomplete(document.getElementById(`autocomplete-${name}`), {})
      autocomplete.addListener('place_changed', handlePlaceSelect)
    }
    return () => {
      if (autocomplete) {
        // eslint-disable-next-line no-undef
        google.maps.event.clearInstanceListeners(autocomplete);
      }
    }
  }, [apiReady])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const handlePlaceSelect = () => {
    let addressObject = autocomplete.getPlace()

    const place = {
      place_id: addressObject.place_id,
      name: addressObject.name,
      geometry: {
        lat: addressObject.geometry.location.lat(),
        lng: addressObject.geometry.location.lng(),
      },
      address_components: addressObject.address_components,
    }

    setValue(place)
  }

  const input = (
    <input
      readOnly={readMode}
      id={`autocomplete-${name}`}
      name={name}
      className={`w-full rounded ${
        translatable && Object.keys(languages)[0] === language
          ? 'rounded-tl-none'
          : ''
      } px-2 py-1 ${
        showErrors && errors.length > 0 ? 'border border-error' : ''
      } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
      type="text"
      placeholder={label}
    />
  )

  return (
    <div>
      <label>{label}</label>
      {input}
      {value && <div className="mt-2 text-xs">Address: {value.name}</div>}
    </div>
  )
}

export default Address
