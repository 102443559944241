import React, { useState } from 'react'
import { useSession } from '../contexts/AuthContext'
import InvitationModal from './InvitationModal'
import Button from './Button'

const InvitationButton = ({ event, modelName, role }) => {
  const token = useSession()
  const [showModal, setShowModal] = useState()

  return <div>
    <Button
     size="small"
     onClick={() => setShowModal(!showModal)}
     color='success'
     visibility={role === 'admin' && modelName !== 'Event' || false}
    >
      Share
    </Button>

    {
      showModal && <InvitationModal
        token={token}
        handleShowModal={() => setShowModal(!showModal)}
        modelName={modelName}
        event={event}
      />
    }
  </div>
}

export default InvitationButton
