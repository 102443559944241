import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { useSession } from '../contexts/AuthContext'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import Button from "./Button"
import Loader from "./Loader"
import { getUserPreference, updateCookiePreferences } from "./Lib/utils"
import { Modal, CloseButton } from "./Modal"
import dayjs from 'dayjs'

const StatsTickets = () => {
  const token = useSession()
  const { name: modelName, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [title, setTitle] = useState(`Tickets exchanged and tickets inside zones`)
  const [totalTickets, setTotalTickets] = useState()
  const [occupancy, setOccupancy] = useState()
  const [ticketTypesState, setTicketTypesState] = useState([])
  const [zones, setZones] = useState([])
  const [graphMarks, setGraphMarks] = useState([])
  const [role, setRole] = useState(false)
  const [showExportModal, setShowExportModal] = useState()
  const [exportStartDate, setExportStartDate] = useState(dayjs().subtract(1, 'month').format('YYYY-MM-DD'))
  const [exportEndDate, setExportEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [exportType, setExportType] = useState('event')
  const [exporting, setExporting] = useState()
  const [autoRefresh, setAutoRefresh] = useState(getUserPreference({
    parentKey: 'statsTicketType',
    key: 'autoRefresh',
    defaultValue: false,
  }))

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsTicketType',
      key: 'autoRefresh',
      value: autoRefresh,
    })
  }, [autoRefresh])

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        setLoading(true)
      }, 10000)
      return () => clearInterval(interval)
    }
  }, [autoRefresh])

  useEffect(() => {
    if (event !== 'all') {
      getData()
    } else {
      setLoading(false)
      getRole()
    }
  }, [loading])

  useEffect(() => {
    updateGraph(parseInt(occupancy) || 0, parseInt(totalTickets) || 0)
  }, [totalTickets, occupancy])

  const getRole = () => {
    fetch(`${process.env.REACT_APP_API_URL}model-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName: 'stats-tickets',
        organization: getOrganization(),
        event: getEvent(),
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}model-stats-tickets`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        organization: getOrganization(),
        event: getEvent(),
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        const rawData = response.body
        if (response.status === 200) {
          setRole(rawData.role)
          setTitle(`Tickets - ${rawData.title}`)
          setTotalTickets(rawData.totalTickets)
          setOccupancy(rawData.totalTicketsWithProfile)
          setTicketTypesState(rawData.ticketTypes)
          setZones(rawData.zones)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
          if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const updateGraph = (value, currentTotalTickets) => {
    const step = Math.round(currentTotalTickets / 6)
    const marks = []
    let newMark = step || 1
    while (newMark < currentTotalTickets) {
      marks.push(newMark)
      newMark += step || 1
    }
    setGraphMarks(marks)
  }

  const exportNoShow = async () => {
    setExporting(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}report-no-show`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        organization: getOrganization(),
        event: getEvent(),
        startDate: exportStartDate,
        endDate: exportEndDate,
        type: exportType,
      }),
    })

    setExporting(false)

    if (res.status === 200) {
      if (res.headers.get('content-type').includes('text/csv')) {
        const csv = await res.json()
        const blob = new Blob([csv], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
  
        link.setAttribute('href', url)
        const dateTime = dayjs().format('YYYY-MM-DD_HH-mm-ss')
        link.setAttribute('download', `${dateTime} Tickets Stats.csv`)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        console.log('Error', res.status, 'No CSV file')
      }
    } else if (res.status === 403) {
      Cookies.remove('token')
      history.push('/login')  
    } else if (res.status === 401) {
      history.push(`/admin/no-access?url=${window.location.pathname}`)
    } else {
      const response = await res.json()
      console.log('Error', res.status, response.error)
    }
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {
        role &&
        <div className='flex justify-between'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      <div className='flex items-end justify-between mt-2'>
        <div className={`wght-semibold ${loading ? '' : 'opacity-0'}`}>Loading...</div>
        <div className='flex'>
          <Button
            className='ml-1'
            onClick={() => setAutoRefresh(!autoRefresh)}
            size='small'
            color={autoRefresh ? 'green' : 'white'}
          >
            Auto refresh
          </Button>
          {
            role === 'admin' &&
            <>
              <Button
                className="ml-1"
                onClick={() => setShowExportModal(true)}
                size="small"
              >Export</Button>
              <Modal
                className="w-1/2"
                showModal={showExportModal}
                setShowModal={setShowExportModal}
                background="white"
              >
                <div className="relative flex flex-col p-8 bg-white">
                  <CloseButton setShowModal={setShowExportModal} />
                  <h2 className="text-xl wght-semibold">Export</h2>
                  <div className="mt-4 text-sm">
                    <div className='flex'>
                      <div className="flex flex-col mr-2">
                        <label className=" text-gray wght-light">Start date</label>
                        <input
                          className="p-1 border rounded border-gray"
                          type="date"
                          placeholder="Start date"
                          value={exportStartDate}
                          onChange={(e) => setExportStartDate(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label className=" text-gray wght-light">End date</label>
                        <input
                          className="p-1 border rounded border-gray"
                          type="date"
                          placeholder="End date"
                          value={exportEndDate}
                          onChange={(e) => setExportEndDate(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="flex mt-4">
                      <label className="flex items-center mr-4">
                        <input
                          className="mr-1 cursor-pointer"
                          type="radio"
                          name="exportType"
                          value="event"
                          checked={exportType === 'event'}
                          onChange={() => setExportType('event')}
                        />
                        <span className="cursor-pointer ">By event</span>
                      </label>
                      <label className="flex items-center">
                        <input
                          className="mr-1 cursor-pointer"
                          type="radio"
                          name="exportType"
                          value="ticketType"
                          checked={exportType === 'ticketType'}
                          onChange={() => setExportType('ticketType')}
                        />
                        <span className="cursor-pointer ">By ticket type</span>
                      </label>
                    </div>

                    <div className="flex justify-end mt-4 text-base">
                      <Button
                        className="ml-2"
                        onClick={() => {
                          exportNoShow()
                        }}
                        disable={exporting}
                      >{!exporting ? 'Export' : 'Loading...'}</Button>
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          }
        </div>
      </div>

      {event === 'all' ? <h3 className="mt-8">Tickets data are grouped by event. Select an event from menu to start.</h3> : (
        <>
          {event !== 'all' && (!loading || occupancy) && totalTickets ? (
            <div className="mt-4">
              <h2 className="block mb-4 text-lg wght-semibold">Exchanged tickets</h2>
              <div className="p-2 bg-white">
                <div>
                  <h3 className="mb-2">
                    Total:
                    {totalTickets && (
                      <span className="wght-semibold">
                        &nbsp;&nbsp;&nbsp;{occupancy}
                        {totalTickets !== '0' ? `/ ${totalTickets}` : ''}
                      </span>
                    )}
                  </h3>
                </div>
                {totalTickets !== '0' && (
                  <div className={`w-full mb-2 `}>
                    <div className="relative w-full h-4 bg-white">
                      <div
                        className={`absolute border-gray border top-0 left-0 w-full h-4 bg-gradient-to-r from-red-500 occupancyBarGraph`}
                        style={{
                          background:
                            'linear-gradient(-90deg, #49e126 0%, #EFE15C 55%, rgba(255,125,87,1) 85%, rgba(187,26,0,1) 100%)',
                        }}
                      ></div>
                      <div
                        className={`absolute border-gray border border-l-0 top-0 right-0 w-full h-4 bg-gradient-to-r from-red-500 occupancyBarGraph`}
                        style={{
                          background: 'white',
                          width: `${100 - (occupancy / totalTickets * 100)}%`,
                          transition: 'width 1s',
                        }}
                      ></div>
                    </div>
                    <div className="flex justify-between w-full">
                      <span className="text-xs">0</span>
                      {graphMarks.map((mark, index) => (
                        <span key={index} className="text-xs">
                          {mark}
                        </span>
                      ))}
                      <span className="text-xs">{totalTickets}</span>
                    </div>
                  </div>
                )}
              </div>

              {ticketTypesState.length > 0 &&
                <div>
                  <h4 className="block mt-8 mb-2 wght-semibold">By ticket type</h4>
                  {ticketTypesState.map((ticketType, index) => {
                    if (ticketType && ticketType.tickets) {
                      const step = Math.round(ticketType.tickets.total / 6)
                      const marks = []
                      let newMark = step || 1
                      while (newMark < ticketType.tickets.total) {
                        marks.push(newMark)
                        newMark += step || 1
                      }

                      let barWidth = 99 - (ticketType.ticketsWithProfile.total / ticketType.tickets.total * 100)
                      if (barWidth === -1) {
                        barWidth = 0
                      }

                      return (
                        <div key={`${ticketType._id}-ticketType-${index}`} className="p-2 my-4 bg-white rounded-sm">
                          <div>
                            <h3 className="mb-2 ">
                              {ticketType.title}
                              {totalTickets && (
                                <span className="wght-semibold">
                                  &nbsp;&nbsp;&nbsp;
                                  {ticketType.ticketsWithProfile &&
                                    ticketType.ticketsWithProfile.total
                                  }
                                  {ticketType.tickets && ticketType.tickets.total !== '0' ? `/ ${ticketType.tickets.total}` : ''}
                                </span>
                              )}
                            </h3>
                          </div>

                          {totalTickets !== '0' && (
                            <div className={`w-full`}>
                              <div className="relative w-full h-4 bg-white">
                                <div
                                  className={`absolute border-gray border top-0 left-0 w-full h-4 bg-gradient-to-r from-red-500 occupancyBarGraph-ticketType-${index}`}
                                  style={{
                                    background:
                                      'linear-gradient(-90deg, #49e126 0%, #EFE15C 55%, rgba(255,125,87,1) 85%, rgba(187,26,0,1) 100%)',
                                  }}
                                ></div>
                                <div
                                  className={`absolute border-gray border border-l-0 top-0 right-0 w-full h-4 bg-gradient-to-r from-red-500 occupancyBarGraph-ticketType-${index}`}
                                  style={{
                                    background: 'white',
                                    width: `${barWidth}%`,
                                    transition: 'width 1s',
                                  }}
                                ></div>
                              </div>
                              <div className="flex justify-between w-full">
                                <span className="text-xs">0</span>
                                {marks.map((mark, index) => (
                                  <span key={`mark-${index}`} className="text-xs">
                                    {mark}
                                  </span>
                                ))}
                                <span className="text-xs">{ticketType.tickets.total}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    }
                    return <div className="p-2 bg-white" key={ticketType.title}>
                      <h3 className="">
                        {ticketType.title}: <span className="wght-semibold">no tickets for this type</span>
                      </h3>
                    </div>
                  })}
                </div>
              }

              {zones.length > 0 &&
                <div>
                  <h2 className="block mt-12 mb-4 text-lg wght-semibold">Tickets inside zones</h2>
                  {zones.map((zone, index) => {
                    if (zone && zone.zone) {
                      const zoneCapacity = Number(zone.zone.capacity)
                      const zoneOccupancy = Number(zone.zone.occupancy)
                      const zoneTitle = zone.zone.title

                      const step = Math.round(zoneCapacity / 6)
                      const marks = []
                      let newMark = step || 1
                      while (newMark < zoneCapacity) {
                        marks.push(newMark)
                        newMark += step || 1
                      }

                      let barWidth = 99 - (zoneOccupancy / zoneCapacity * 100)
                      if (barWidth === -1) {
                        barWidth = 0
                      }

                      return (
                        <div key={`${zone._id}-zone-${index}`} className="p-2 my-4 bg-white rounded-sm">
                          <div>
                            <h3 className="">
                              {zoneTitle}
                              <span className="wght-semibold">
                                &nbsp;&nbsp;&nbsp;
                                {zoneOccupancy}
                                {zoneCapacity && zoneCapacity > 0 ? `/ ${zoneCapacity}` : ''}
                              </span>
                            </h3>
                          </div>

                          {zoneCapacity > 0 && (
                            <div className={`w - full mt - 2`}>
                              <div className="relative w-full h-4 bg-white">
                                <div
                                  className={`absolute border - gray border top - 0 left - 0 w - full h - 4 bg - gradient - to - r from - red - 500 occupancyBarGraph - ticketType - ${index} `}
                                  style={{
                                    background:
                                      'linear-gradient(-90deg, #49e126 0%, #EFE15C 55%, rgba(255,125,87,1) 85%, rgba(187,26,0,1) 100%)',
                                  }}
                                ></div>
                                <div
                                  className={`absolute border - gray border border - l - 0 top - 0 right - 0 w - full h - 4 bg - gradient - to - r from - red - 500 occupancyBarGraph - ticketType - ${index} `}
                                  style={{
                                    background: 'white',
                                    width: `${barWidth}% `,
                                    transition: 'width 1s',
                                  }}
                                ></div>
                              </div>
                              <div className="flex justify-between w-full">
                                <span className="text-xs">0</span>
                                {marks.map((mark, index) => (
                                  <span key={index} className="text-xs">
                                    {mark}
                                  </span>
                                ))}
                                <span className="text-xs">{zoneCapacity}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )
                    }
                    return <div className="p-2 bg-white" key={zone.zone.title}>
                      <h3 className="">
                        {zone.zone.title}: <span className="wght-semibold">no tickets for this type</span>
                      </h3>
                    </div>
                  })}
                </div>
              }

            </div>
          ) :
            <Loader loading={loading} className="mt-8" text="">
              <h3 className="mt-8">No tickets</h3>
            </Loader>
          }
        </>
      )}

    </div>
  )
}

export default StatsTickets
