import React from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import Cookies from 'js-cookie'

const removeLastSlash = (pathname) => {
  return pathname[pathname.length - 1] === '/' ? pathname.slice(0, -1) : pathname
}

const getUserPreference = ({ parentKey, key, defaultValue = false }) => {
  const userPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))
  if (userPreferences) {
    if (userPreferences[parentKey]) {
      return userPreferences[parentKey][key] || defaultValue
    } else {
      return userPreferences[key] || defaultValue
    }
  }
  return defaultValue
}

const updateCookiePreferences = ({
  parentKey = '',
  key,
  value,
  remove,
}) => {
  let userManagerPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))
  if (!userManagerPreferences) {
    userManagerPreferences = {}
  }

  if (parentKey) {
    userManagerPreferences[parentKey] = userManagerPreferences[parentKey] || {}
    if (remove) {
      delete userManagerPreferences[parentKey][key]
    } else {
      userManagerPreferences[parentKey][key] = value
    }
  } else {
    if (remove) {
      delete userManagerPreferences[key]
    } else {
      userManagerPreferences[key] = value
    }
  }

  Cookies.set('userManagerPreferences', JSON.stringify(userManagerPreferences), { expires: 365 })
}

const getUpdatedColumns = (column, visibleColumns) => {
  let rawColumns = [...visibleColumns]
  if (rawColumns.includes(column)) {
    rawColumns = rawColumns.filter(col => col !== column)
  } else {
    rawColumns.push(column)
  }

  return rawColumns
}

const parseField = (key, item, extraInfo) => {

  if (key === 'firebaseUID') {
    return <span className={`mr-4 text-sm whitespace-nowrap ${item[key] ? 'text-primary' : 'text-error'}`}>
      {item[key] ? 'Registered' : 'Not registered'}
    </span>
  }

  if (!item[key] || typeof item[key] === 'undefined') {
    return ''
  }

  if (key === 'userId') {
    return <Link
      className="mr-4 cursor-pointer hover:text-primary hover:wght-semibold text-primary"
      to={`/admin/user/${item[key].id}/${item[key].profile._id}`}
    >
      {item[key].profile.name}
    </Link>
  }

  if (key.includes("modal") && extraInfo && extraInfo.data) {

    const { data, setShowModelModal, setInfoModelModal } = extraInfo

    return <button
      className="flex mt-1 text-xs cursor-pointer pointer-events-auto text-primary hover:wght-semibold"
      onClick={() => {
        console.log('heeeey')
        setShowModelModal(state => !state)
        setInfoModelModal({
          title: data.modelConfig.list[key],
          text: item[key]
        })
      }}
    >
      <span className='mr-1 text-lg -mt-2 pt-0.5'>≣</span> {data.modelConfig.list[key]}
    </button>
  }

  if (Array.isArray(item[key])) {
    return item[key].map((i, index) => {
      const subitem = {}
      subitem[key] = i
      return parseField(key, subitem)
    }).join(', ')
  }

  if (typeof item[key] === 'object') {
    return item[key].label || item[key].title || item[key].en || JSON.stringify(item[key])
  }

  if (['date', 'day'].some(v => key.toLowerCase().includes(v)) && dayjs(item[key]).isValid()) {
    return dayjs(item[key]).format('DD/MM/YYYY')
  }

  if (['datetime', 'time', 'createdat', 'updatedat'].some(v => key.toLowerCase().includes(v)) && dayjs(item[key]).isValid()) {
    return dayjs(item[key]).format('DD/MM/YYYY HH:mm:ss')
  }

  return item[key]
}

export {
  removeLastSlash,
  getUserPreference,
  updateCookiePreferences,
  getUpdatedColumns,
  parseField,
}
