import React, { createContext, useContext, useEffect, useState } from "react";
import { getAuth } from 'firebase/auth';
import Cookies from 'js-cookie';

const AuthContext = createContext([]);

const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = getAuth().onIdTokenChanged(async (user) => {
      if (user) {
        setSession(user.accessToken)
      } else {
        Cookies.remove('user')
      }
      setLoading(false)
    });

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider value={session}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

function useSession() {
  return useContext(AuthContext);
}

export { useSession, AuthProvider };
