import React from 'react'

const Loader = ({
  loading = false,
  text = 'Loading...',
  className,
  children,
}) => {
  if (loading) {
    return <div className={className}>
      {text}
    </div>
  }
  return children
}

export default Loader
