import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Form from './Form/Form'
import flatten from './Lib/flatten'
import { useSession } from '../contexts/AuthContext'

const ShowOptionsForm = (props) => {
  const {
    importData: data,
    setImportData: setData,
    setLoading,
    url,
  } = props
  const history = useHistory()
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})
  const token = useSession()
  const {
    GuestsEvent,
    GuestsCategory,
    GuestsEntity,
    GuestsQuota,
    GuestsInvitation,
  } = useParams()

  const updateStored = (key, value, errs = []) => {
    if (!data.stored) {
      data.stored = {}
    }

    if (typeof data.stored.modelName === 'undefined') {
      data.stored.modelName = props.modelName
    }

    data.stored[key] = value
    errors[key] = errs
    setData({ ...data })
    setErrors({ ...errors })
  }

  useEffect(() => {
    setData(false)

    fetch(`${process.env.REACT_APP_API_URL}model-edit`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName: props.modelName,
        filters: {
          event: GuestsEvent || Cookies.get('event'),
          organization: Cookies.get('organization'),
        },
        defaultSelectedOptions: {
          GuestsEvent,
          GuestsCategory,
          GuestsEntity,
          GuestsQuota,
          GuestsInvitation,
        },
        modelsToShow: props.modelsToShow,
        url: url || null,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setData(response.body)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/guests/list/${props.modelName}`);
          }
        }
      })
  }, [props.modelName])


  const save = async () => {
    const errs = flatten(errors)
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
    }
  }

  return (
    <div className="px-4">
      {!data && <div className="px-4 py-8">Loading...</div>}
      {data && props.modelName && (
        <div>
          {props.children}
          <Form
            onChange={updateStored}
            data={data}
            name={props.modelName}
            showErrors={showErrors}
            mode="create"
            currentData={data}
          />
        </div >
      )}
    </div >
  )
}

export default ShowOptionsForm