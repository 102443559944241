import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSession } from '../contexts/AuthContext'

const useSessionPermissions = (load = true, modelName, endpoint) => {
  const [role, setRole] = useState(false)
  const [loading, setLoading] = useState(load)
  const history = useHistory()
  const token = useSession()


  useEffect(() => {
    (async () => {
      if (!loading) {
        setLoading(true)
      }

      await fetch(`${process.env.REACT_APP_API_URL}session-permissions`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName,
          endpoint,
          currentOrganization: Cookies.get('organization'),
          currentEvent: Cookies.get('event')
        }),
      })
        .then(response => response
          .json()
          .then(data => ({ status: response.status, body: data })))
        .then(response => {
          if (response.status === 200) {
            setRole(response.body.role)
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.status === 403) {
              Cookies.remove('user')
              history.push('/login')
            } else if (response.status === 401) {
              history.push(`/admin/no-access?url=${window.location.pathname}`)
            }
          }
          setLoading(false)
        })
    })()
  }, [])

  return {
    role,
    loading,
    setLoading
  }
}

export default useSessionPermissions