import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useHistory, useParams } from 'react-router-dom'
import useSessionPermissions from '../../hooks/useSessionPermissions'
import { useSession } from '../../contexts/AuthContext'
import ImportExport from '../ImportExport'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'

const ImportDiceTicketTypePage = () => {
  const { event } = useParams()
  const { getOrganization } = useOrganizationInfo(event)
  const history = useHistory()
  const [currentFile, setCurrentFile] = useState()
  const { role, loading } = useSessionPermissions('ticketType', 'ticketType')
  const token = useSession()
  const [uploading, setUploading] = useState(false)

  const [errorMessage, setErrorMessage] = useState()

  const handleChange = (e) => {
    setCurrentFile(e.target.files[0])
  }

  useEffect(() => {
    if (currentFile) {
      upload()
    }
  }, [currentFile])
  
  const [result, setResult] = useState()

  const upload = () => {
    setUploading(true)
    const formData = new FormData()
    formData.append('organization', getOrganization())
    formData.append('file', currentFile)

    fetch(`${process.env.REACT_APP_API_URL}import-dice-tickettypes`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setUploading(false)
        if (response.status === 200) {
          setResult(response.body.imported)
          setTimeout(() => {
            setResult()
          }, 6000);
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
          setErrorMessage(response.body.error)
          setTimeout(() => {
            setErrorMessage()
          }, 6000);
        }
      })
  }

  return (
    <div>
      <h1 className="text-xl wght-semibold">Import DICE Ticket Types</h1>
      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }
      {loading && <div className="mt-4">Loading...</div>}
      {!loading &&
        <>
          <p className="mt-4">Select a CSV file with the columns:</p>
          <p className="mb-4 font-mono text-xs">diceTicketTypeId, ticketTypeId</p>
          <div className='flex'>
            <ImportExport
              uploading={uploading} handleImport={handleChange}
            />
          </div>
          {result && <p className="mt-4 text-green">Imported successfully: {result}</p>}
          {errorMessage && <p className="mt-4 text-red">Error: {errorMessage}</p>}
        </>
      }
    </div>
  )
}

export default ImportDiceTicketTypePage