import React from 'react'

const NoAccess = () => {

  return (
    <div>
      <h1 className="mb-4 text-xl wght-semibold">Permission denied</h1>
      <p>You don't have permission to access the section.</p>
      <p>Check if you have selected the correct organization.</p>
    </div>
  )
}

export default NoAccess