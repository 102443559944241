import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import colorFromString from './Lib/colorFromString'
import { useSession } from '../contexts/AuthContext'

const ImageToLoad = ({ type, id, autoLoad }) => {
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState(null)
  const token = useSession()

  useEffect(() => {
    if (autoLoad) {
      loadImage()
    }
  })

  const loadImage = () => {

    if (url) return

    setLoading(true)
    fetch(`${process.env.REACT_APP_GRAPH_URL}/public-url-for-file`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({ id, type }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setUrl(response.body.url)
        }
      })
  }

  if (url) {
    return (
      <img className="max-w-full max-h-full" alt="" src={url} />
    )
  }

  return <div className="p-1 px-2 border border-solid rounded cursor-pointer hover:text-primary border-grayLight" onClick={loadImage}>{loading ? 'Loading...' : 'Load image'}</div>
}


const Rat = ({ userId, profileId, result, _id, userPhoto, file }) => {
  const [showChange, setShowChange] = useState(false)
  const [loading, setLoading] = useState(false)
  const token = useSession()

  const changeResult = (status) => {

    if (loading) return

    setLoading(true)
    fetch(`${process.env.REACT_APP_GRAPH_URL}alter-rat-result`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        userId,
        profileId,
        ratId: _id,
        result: status,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          window.location.reload();
        } else {
          alert('Unknown error')
        }
      })
  }

  return (
    <div className="w-full mt-2">
      <div className="flex justify-between w-full">
        <div>
          <strong className="wght-bold">Result: </strong>
          {result === 'pending' && <span className="text-gray">Pending</span>}
          {result === 0 && <span className="text-success">Negative</span>}
          {result === 1 && <span className="text-error">Positive</span>}
          {result === 2 && <span className="text-orange">Inconclusive</span>}
        </div>
        <span onClick={() => setShowChange(!showChange)} className="cursor-pointer hover:text-primary">Change result</span>
      </div>
      {showChange && <div className="p-2 mt-2 rounded bg-grayLight">
        <span onClick={() => changeResult(0)} className="mr-2 cursor-pointer hover:text-primary">Negative</span>
        <span onClick={() => changeResult(1)} className="mr-2 cursor-pointer hover:text-primary">Positive</span>
        <span onClick={() => changeResult(2)} className="mr-2 cursor-pointer hover:text-primary">Inconclusive</span>
      </div>}
      {userPhoto && <div className="mt-2"><span className="block mt-2 wght-bold">User Photo:</span><ImageToLoad type="rat" id={userPhoto._id} /></div>}
      {file && <div className="mt-2"><span className="block mt-2 wght-bold">RAT Photo:</span><ImageToLoad type="rat" id={file._id} /></div>}
    </div>
  )
}

const Photo = ({ _id }) => {
  return (
    <div className="w-full mt-2">
      <ImageToLoad type="photo24" id={_id} />
    </div>
  )
}

const ProfilePhoto = ({ fileId }) => {
  return (
    <div className="w-full p-2 mt-2 bg-white rounded">
      <ImageToLoad type="photo24" id={fileId} />
    </div>
  )
}

const Ticket = ({ _id, ticketType, ticketId }) => {

  return (
    <div className="w-full p-2 mt-2 bg-white rounded">
      <span className="block"><span className="wght-bold">ID</span>: <Link className="hover:text-primary" to={`/admin/tickettype/edit/${_id}`}>{_id}</Link></span>
      <span className="block"><span className="wght-bold">TicketID</span>: <Link className="hover:text-primary" to={`/admin/ticket/edit/${_id}`}>{ticketId}</Link></span>
      <span className="block"><span className="wght-bold">TicketType</span>: <Link className="hover:text-primary" to={`/admin/tickettype/edit/${ticketType}`}>{ticketType}</Link></span>
    </div>
  )
}

const EventLocationZone = ({ _id, title }) => {

  return (
    <div className="w-full p-2 mt-2 bg-white rounded">
      <span className="block"><span className="wght-bold">Zone</span>: <Link className="hover:text-primary" to={`/admin/stats/EventLocationZone/${_id}`}>{title}</Link></span>
    </div>
  )
}

const ProfileTicket = ({ _id, userId, profileId, ticketType }) => {

  return (
    <Link
      to={`/admin/user/${userId}/${profileId}/${_id}`}
      className="p-2 mr-2 text-xs border border-solid rounded shrink-0 border-grayLight bg-grayLight wght-bold hover:bg-white"
    >
      {ticketType && <div>{ticketType.title}</div>}
    </Link>
  )
}

const Section = ({ title, open = true, children }) => {

  const [isOpen, setIsOpen] = useState(open)

  return (
    <div className="mb-4 border rounded border-primary border-1">
      <h2 onClick={() => setIsOpen(!isOpen)} className="flex justify-between px-2 py-1 text-sm text-white cursor-pointer bg-primary">
        <span>{title}</span>
        <span className={`select-none transition ${isOpen ? 'rotate-180' : ''}`}>&darr;</span>
      </h2>
      {isOpen && <div className={`text-sm p-2`}>
        {children}
      </div>}
    </div>
  )

}

const User = () => {

  const { userId, profileId } = useParams()
  const history = useHistory()

  const [ready, setReady] = useState(false)
  const [data, setData] = useState()
  const [event, setEvent] = useState()
  const [events, setEvents] = useState([])
  const [profile, setProfile] = useState()
  const [user, setUser] = useState()
  const [currentEvent, setCurrentEvent] = useState()
  const [newEmail, setNewEmail] = useState()
  const [loading, setLoading] = useState(false)
  const [updatingEmail, setUpdatingEmail] = useState(false)
  const token = useSession()

  useEffect(() => {
    setLoading(true)
    setData(false)

    fetch(`${process.env.REACT_APP_API_URL}user`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        profileId,
        currentEvent: Cookies.get('event'),
        currentOrganization: Cookies.get('organization'),
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {

          const { user } = response.body
          const { profiles } = user
          const profile = profiles.find(profile => profile._id === profileId)
          const events = profile.tickets ? profile.tickets.map(ticket => ticket.event).filter((value, index, array) => array.findIndex(v => v._id === value._id) === index).sort((a, b) => dayjs(a.startDate).isBefore(dayjs(b.startDate)) ? 1 : -1) : []

          setUser(user)
          setProfile(profile)
          setEvents(events)
          setData(response.body)
          const eventId = Cookies.get('event') || events[0]._id
          setEvent(eventId)

          let currentEvent = events.find(ev => String(ev._id) === String(event))
          if (!currentEvent || currentEvent === 'undefined') {
            currentEvent = events[0]
          }
          setCurrentEvent(currentEvent)

          setReady(true)

        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/user/${userId}/${profileId}`);
          }
        }
      })
  }, [userId, profileId])

  if (loading) {
    return <div className="ml-auto">Loading...</div>
  }

  if ((!data || !user || !user.profiles) || !ready) {
    return <div />
  }

  const changeEmail = (value) => {
    setUpdatingEmail(true)
    fetch(`${process.env.REACT_APP_API_URL}user`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        profileId,
        newEmail: value,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setUpdatingEmail(false)
        setTimeout(() => {
          if (response.status === 200) {
            setData(response.body)
            alert(`Email changed to: \n\n${response.body.user.email}`)
          } else {
            console.log('Error', response.status, response.body.error)
            if (response.body.error === 'email_exist') {
              alert('Email exist with other user')
            } else {
              if (response.status === 403) {
                Cookies.remove('user')
                history.push('/login')
              } else if (response.status === 401) {
                window.location.replace(`/admin/no-access?url=/admin/user/${userId}/${profileId}`);
              }
            }
          }
        }, 200);
      })
  }

  return (
    <div className="p-2 bg-white">
      <Helmet>
        <title>User: {profile.name || ''}{profile.name && user.email ? ' - ' : ''}{user.email || ''}</title>
      </Helmet>
      <div>

        <div className="mb-4">
          <select className="w-full p-2 rounded bg-grayLight" defaultValue={event} onChange={(e) => {
            setEvent(e.target.value)
          }}>
            {events.map(ev => <option key={ev._id} value={ev._id}>Evento: {ev.title}</option>)}
          </select>
        </div>

        <Section title="User">
          <div className="flex flex-wrap justify-between">
            <select className="p-2 rounded bg-grayLight" defaultValue={profileId} onChange={(e) => {
              history.push(`/admin/user/${userId}/${e.target.value}`)
            }}>
              {user.profiles.map(profile => <option key={profile._id} value={profile._id}>{profile.name}{profile.owner && ' (main profile)'}</option>)}
            </select>
            <div>
              <h1 className="mb-0 text-xl">{user.email}</h1>
              <span className="text-xs">{user._id}</span>
            </div>
          </div>

          <h2 className="mt-4 wght-bold">Tickets for {currentEvent.title}</h2>
          <div className="overflow-scroll">
            <div className="flex">
              {profile.tickets && profile.tickets.reverse().filter(t => {
                if (!event) {
                  return String(t.event._id) === String(events[0]._id)
                }
                return String(t.event._id) === String(event)
              }).map(ticket => (
                <ProfileTicket key={ticket._id} userId={userId} profileId={profileId} {...ticket} />
              ))}
            </div>
          </div>

          <h2 className="mt-4 wght-bold">Profile information</h2>
          <div>
            {profile && profile.name && <div><span className="wght-semibold">Name</span>: {profile.name}</div>}
            {profile && profile.firstName && <div><span className="wght-semibold">First Name</span>: {profile.firstName}</div>}
            {profile && profile.lastName && <div><span className="wght-semibold">Last Name</span>: {profile.lastName}</div>}
            {profile && profile.email && <div><span className="wght-semibold">Email</span>: <a href={`mailto:${profile.email}`}>{profile.email}</a></div>}
            {profile && profile.phoneNumberPrefix && <div><span className="wght-semibold">Country Prefix</span>: {profile.phoneNumberPrefix}</div>}
            {profile && profile.phoneNumber && <div><span className="wght-semibold">Phone Number</span>: {profile.phoneNumber}</div>}
            {profile && profile.birthDate && <div><span className="wght-semibold">Phone Number</span>: {dayjs(profile.birthDate).format('DD/MM/YYYY')}</div>}
          </div>
        </Section>

        <Section title="Purchases" open={false}>
          {typeof user.purchases && user.purchases.filter(purchase => {
            return (typeof purchase.ticket !== 'undefined' && typeof purchase.ticket.ticketType !== 'undefined' && purchase.ticket.ticketType.event !== 'undefined' && purchase.ticket.ticketType.event === event && ['PAID', 'EXCHANGED', 'REFUNDED'].includes(purchase.status))
          }).map(purchase => (
            <div key={purchase._id} className="p-2 mb-2 text-xs border rounded border-grayLight">
              <div className="flex justify-between">
                <div>
                  <span className="font-weight"><Link to={`/admin/edit/Shop/${purchase.shop._id}`}>{purchase.shop.title}</Link> </span>
                  <span className={`ml-2 text-white rounded px-1 ${purchase.status === 'REFUNDED' ? 'bg-error' : ''} ${purchase.status === 'PAID' ? 'bg-warning' : ''} ${purchase.status === 'EXCHANGED' ? 'bg-success' : ''}`}>{purchase.status}</span>
                </div>
                <span className="text-gray">{dayjs(purchase.creationDate).format('DD/MM/YYYY HH:mm:ss')}</span>
              </div>
              <div className="p-2 mt-2 rounded bg-grayLight">
                <span className="block"><span className="wght-bold">Price</span>: {purchase.totalPrice}EUR (Paid {purchase.priceToPay && purchase.priceToPay.priceToPayTotal}EUR)</span>
                <span className="block"><span className="wght-bold">Products</span>: {purchase.products.map(product => {
                  if (typeof product.name === 'string') {
                    return `${product.units} x ${product.name} = ${product.totalPrice}EUR`
                  }
                  return `${product.units} x ${product.name[Object.keys(product.name)[0]]} = ${product.totalPrice}EUR`
                }).join(', ')}</span>
                {purchase.priceToPay && purchase.priceToPay.priceToPayTotal === 0 && <span className="px-1 bg-white rounded">Paid with balance</span>}
              </div>
              <div className="mt-2">
                <Link to={`/admin/purchase/${purchase._id}`} className="px-1 text-white rounded bg-primary">View purchase</Link>
              </div>
            </div>
          ))}
          {typeof user.purchases === 'undefined' || user.purchases.length === 0 && <span className="text-sm">No purchases found for this user</span>}
        </Section>

        <Section title="History" open={false}>
          {typeof profile.history !== 'undefined' && profile.history.map((history, index) => (
            <div key={`${index}.${history.date}`} className="p-1 mb-2 text-xs border border-solid rounded border-grayLight">
              <div className="flex justify-between">
                <span className="px-1 mr-2 rounded border-1 border-gray" style={{ backgroundColor: colorFromString(history.type) }}>{history.type} → {history.action}</span>
                <span className="text-gray">{dayjs(history.date).format('DD/MM/YYYY HH:mm:ss')}</span>
              </div>
              <div className="p-2 mt-1 rounded bg-grayLight">
                {history.data && Object.keys(history.data).length > 0 &&
                  Object.keys(history.data).map(key => <span key={key} className="block"><span className="wght-bold">{key}</span>: {history.data[key]}</span>)}
                {history.related && history.type === 'ticket' && <Ticket {...history.related} />}
                {history.related && history.type === 'eventlocationzone' && <EventLocationZone {...history.related} />}
                {history.type === 'profile' && history.action === 'photo-added' && <ProfilePhoto {...history.data} />}
              </div>
            </div>
          ))}
        </Section>

        <Section title="Photos" open={false}>
          {typeof profile.requirements !== 'undefined' && typeof profile.requirements.photo !== 'undefined' && profile.requirements.photo.map(photo => (
            <div key={photo._id} className="p-1 mb-2 text-xs border rounded border-grayLight">
              <div className="flex">
                <span className="text-gray">{dayjs(photo.date).format('DD/MM/YYYY HH:mm:ss')}</span>
              </div>
              <div className="p-2 mt-1 rounded bg-grayLight">
                <span className="block"><span className="wght-bold">fileId</span>: {photo.fileId}</span>
              </div>
              {photo.related && <Photo {...photo.related} />}
            </div>
          ))}
          {typeof profile.requirements === 'undefined' || typeof profile.requirements.photo === 'undefined' || profile.requirements.photo.length === 0 && <span className="text-sm">No Photos found for this profile</span>}
        </Section>

        <Section title="RATs" open={false}>
          {typeof profile.requirements !== 'undefined' && typeof profile.requirements.rat !== 'undefined' && profile.requirements.rat.map(rat => (
            <div key={rat._id} className="p-2 mb-2 text-xs bg-white rounded">
              <div className="flex">
                <span className="text-gray">{dayjs(rat.date).format('DD/MM/YYYY HH:mm:ss')}</span>
              </div>
              <div className="p-2 mt-2 rounded bg-grayLight">
                <span className="block"><span className="wght-bold">ratId</span>: {rat._id}</span>
              </div>
              {rat.related && <Rat userId={userId} profileId={profileId} {...rat.related} />}
            </div>
          ))}
          {typeof profile.requirements === 'undefined' || typeof profile.requirements.rat === 'undefined' || profile.requirements.rat.length === 0 && <span className="text-sm">No RATs found for this profile</span>}
        </Section>

        <Section title={`Change user email - ${user.email}`} open={false}>
          <div className="w-full mb-2 sm:mb-0 sm:w-auto">
            <div className="flex flex-col md:flex-row">
              <input
                name="search"
                className={`rounded px-2 border-1 bg-grayLight placeholder-gray mr-2`}
                type="text"
                placeholder="New email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
              {updatingEmail ? <span className="ml-auto">Loading...</span> :
                <span className="mr-2 cursor-pointer hover:wght-semibold" onClick={() => {
                  const confirm = window.confirm(`Do you want to change update email for this one? \n \n${newEmail}`)
                  if (confirm) {
                    changeEmail(newEmail)
                  }
                }}>Change email</span>
              }
            </div>
          </div>
        </Section>

      </div>
    </div>
  )
}

export default User