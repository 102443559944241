import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import { useSession } from '../contexts/AuthContext'

const User = () => {
  const { purchaseId, event } = useParams()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState()
  const [showRefund, setShowRefund] = useState(false)
  const [refund, setRefund] = useState(0)
  const [message, setMessage] = useState('')
  const token = useSession()


  const add = () => {

    if (message.length === 0) return

    fetch(`${process.env.REACT_APP_API_URL}purchasehistory`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: purchaseId,
        text: message,
        action: 'add',
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          window.location.reload()
        } else {
          if (response.status === 403) {
            alert('There was a problem')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/${event}/purchase/${purchaseId}`);
          }
        }
      })
  }

  const doRefund = () => {
    if (loading) return
    const { purchase } = data
    const r = Number(refund)
    if (typeof purchase.priceToPay.priceToPayTotal !== 'undefined') {
      if (r > purchase.priceToPay.priceToPayTotal) {
        alert(`The max you can refund is ${purchase.priceToPay.priceToPayTotal}`)
      }
    } else {
      if (r > purchase.totalPrice) {
        alert(`The max you can refund is ${purchase.totalPrice}`)
      }
    }
    if (window.confirm(`*** REFUND ${r}EUR to ${purchase.user.email}? ***`)) {
      setLoading(true)
      fetch(`${process.env.REACT_APP_GRAPH_URL}refundpurchase`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'AccessTicket-App': 'manager',
        },
        body: JSON.stringify({
          purchaseId: purchase._id,
          quantity: refund,
        }),
      })
        .then(response => response
          .json()
          .then(data => ({ status: response.status, body: data })))
        .then(response => {
          setLoading(false)
          if (response.status === 200) {
            window.location.reload();
          } else {
            alert(response.body.message || 'Unknown error')
          }
        })
    }
  }

  useEffect(() => {
    setData(false)
    fetch(`${process.env.REACT_APP_API_URL}purchase`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        purchaseId,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setData(response.body)
          const { purchase } = response.body
          if (purchase) {
            setRefund(purchase.priceToPay.priceToPayTotal || purchase.totalPrice || 0)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/${event}/purchase/${purchaseId}`);
          }
        }
      })
  }, [purchaseId])

  if (!data || !data.purchase) {
    return <div />
  }

  const { purchase } = data

  return (
    <div>
      <Helmet>
        <title>{`${purchase.shop.title}`} - {`${purchase.totalPrice}`}EUR</title>
      </Helmet>
      <div>
        <div>
          <Link to={`/admin/user/${purchase.user._id}/${purchase.profile}`} className="hover:text-primary">← Go back to the profile</Link>
        </div>
        <div key={purchase._id} className="p-2 mt-2 text-sm bg-white">
          <div className="flex justify-between">
            <div>
              <h2 className="text-xl font-weight"><Link to={`/admin/${event}/edit/Shop/${purchase.shop._id}`}>{purchase.user.email} at {purchase.shop.title}</Link> </h2>
              <div className={`text-white inline-block rounded px-1 ${purchase.status === 'REFUNDED' ? 'bg-error' : ''} ${purchase.status === 'PAID' ? 'bg-warning' : ''} ${purchase.status === 'EXCHANGED' ? 'bg-success' : ''}`}>{purchase.status}</div>
              <div className="mt-2 text-grayDark">{dayjs(purchase.creationDate).format('DD/MM/YYYY HH:mm:ss')}</div>
            </div>
            {purchase.status !== 'REFUNDED' && <div>
              {purchase.paymentIntentId && <div className="p-4 text-white rounded cursor-pointer bg-primary" onClick={() => setShowRefund(true)}>Refund</div>}
            </div>}
            {showRefund && <div className="fixed inset-0 flex items-center justify-center" style={{ background: 'rgba(0,0,0,.6)' }} onClick={() => setShowRefund(false)}>
              <div className="p-4 bg-white rounded" onClick={e => e.stopPropagation()}>
                <div className="wght-bold">Refund</div>
                <div>
                  <input type="number" step="0.01" className="p-2 border border-solid rounded border-gray" type="text" value={refund
                  } onChange={e => {
                    setRefund(e.target.value)
                  }} />
                </div>
                <span onClick={doRefund} className="block p-2 mt-2 text-center text-white rounded cursor-pointer bg-primary">
                  {loading ? 'Refunding...' : `Refund ${refund}EUR`}
                </span>
              </div>
            </div>}
          </div>
          <div className="p-2 mt-2 rounded bg-grayLight">
            <h3 className="wght-bold">Price</h3>
            <span>{purchase.totalPrice}EUR (Paid {purchase.priceToPay && purchase.priceToPay.priceToPayTotal}EUR)</span>
            <h3 className="mt-2 wght-bold">Products</h3>
            <div className="mb-2">
              {purchase.products.map(product => {
                if (typeof product.name === 'string') {
                  return <span key={product.productId}>{`${product.units} x ${product.name} = ${product.totalPrice}EUR`}</span>
                }
                return <span key={product.productId}>{`${product.units} x ${product.name[Object.keys(product.name)[0]]} = ${product.totalPrice}EUR`}</span>
              })}
            </div>
            {purchase.paymentIntentId && <span className="block"><a className="hover:text-primary" target="_blank" href={`https://dashboard.stripe.com/payments/${purchase.paymentIntentId}`}>View on Stripe</a></span>}
            {purchase.priceToPay && purchase.priceToPay.priceToPayTotal === 0 && <span className="px-1 bg-white rounded">Paid with balance</span>}
          </div>

          <h2 className="mt-4 wght-bold">Support history</h2>
          <div className="p-2 mt-2 rounded bg-grayLight">
            {(!purchase.supportHistory || purchase.supportHistory.length === 0) && <span>No support history for this purchase</span>}
            {purchase.supportHistory && purchase.supportHistory.map(history => <div key={history.date} className="p-2 mb-2 text-sm bg-white rounded">
              <div className="text-xs text-gray">{dayjs(history.date).format('DD/MM/YYYY HH:mm:ss')}</div>
              <div className="text-sm">{history.text}</div>
            </div>)}
            <div>
              <textarea placeholder="Type your message" onChange={e => setMessage(e.target.value)} value={message} className="w-full p-2 rounded" name="" id="" cols="30" rows="2"></textarea>
              {message && message.length > 0 && <span onClick={add} className="inline-block px-2 text-white rounded cursor-pointer bg-primary">Add</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default User