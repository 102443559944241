import React, { useState, useEffect } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import Select from 'react-select';
import { ListControlDevices, UpdateControlDeviceStatus, DeleteControlDevice, KillControlDevice, RefreshControlDeviceTicketTypes, RebootControlDevice, ListControlDevicesCategories } from '../../externalApi/gql.services'
import Button from '../Button'
import useSessionPermissions from '../../hooks/useSessionPermissions'
import InvitationModal from '../InvitationModal'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'
import { getUserPreference, updateCookiePreferences } from '../Lib/utils'
import { selectTheme, selectStyles } from '../Lib/helpers'

const NOT_ASSIGNED_ZONE = 'Not Assigned'

const ControlDevices = ({ menuEventsOptions }) => {
  const history = useHistory()
  const { event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const modelName = 'controldevices'
  const [data, setData] = useState()
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingZones, setLoadingZones] = useState(false)
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [device, setDevice] = useState()
  const [eventTickets, setEventTickets] = useState()
  const [loadingTickets, setLoadingTickets] = useState()
  const [publicId, setPublicId] = useState()
  const [eventZones, setEventZones] = useState()
  const [zoneId, setZoneId] = useState()
  const [eventId, setEventId] = useState()
  const [nfcMode, setNfcMode] = useState()
  const [offlineCapable, setOfflineCapable] = useState()
  // NOTE: horrible oversuse of useState, change it a object state or maybe a useReducer approach
  const [allowToShowMetrics, setAllowToShowMetrics] = useState()
  const [hideUserFunctions, setHideUserFunctions] = useState()
  const [isAllowedToCancel, setIsAllowedToCancel] = useState()
  const [randomMode, setRandomMode] = useState()
  const [name, setName] = useState()
  const [deviceName, setDeviceName] = useState()
  const [type, setType] = useState()
  const [defaultDeviceReaderSelector, setDefaultDeviceReaderSelector] = useState()
  const [readerModeOnly, setReaderModeOnly] = useState(false)
  const [hexId, sethexId] = useState()
  const [category, setCategory] = useState()
  const [filterCategories, setFilterCategories] = useState()
  const [editedDevice, setEditedDevice] = useState()
  const [showActions, setShowActions] = useState()
  const { role, loading, setLoading } = useSessionPermissions('ControlDevice', 'controldevices', 'model-list')
  const [loadingData, setLoadingData] = useState()
  const [showModal, setShowModal] = useState(false)
  const handleShowModal = () => setShowModal(state => !state)
  const defaultUserManagerPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))
  const [selectedFilterCategory, setSelectedFilterCategory] = useState(defaultUserManagerPreferences && defaultUserManagerPreferences.controldevicesCategoryFilter || null)
  const [selectedFilterType, setSelectedFilterType] = useState()
  const [selectedFilterZone, setSelectedFilterZone] = useState()
  const [selectedFilterName, setSelectedFilterName] = useState()
  const [showColumnsFilters, setShowColumnsFilters] = useState()
  const [selectedDevices, setSelectedDevices] = useState([])
  const [sortValue, setSortValue] = useState('updateDate')
  const [sortDirection, setSortDirection] = useState('desc')
  const [groupZones, setGroupZones] = useState([])
  const token = useSession()

  const columns = [
    ["name","Name"],
    ["deviceName","Device Name"],
    ["updateDate","Last update"],
    // ["lastAccess","Last access"],
    ["batteryLevel","Battery"],
    ["type","Type"],
    ["event","Event"],
    ["zoneId","Zone"],
    // ["ticketId","TicketId"],
    ["nfcMode","NFC Mode"],
    ["offlineCapable","Offline Capable"],
    // ["ticketId","PublicId"],
    ["hexId","HexId"],
    ["deviceId","DeviceId"],
  ]

  const isOrganizationView = event === 'all'

  const [groupByZone, setGroupByZone] = useState(getUserPreference({
    parentKey: 'controlDevices',
    key: 'groupByZone',
    defaultValue: true,
  }))

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'controlDevices',
      key: 'groupByZone',
      value: groupByZone,
    })
  }, [groupByZone])

  const [visibleColumns, setVisibleColumns] = useState(getUserPreference({
    parentKey: 'controlDevices',
    key: 'visibleColumns',
    defaultValue: [...columns],
  }))

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'controlDevices',
      key: 'visibleColumns',
      value: visibleColumns,
    })
  }, [visibleColumns])

  const getControlTickets = async () => {
    setEventTickets()
    setLoadingTickets(true)

    const res = await fetch(`${process.env.REACT_APP_API_URL}control-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        organization: getOrganization(),
        event: eventId || getEvent(),
        action: 'getControlTickets',
        zone: zoneId,
      }),
    })
    
    setLoadingTickets(false)
    try {
      const { tickets, errors: responseErrors } = await res.json()
      if (responseErrors) {
        responseErrors.forEach(error => console.log('Error', error))
      } else {
        const ticketsOptions = tickets && tickets.map(ticket => ({
          value: ticket.publicId,
          label: ticket.ticketId,
          _id: ticket._id,
          event: ticket.event || ticket.ticketType?.event, 
        }))
        setEventTickets(ticketsOptions)
      }
    }
    catch (error) {
      console.log('error', error)
    }
  }

  const fetchData = async (c) => {
    setEditedDevice()
    const category = c || selectedFilterCategory
    setLoadingData(true)

    const returnToDevice = device && device.deviceId ? device.deviceId : null

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}control-list`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          organization: getOrganization(),
          event: eventId || getEvent(),
          action: 'getDevices',
          events: getEvent() && [getEvent()] || menuEventsOptions.map(i => i.value) || [],
        }),
      })
      const { data, errors: responseErrors } = await res.json()
      setLoadingData(false)
      if (responseErrors) {
        responseErrors.forEach(error => console.log('Error', error))
        setData({
          listControlDevices: [],
          listControlDevicesCategories: [],
        })
      } else {
        if (category === 'without' && data.listControlDevices && data.listControlDevices.length > 0) {
          data.listControlDevices = data.listControlDevices.filter(i => !i.category || i.category === '' || i.category === null)
        } else if (category && category !== -1 && data.listControlDevices && data.listControlDevices.length > 0) {
          data.listControlDevices = data.listControlDevices.filter(i => i.category === category)
        }
        setData(data)
        const uniqueZones = Array.from(
          new Map(
            data.listControlDevices.map(i => [i.zoneName || NOT_ASSIGNED_ZONE, { zoneId: i.zoneId, zoneName: i.zoneName || NOT_ASSIGNED_ZONE, eventId: i.eventId }])
          ).values()
        ).sort((a, b) => {
          // NOTE: Always show NOT_ASSIGNED_ZONE at the end
          if (a.zoneName === NOT_ASSIGNED_ZONE) return 1;
          if (b.zoneName === NOT_ASSIGNED_ZONE) return -1;
          return a.zoneName?.localeCompare(b.zoneName || '');        
        });
        setGroupZones(groupByZone && uniqueZones || [{}])
      }

      if (returnToDevice) {
        setEditedDevice(returnToDevice)
        const domElement = document.getElementById(returnToDevice)

        if (domElement) {
          domElement.scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" })
          setTimeout(() => {
            setEditedDevice()
          }, 500);
          setTimeout(() => {
            setEditedDevice(returnToDevice)
          }, 1000);
          setTimeout(() => {
            setEditedDevice()
          }, 1500);
          setTimeout(() => {
            setEditedDevice(returnToDevice)
          }, 2000);
          setTimeout(() => {
            setEditedDevice()
          }, 2500);

          setTimeout(() => {
            setEditedDevice()
          }, 3000);
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const fetchDataZones = async () => {
    setLoadingZones(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}zones-event`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        organization: getOrganization(),
        eventId,
      }),
    })
    try {
      const { zones, errors: responseErrors } = await res.json()
      setLoadingZones(false)
      if (responseErrors) {
        responseErrors.forEach(error => console.log('Error', error))
      } else {
        const zonesOptions = zones && zones.map(zone => ({ value: zone._id, label: zone.title, eventId: zone.eventId }))
        setEventZones(zonesOptions)
        if (!eventId && getEvent() && menuEventsOptions.length) {
          setEventId(getEvent())
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    if (eventId) {
      fetchDataZones()
    }
  }, [eventId])

  useEffect(() => {
    if (zoneId) {
      getControlTickets()
    }
  }, [zoneId])
  

  const fetchCategoriesControlDevices = async () => {
    setLoadingCategories(true)
    try {
      const res = await fetch(`${process.env.REACT_APP_GRAPH_URL}`, {
        method: 'POST',
        headers: {
          Authorization: `${token}`,
          'AccessTicket-App': 'manager',
        },
        body: JSON.stringify({
          query: ListControlDevicesCategories,
          variables: {
            event: getEvent(true) || '',
          },
        }),
      })
      const { data, errors: responseErrors } = await res.json()
      setLoadingCategories(false)
      if (responseErrors) {
        responseErrors.forEach(error => console.log('Error', error))
      } else {
        const categories = data.listControlDevicesCategories
        setFilterCategories(categories)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    fetchData()
    fetchDataZones()
    getControlTickets()
    fetchCategoriesControlDevices()
  }, [])

  useEffect(() => {
    fetchData()
  }, [groupByZone])
  useEffect(() => {
    if (device && !selectedDevices.length) {
      setPublicId(device.ticketId ? device.ticketId : '')
      setEventId(device.eventId)
      setZoneId(device.zoneId)
      setNfcMode(device.nfcMode)
      setOfflineCapable(device.offlineCapable)
      setAllowToShowMetrics(device.allowToShowMetrics)
      setHideUserFunctions(device.hideUserFunctions)
      setIsAllowedToCancel(device.isAllowedToCancel)
      setRandomMode(device.randomMode)
      setType(device.type)
      sethexId(device.hexId)
      setName(device.name)
      setDeviceName(device.deviceName)
      setDefaultDeviceReaderSelector(device.defaultDeviceReaderSelector)
      setReaderModeOnly(device.readerModeOnly)
    } else {
      setPublicId(null)
      setEventId(null)
      setZoneId(null)
      setNfcMode(null)
      setOfflineCapable(true)
      setAllowToShowMetrics(false)
      setHideUserFunctions(false)
      setIsAllowedToCancel(false)
      setRandomMode(false)
      setType(null)
      sethexId(null)
      setName(null)
      setDeviceName(null)
      setDefaultDeviceReaderSelector()
      setReaderModeOnly()
    }
  }, [device])

  useEffect(() => {
    if (selectedFilterCategory) fetchData(selectedFilterCategory)
  }, [selectedFilterCategory])

  const handleDelete = async ({ deviceId }) => {
    if (window.confirm('Are you sure you want to remove the device: ' + deviceId)) {
      const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
        method: 'POST',
        headers: {
          Authorization: `${token}`,
          'AccessTicket-App': 'manager',
        },
        body: JSON.stringify({
          query: DeleteControlDevice,
          variables: {
            deviceId: deviceId,
          },
        }),
      })
      const { data, errors: responseErrors } = await res.json()
      if (responseErrors) {
        responseErrors.forEach(error => console.log('Error', error))
      } else {
        fetchData()
      }
    }
  }

  const handleKill = async ({ deviceId }) => {
    if (window.confirm('Are you sure you want to kill the app for device: ' + deviceId)) {
      const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
        method: 'POST',
        headers: {
          Authorization: `${token}`,
          'AccessTicket-App': 'manager',
        },
        body: JSON.stringify({
          query: KillControlDevice,
          variables: {
            deviceId: deviceId,
          },
        }),
      })
      const { errors: responseErrors } = await res.json()
      if (responseErrors) {
        responseErrors.forEach(error => console.log('Error', error))
      } else {
        fetchData()
      }
    }
  }

  const updateData = async (dataDevice, quickData) => {
    setLoadingUpdate(true)
    const variables = !dataDevice || !quickData ?
      {
        deviceId: device.deviceId,
        ticketId: publicId,
        ...(eventId) && { eventId: eventId },
        ...(zoneId) && { zoneId },
        nfcMode,
        offlineCapable,
        allowToShowMetrics,
        hideUserFunctions,
        isAllowedToCancel,
        randomMode,
        type,
        hexId,
        category,
        name,
        deviceName,
        defaultDeviceReaderSelector,
        readerModeOnly,
      } :
      {
        deviceId: dataDevice.deviceId,
        ticketId: dataDevice.publicId,
        ...(dataDevice.eventId) && { eventId: dataDevice.eventId },
        ...(dataDevice.zoneId) && { zoneId: dataDevice.zoneId },
        ...(quickData.type) ? { type: quickData.type } : { type: dataDevice.type },
        ...(typeof quickData.nfcMode === 'boolean') ? { nfcMode: quickData.nfcMode } : { nfcMode: dataDevice.nfcMode },
        ...(typeof quickData.offlineCapable === 'boolean') ? { offlineCapable: quickData.offlineCapable } : { offlineCapable: dataDevice.offlineCapable },
        // NOTE: No idea what this does, just repeating the same patterns
        ...(typeof quickData.allowToShowMetrics === 'boolean') ? { allowToShowMetrics: quickData.allowToShowMetrics } : { allowToShowMetrics: dataDevice.allowToShowMetrics },
        ...(typeof quickData.hideUserFunctions === 'boolean') ? { hideUserFunctions: quickData.hideUserFunctions } : { hideUserFunctions: dataDevice.hideUserFunctions },
        ...(typeof quickData.isAllowedToCancel === 'boolean') ? { isAllowedToCancel: quickData.isAllowedToCancel } : { isAllowedToCancel: dataDevice.isAllowedToCancel },
        ...(typeof quickData.randomMode === 'boolean') ? { randomMode: quickData.randomMode } : { randomMode: dataDevice.randomMode },
        name: dataDevice.name,
        deviceName: dataDevice.deviceName,
        hexId: dataDevice.hexId,
        category: dataDevice.category,
        defaultDeviceReaderSelector: dataDevice.defaultDeviceReaderSelector,
        readerModeOnly: dataDevice.readerModeOnly
      }

    const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        query: UpdateControlDeviceStatus,
        variables: variables,
      }),
    })
    const { errors: responseErrors } = await res.json()
    setLoadingUpdate(false)
    if (responseErrors) {
      responseErrors.forEach(error => console.log('Error', error))
    } else {
      fetchData()
      setDevice(null)
      setShowActions()
    }
  }

  const singlePropUpdate = async (quickData) => {
    const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        query: UpdateControlDeviceStatus,
        variables: quickData,
      }),
    })
    const { errors: responseErrors } = await res.json()
    setLoadingUpdate(false)
    if (responseErrors) {
      responseErrors.forEach(error => console.log('Error', error))
    } else {
      fetchData()
      setDevice(null)
      setShowActions()
    }
  }

  const updateMultipleData = async () => {
    const variables = {
      ...(publicId) && { ticketId: publicId },
      ...(type) && { type },
      ...(hexId) && { hexId },
      ...(category) && { category },
      zoneId,
      eventId,
      defaultDeviceReaderSelector,
      readerModeOnly: readerModeOnly ?? false,
    }

    // for async, for each device, update the data
    for await (const device of selectedDevices) {
      const deviceVariables = {
        deviceId: device,
        ticketId: data.listControlDevices.find(d => d.deviceId === device).ticketId,
        zoneId: data.listControlDevices.find(d => d.deviceId === device).zoneId,
        eventId: data.listControlDevices.find(d => d.deviceId === device).eventId,
        defaultDeviceReaderSelector: data.listControlDevices.find(d => d.deviceId === device).defaultDeviceReaderSelector,
        readerModeOnly: data.listControlDevices.find(d => d.deviceId === device).readerModeOnly,
        ...variables,
      }
      const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
        method: 'POST',
        headers: {
          Authorization: `${token}`,
          'AccessTicket-App': 'manager',
        },
        body: JSON.stringify({
          query: UpdateControlDeviceStatus,
          variables: deviceVariables,
        }),
      })
      const { errors: responseErrors } = await res.json()
      setLoadingUpdate(false)
      if (responseErrors) {
        responseErrors.forEach(error => console.log('Error', error))
      }
    }
    fetchData()
    setDevice(null)
    setShowActions()
  }

  const refreshTicketTypes = async ({ deviceId }) => {
    const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        query: RefreshControlDeviceTicketTypes,
        variables: {
          deviceId: deviceId,
        },
      }),
    })
    const { data, errors: responseErrors } = await res.json()
    if (responseErrors) {
      responseErrors.forEach(error => console.log('Error', error))
    } else {
      alert('Refreshed')
    }
  }

  const reboot = async ({ deviceId }) => {
    const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        query: RebootControlDevice,
        variables: {
          deviceId: deviceId,
        },
      }),
    })
    const { errors: responseErrors } = await res.json()
    if (responseErrors) {
      responseErrors.forEach(error => console.log('Error', error))
    } else {
      fetchData()
      setDevice(null)
      setShowActions()
    }
  }

  const status = ({ batteryLevel, updateDate, type }) => {
    if (batteryLevel && batteryLevel < 30) {
      return `${type}-error`
    } else if (batteryLevel && batteryLevel < 90) {
      return `${type}-warning`
    } else if (dayjs().diff(dayjs.unix(updateDate / 1000), 'minute') > 10) {
      return `${type}-gray`
    }
    return `${type}-success`
  }

  useEffect(() => {
    let userManagerPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))
    if (!userManagerPreferences) {
      userManagerPreferences = {}
    }

    userManagerPreferences.controldevicesCategoryFilter = selectedFilterCategory

    Cookies.set('userManagerPreferences', JSON.stringify(userManagerPreferences), { expires: 365 })
  }, [selectedFilterCategory])

  const applyDeviceFilters = (i, group) => {
    let showItem = true
    if (showItem && selectedFilterType && selectedFilterType !== -1) {
      if (i.type !== selectedFilterType) showItem = false
    }
    if (showItem && selectedFilterZone) {
      if (selectedFilterZone === -1) {
        if (i.zoneId) showItem = false
      } else if (i.zoneId?.toString() !== selectedFilterZone.toString()) {
        showItem = false
      }
    }
    if (showItem && selectedFilterName && selectedFilterName !== -1) {
      if (!i.name?.toLowerCase().includes(selectedFilterName.toLowerCase())) showItem = false
    }

    if (group && group.zoneId?.toString() !== i.zoneId?.toString()) {
      showItem = false
    }
    return showItem
  }

  const sortDevices = (a,b) => {
    let valueA = a[sortValue]
    let valueB = b[sortValue]
    if (sortValue === 'offlineCapable') {
      valueA = valueA === null ? true : valueA
      valueB = valueB === null ? true : valueB
    }

    if (sortValue === 'type') {
      valueA = valueA === null ? 'entry' : valueA
      valueB = valueB === null ? 'entry' : valueB
    }

    if (sortValue === 'lastAccess') {
      valueA = valueA ? dayjs(valueA).unix() : 0
      valueB = valueB ? dayjs(valueB).unix() : 0
    }

    if (valueA < valueB) {
      return sortDirection === 'asc' ? -1 : 1
    }
    if (valueA > valueB) {
      return sortDirection === 'asc' ? 1 : -1
    }
    return 0
  }
  
  const updateSort = (value) => {
    if (sortValue === value) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortDirection('asc')
      setSortValue(value)
    }
  }

  const SortIndicator = ({ value }) => {
    if (sortValue === value) {
      return <span className={`ml-1`}>{sortDirection === 'asc' ? "↑" : "↓"}</span>
    }
    return <></>
  }

  const HeaderCell = ({value = '', label = ''}) => {
    return <th className="px-4 py-2 border-b-2 cursor-pointer select-none border-grayLight" onClick={() => updateSort(value)}>{label} <SortIndicator value={value} /></th>
  }

  const BodyCell = ({ column = '', className, children, onClick}) => {
    if (!visibleColumns?.find(i => i[1] === column)) return <></>
    if (!children || !Object.keys(children).length) return <td className={`px-1 py-1 ${className}`} />
    return <td className={`px-1 py-1 ${className}`} onClick={onClick} >{children}</td>
  }

  return (
    <div>
      <Helmet>
        <title>Control Devices</title>
      </Helmet>
      <div>

        {showActions && (!device && !selectedDevices.length) && <>
          <div className="fixed inset-0 z-50 flex items-center justify-center p-4" style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
            <div className='absolute inset-0 flex items-center justify-center'>
              <div className='absolute inset-0 cursor-pointer ' onClick={() => setShowActions()}></div>
              <div className="relative z-50 flex flex-col justify-center w-full p-4 m-2 bg-white border rounded md:px-12 md:w-auto border-gray">
                <h3 className={`wght-bold text-lg`}>{data?.listControlDevices?.find(d => d.deviceId === showActions)?.name}</h3>
                <p className={`text-xs`}>{data?.listControlDevices?.find(d => d.deviceId === showActions)?.deviceId}</p>
                <span className='flex flex-col my-2 md:w-80'>
                  {
                    role !== 'read' && <>
                      <span
                        onClick={() => reboot({ deviceId: showActions })}
                        style={{ margin: 'auto', marginTop: '5px' }}
                        className="w-full max-w-xs px-4 py-2 mt-2 text-center text-white border rounded cursor-pointer hover:bg-white hover:text-primary hover:border-primary bg-primary"
                      >Reboot</span>
                      <span
                        onClick={() => refreshTicketTypes({ deviceId: showActions })}
                        style={{ margin: 'auto', marginTop: '5px' }}
                        className="w-full max-w-xs px-4 py-2 mt-2 text-center text-white border rounded cursor-pointer hover:bg-white hover:text-primary hover:border-primary bg-primary"
                      >Refresh</span>
                      <span
                        onClick={() => handleKill({ deviceId: showActions })}
                        style={{ margin: 'auto', marginTop: '5px' }}
                        className="w-full max-w-xs px-4 py-2 mt-2 text-center text-white border rounded cursor-pointer hover:bg-white hover:text-primary hover:border-primary bg-primary"
                      >Kill</span>
                      <span
                        onClick={() => handleDelete({ deviceId: showActions })}
                        style={{ margin: 'auto', marginTop: '5px' }}
                        className="w-full max-w-xs px-4 py-2 mt-2 text-center text-white border rounded cursor-pointer hover:border-error hover:bg-white hover:text-error bg-error"
                      >Delete</span>
                    </>
                  }
                </span>

              </div>
            </div>
          </div>
        </>
        }

        {role !== 'read' && device && !selectedDevices.length && <>
          <div className="fixed inset-0 z-40 flex items-center justify-center p-4 m-2" style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
            <div className='absolute inset-0 flex items-center justify-center'>
              <div className='absolute inset-0 ' onClick={() => { setDevice(null); setShowActions() }}></div>
              <div className="relative z-50 flex flex-col justify-center p-4 bg-white border rounded md:w-1/2 border-gray">
                {<h3 className="text-lg wght-bold">{device.name || '[No device name]'}</h3>}
                <p className="text-xs">{device.deviceId}</p>
                <div className="mt-2 md:mt-4">
                  <div className="mb-2 md:mb-4">
                    <span>
                      <span>Event</span>
                      <Select
                        value={menuEventsOptions && menuEventsOptions.find(t => t.value === eventId) || null}
                        onChange={(selected) => {
                          setEventId(selected?.value || null)
                          setZoneId()
                          setPublicId()
                        }}
                        options={menuEventsOptions}
                        className="text-sm cursor-pointer"
                        isClearable={true}
                        theme={selectTheme}
                        styles={selectStyles}
                      />
                    </span>
                  </div>
                  <div className="mb-2 md:mb-4">
                    <span>
                      <span>Zone</span>
                      <Select
                        isDisabled={loadingZones || (!zoneId && !eventId)}
                        isLoading={loadingZones}
                        value={eventZones && eventZones.find(t => t.value === zoneId) || null}
                        onChange={(selected) => {
                          setZoneId(selected?.value || null)
                        }}
                        options={!eventId ? eventZones : eventZones?.filter(z => z.eventId?.toString() === eventId?.toString())}
                        className="text-sm cursor-pointer"
                        placeholder={'Select Zone'}
                        isClearable={true}
                        theme={selectTheme}
                        styles={selectStyles}
                      />
                    </span>
                  </div>
                  <div className="mb-2 md:mb-4">
                    <span>
                      <span>Name</span>
                      <input
                        className="w-full px-1 py-1 text-xs border rounded"
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={e => setName(e.target.value)}
                      />
                    </span>
                  </div>
                  <div className="mb-2 md:mb-4">
                    <span>
                      <span>Device Name</span>
                      <input
                        className="w-full px-1 py-1 text-xs border rounded"
                        type="text"
                        name="deviceName"
                        id="deviceName"
                        value={deviceName}
                        onChange={e => setDeviceName(e.target.value)}
                      />
                    </span>
                  </div>
                  <div className='flex'>
                    <div className="w-full mb-2 mr-2 md:mb-4">
                      <label className="" htmlFor="defaultDeviceReaderSelector">Device Reader Mode</label>
                      <select value={defaultDeviceReaderSelector} name="defaultDeviceReaderSelector" id="defaultDeviceReaderSelector"
                        onChange={(e) => {
                          setDefaultDeviceReaderSelector(e.target.value)
                        }}
                        className="block w-full px-1 py-1 text-xs border rounded"
                      >
                        <option selected={defaultDeviceReaderSelector === 'camera'} value="camera">Camera</option>
                        <option selected={defaultDeviceReaderSelector === 'scanner'} value="scanner">Scanner</option>
                        <option selected={defaultDeviceReaderSelector === 'nfc'} value="nfc">NFC</option>
                      </select>
                    </div>
                  </div>
                  <div className='flex'>
                    <div className="w-1/3 mb-2 mr-2 md:mb-4">
                      <label className="" htmlFor="type">Type</label>
                      <select value={type} name="type" id="type"
                        onChange={(e) => {
                          setType(e.target.value)
                        }}
                        className="block w-full px-1 py-1 text-xs border rounded"
                      >
                        <option selected={type === 'entry'} value="entry">Entry</option>
                        <option selected={type === 'exit'} value="exit">Exit</option>
                      </select>
                    </div>
                    <div className="w-full mb-2 md:mb-4">
                      <label className="" htmlFor="zoneId">HexId</label>
                      <input
                        className="w-full px-1 py-1 text-xs border rounded"
                        type="text"
                        name="hexId"
                        id="hexId"
                        value={hexId}
                        onChange={e => sethexId(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-2 md:mb-4">
                    <label className="" htmlFor="type">Category</label>
                    <select value={category} name="category" id="category"
                      onChange={(e) => {
                        setCategory(e.target.value)
                      }}
                      className="block w-full px-1 py-1 text-xs border rounded"
                    >
                      {loading ?
                        <option value="-1">Loading...</option>
                        :
                        <option value="-1">Select a category</option>
                      }
                      {filterCategories && filterCategories.map(category => {
                        return (<option key={category._id} value={category._id}>{category.title}</option>)
                      })}
                    </select>
                  </div>
                  <div className='flex'>
                    <div className="w-1/2 mb-2 md:mb-4">
                      <label className="cursor-pointer select-none" htmlFor="offlineCapable">Offline capable</label>
                      <input
                        className="px-1 py-1 ml-2 text-xs border rounded"
                        type="checkbox"
                        name="offlineCapable"
                        id="offlineCapable"
                        checked={offlineCapable}
                        value={offlineCapable}
                        onChange={e => setOfflineCapable(!offlineCapable)}
                      />
                    </div>
                    <div className="w-1/2 mb-2 md:mb-4">
                      <label className="cursor-pointer select-none" htmlFor="offlineCapable">Only Reader</label>
                      <input
                        className="px-1 py-1 ml-2 text-xs border rounded"
                        type="checkbox"
                        name="readerModeOnly"
                        id="readerModeOnly"
                        checked={readerModeOnly}
                        value={readerModeOnly}
                        onChange={e => setReaderModeOnly(!readerModeOnly)}
                      />
                    </div>
                  </div>
                  <div className='flex'>
                    <div className="w-1/2 mb-2 md:mb-4">
                      <label className="cursor-pointer select-none" htmlFor="allowToShowMetrics">Show Metrics</label>
                      <input
                        className="px-1 py-1 ml-2 text-xs border rounded"
                        type="checkbox"
                        name="allowToShowMetrics"
                        id="allowToShowMetrics"
                        checked={allowToShowMetrics}
                        value={allowToShowMetrics}
                        onChange={e => setAllowToShowMetrics(!allowToShowMetrics)}
                      />
                    </div>
                    <div className="w-1/2 mb-2 md:mb-4">
                      <label className="cursor-pointer select-none" htmlFor="hideUserFunctions">Hide Functions</label>
                      <input
                        className="px-1 py-1 ml-2 text-xs border rounded"
                        type="checkbox"
                        name="hideUserFunctions"
                        id="hideUserFunctions"
                        checked={hideUserFunctions}
                        value={hideUserFunctions}
                        onChange={e => setHideUserFunctions(!hideUserFunctions)}
                      />
                    </div>
                  </div>
                  <div className='flex'>
                    <div className="w-1/2 mb-2 md:mb-4">
                      <label className="cursor-pointer select-none" htmlFor="isAllowedToCancel">Can reset entry</label>
                      <input
                        className="px-1 py-1 ml-2 text-xs border rounded"
                        type="checkbox"
                        name="isAllowedToCancel"
                        id="isAllowedToCancel"
                        checked={isAllowedToCancel}
                        value={isAllowedToCancel}
                        onChange={e => setIsAllowedToCancel(!isAllowedToCancel)}
                      />
                    </div>
                    <div className="w-1/2 mb-2 md:mb-4">
                      <label className="cursor-pointer select-none" htmlFor="randomMode">Random Mode (dev)</label>
                      <input
                        className="px-1 py-1 ml-2 text-xs border rounded"
                        type="checkbox"
                        name="randomMode"
                        id="randomMode"
                        checked={randomMode}
                        value={randomMode}
                        onChange={e => setRandomMode(!randomMode)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <Button className='mr-2' onClick={() => { setDevice(null); setShowActions() }} color="cancel">Cancel</Button>
                  <Button onClick={updateData}>
                    {loadingUpdate ? 'Updating...' : 'Save'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>}

        {role !== 'read' && device && !!selectedDevices.length && <>
          <div className="fixed inset-0 z-40 flex items-center justify-center p-4 m-2" style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
            <div className='absolute inset-0 flex items-center justify-center'>
              <div className='absolute inset-0 ' onClick={() => { setDevice(null); setShowActions() }}></div>
              <div className="relative z-50 flex flex-col justify-center p-4 bg-white border rounded md:w-1/2 border-gray">
                <h3 className="text-lg wght-bold">Multiple edit</h3>
                <p className="text-xs">{selectedDevices.length} devices</p>
                <div className="mt-2 md:mt-4">
                  <div className="mb-2 md:mb-4">
                    <span>
                      <span>Filter zones by event</span>
                      <Select
                        value={menuEventsOptions && menuEventsOptions.find(t => t.value === eventId) || null}
                        onChange={(selected) => {
                          setEventId(selected?.value || null)
                          setZoneId(null)
                          setPublicId(null)
                        }}
                        options={menuEventsOptions}
                        className={`text-sm cursor-pointer`}
                        isClearable={true}
                        theme={selectTheme}
                        styles={{
                          control: (styles) => ({...styles, borderColor: "#eee", minHeight: '20px', backgroundColor: eventId ? '#FFC4AD' : '#eee' }),
                          valueContainer: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0, backgroundColor: eventId ? '#FFC4AD' : '#eee' }),
                          input: (styles) => ({ ...styles, marginTop: 2, marginBottom: 1 }),
                          dropdownIndicator: (styles) => ({ ...styles, padding: 2, backgroundColor: eventId ? '#FFC4AD' : '#eee' }),
                          clearIndicator: (styles) => ({ ...styles, padding: 2, backgroundColor: eventId ? '#FFC4AD' : '#eee' }),
                          menuList: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0, backgroundColor: eventId ? '#FFC4AD' : '#eee'}),
                        }}
                      />
                    </span>
                  </div>
                  <div className="mb-2 md:mb-4">
                    <span>
                      <span>Zone</span>
                      <Select
                        isDisabled={loadingZones || (!zoneId && !eventId)}
                        isLoading={loadingZones}
                        value={eventZones && eventZones.find(t => t.value === zoneId) || null}
                        onChange={(selected) => {
                          setZoneId(selected?.value || null)
                          setPublicId(null)
                        }}
                        options={!eventId ? eventZones : eventZones?.filter(z => z.eventId?.toString() === eventId?.toString())}
                        className="text-sm cursor-pointer"
                        placeholder={'Select Zone'}
                        isClearable={true}
                        theme={selectTheme}
                        styles={{
                          control: (styles) => ({...styles, borderColor: "#eee", minHeight: '20px', backgroundColor: zoneId ? '#FFC4AD' : '#eee' }),
                          valueContainer: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0, backgroundColor: zoneId ? '#FFC4AD' : '#eee' }),
                          input: (styles) => ({ ...styles, marginTop: 2, marginBottom: 1 }),
                          dropdownIndicator: (styles) => ({ ...styles, padding: 2, backgroundColor: zoneId ? '#FFC4AD' : '#eee' }),
                          clearIndicator: (styles) => ({ ...styles, padding: 2, backgroundColor: zoneId ? '#FFC4AD' : '#eee' }),
                          menuList: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0, backgroundColor: zoneId ? '#FFC4AD' : '#eee'}),
                        }}
                      />
                    </span>
                  </div>
                  <div className='flex'>
                    <div className="w-1/3 mb-2 mr-2 md:mb-4">
                      <label className="" htmlFor="type">Type</label>
                      <select value={type} name="type" id="type"
                        onChange={(e) => {
                          setType(e.target.value)
                        }}
                        className={`block w-full px-1 py-1 text-xs border rounded ${type ? 'bg-orange' : ''}`}
                      >
                        <option value="">Select</option>
                        <option selected={type === 'entry'} value="entry">Entry</option>
                        <option selected={type === 'exit'} value="exit">Exit</option>
                      </select>
                    </div>
                    <div className="w-full mb-2 md:mb-4">
                      <label className="" htmlFor="zoneId">HexId</label>
                      <input
                        className={`w-full px-1 py-1 text-xs border rounded ${hexId ? 'bg-orange' : ''}`}
                        type="text"
                        name="hexId"
                        id="hexId"
                        value={hexId}
                        onChange={e => sethexId(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <div className="w-2/3 mb-2 mr-2 md:mb-4">
                      <label className="" htmlFor="defaultDeviceReaderSelector">Device Reader Mode</label>
                      <select value={defaultDeviceReaderSelector} name="defaultDeviceReaderSelector" id="defaultDeviceReaderSelector"
                        onChange={(e) => {
                          setDefaultDeviceReaderSelector(e.target.value)
                        }}
                        className="block w-full px-1 py-1 text-xs border rounded"
                      >
                        <option selected={defaultDeviceReaderSelector === 'camera'} value="camera">Camera</option>
                        <option selected={defaultDeviceReaderSelector === 'scanner'} value="scanner">Scanner</option>
                        <option selected={defaultDeviceReaderSelector === 'nfc'} value="nfc">NFC</option>
                      </select>
                    </div>
                    <div className="w-1/3">
                      <label className="cursor-pointer select-none" htmlFor="offlineCapable">Only Reader</label>
                      <input
                        className="px-1 py-1 ml-2 text-xs border rounded"
                        type="checkbox"
                        name="readerModeOnly"
                        id="readerModeOnly"
                        checked={readerModeOnly}
                        value={readerModeOnly}
                        onChange={e => setReaderModeOnly(!readerModeOnly)}
                      />
                    </div>
                  </div>
                  <div className="mb-2 md:mb-4">
                    <label className="" htmlFor="type">Category</label>
                    <select value={category} name="category" id="category"
                      onChange={(e) => {
                        setCategory(e.target.value)
                      }}
                      className={`block w-full px-1 py-1 text-xs border rounded ${category ? 'bg-orange' : ''}`}
                    >
                      {loading ?
                        <option value="-1">Loading...</option>
                        :
                        <option value="-1">Select a category</option>
                      }
                      {filterCategories && filterCategories.map(category => {
                        return (<option key={category._id} value={category._id}>{category.title}</option>)
                      })}
                    </select>
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <Button className='mr-2' onClick={() => { setDevice(null); setShowActions() }} color="cancel">Cancel</Button>
                  <Button onClick={updateMultipleData}>
                    {loadingUpdate ? 'Updating...' : 'Save'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>}

        {<>
          <div className='flex justify-between'>
            <div>
              <h2 className="text-xl wght-bold">Control Devices</h2>
              {showModal &&
                <InvitationModal token={token} handleShowModal={handleShowModal} modelName={modelName} event={event} role={role}/>
              }
              <div className='flex justify-between mb-4'>
                <div className="relative flex text-xs text-gray wght-light">
                  <div>
                    <span className='wght-semibold'>{role && role.toUpperCase()}</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button visibility={role === 'admin'} size="small" onClick={handleShowModal} color='success'>Share</Button>
            </div>
          </div>

          <div className='flex flex-wrap px-4 py-2 mb-4 bg-white items-between'>
            {filterCategories && <div className="flex items-center my-1 mr-2">
              <h3 className='mr-2 text-sm'>Filter by category: </h3>
              <select
                name="filterCategories"
                type="text"
                value={selectedFilterCategory || false}
                onChange={(event) => {
                  setSelectedFilterCategory(event.target.value != -1 ? event.target.value : -1)
                }}
                className="py-1 text-xs rounded outline-none bg-grayLight placeholder-gray"
              >
                {loadingCategories && <option value="-1">Loading...</option>}
                {!loadingCategories && <option value="-1">All categories</option>}
                {!loadingCategories && <option value="without">Without category</option>}
                {filterCategories && filterCategories.map(category => {
                  return (<option key={category._id} value={category._id}>{category.title}</option>)
                })}
              </select>
            </div>
            }
            <div className="flex items-center my-1 mr-2">
              <h3 className='mr-2 text-sm'>Filter by type: </h3>
              <select
                name="filterTypes"
                type="text"
                value={selectedFilterType || false}
                onChange={(event) => {
                  setSelectedFilterType(event.target.value != -1 ? event.target.value : -1)
                }}
                className="py-1 text-xs rounded outline-none bg-grayLight placeholder-gray"
              >
                <option value="">All</option>
                <option value="entry">Entry</option>
                <option value="exit">Exit</option>
              </select>
            </div>
            <div className="flex items-center my-1 mr-2">
              <h3 className='mr-2 text-sm'>Filter by name: </h3>
              <input
                name="filterName"
                type="text"
                placeholder='Name'
                value={selectedFilterName || ''}
                onChange={(event) => {
                  setSelectedFilterName(event.target.value)
                }}
                className="p-1 text-xs rounded outline-none bg-grayLight placeholder-gray"
              />
            </div>
            <div className="flex items-center my-1 mr-2">
              <h3 className='mr-2 text-sm'>Filter by zone: </h3>
              <select
                name="filterZones"
                type="text"
                value={selectedFilterZone || false}
                onChange={(event) => {
                  setTimeout(() => {
                    setGroupByZone(event.target?.value === -1)
                  }, 10);
                  setSelectedFilterZone(event.target.value != -1 ? event.target.value : -1)
                }}
                className="py-1 text-xs rounded outline-none bg-grayLight placeholder-gray"
              >
                <option value="">All zones</option>
                <option value="-1">Without zone</option>
                <option value="" disabled >----</option>
                { eventZones && eventZones.map(zone => {
                  return (<option key={zone.value} value={zone.value}>{zone.label}</option>)
                })}
              </select>
            </div>
            <div className='flex items-center justify-end w-auto'>
              <Button
                size="small"
                disable={!selectedFilterCategory && !selectedFilterType && !selectedFilterName && !selectedFilterZone}
                onClick={() => {
                  setSelectedFilterCategory(false)
                  setSelectedFilterType(false)
                  setSelectedFilterName('')
                  setSelectedFilterZone(false)
                }}
                color='cancel'
              >
                Clear filters
              </Button>
            </div>
          </div>

          <div className='flex justify-between'>
            <div className='flex ml-1'>
              <div className='flex items-center mr-4'>
                <div className='w-2 h-2 mr-1 bg-success'></div>
                <span className='text-xs'>Full battery</span>
              </div>
              <div className='flex items-center mr-4'>
                <div className='w-2 h-2 mr-1 bg-warning'></div>
                <span className='text-xs'>{`Battery < 90%`}</span>
              </div>
              <div className='flex items-center mr-4'>
                <div className='w-2 h-2 mr-1 bg-error'></div>
                <span className='text-xs'>{`Battery < 30%`}</span>
              </div>
              <div className='flex items-center mr-4'>
                <div className='w-2 h-2 mr-1 bg-gray'></div>
                <span className='text-xs'>Inactive last 10 minutes</span>
              </div>
            </div>

            <div className='flex items-center mb-1'>
              <Button
                size="small"
                onClick={() => {
                  setShowActions(true)
                  setDevice(true)
                }}
                color='primary'
                visibility={role === 'admin' && selectedDevices.length > 0}
              >
                <span className='text-xs'>Update selection</span>
              </Button>

              <Button
                size="small"
                onClick={() => { 
                  setGroupByZone(!groupByZone)
                  setSelectedFilterZone(false)
                }}
                color={`${groupByZone ? 'success' : 'white'}`}
              >Group by Zone</Button>

              <div onClick={() => { setShowColumnsFilters(!showColumnsFilters) }}>
                <div className={`px-2 py-1 text-xs bg-white border rounded cursor-pointer hover:wght-semibold ${columns.length > visibleColumns.length ? 'bg-primary text-white' : 'text-primary border-primary'}`}>
                  <span className="px-2">Columns</span>
                  <span className={`pr-2 pt-1 ${showColumnsFilters ? '' : 'hidden'}`}>↑</span>
                  <span className={`pr-2 pt-1 ${showColumnsFilters ? 'hidden' : ''}`}>↓</span>
                </div>

                <div className="relative">
                  <div className={`absolute z-10 border top-2 right-0 text-xs rounded cursor-pointer text-primary ${showColumnsFilters ? '' : 'hidden'}`}>
                    <span className="flex flex-col flex-wrap p-2 bg-white rounded whitespace-nowrap">
                        <label
                          className="w-full mr-4 cursor-pointer select-none md:mr-2 bg-red "
                          htmlFor={`column-all`}
                        >
                          <input
                            className="mr-1 cursor-pointer"
                            type="checkbox"
                            id={`column-all`}
                            name={`column-all`}
                            value=""
                            onChange={() => { setVisibleColumns(columns)}}
                            checked={visibleColumns.length === columns.length}
                          />
                          All
                        </label>
                        <span>----</span>
                        {columns.map(([key, value]) => {
                          return (
                            <label
                              key={value}
                              className="mr-4 cursor-pointer select-none md:mr-2"
                              htmlFor={`column-${value}`}
                            >
                            <input
                              disabled={loading}
                              className="mr-1 cursor-pointer"
                              type="checkbox"
                              id={`column-${value}`}
                              name={`column-${value}`}
                              value=""
                              onChange={() => {
                                if (visibleColumns?.find(i => i[1] === value)) {
                                  setVisibleColumns(visibleColumns?.filter(i => i[1] !== value))
                                } else {
                                  let rawColumns = [...visibleColumns, [key, value]]
                                  rawColumns.sort((a, b) => {
                                    return columns.findIndex(i => i[1] === a[1]) - columns.findIndex(i => i[1] === b[1])
                                  })
                                  setVisibleColumns(rawColumns)
                                }
                              }}
                              checked={visibleColumns?.find(i => i[1] === value) || false}
                            />
                              {typeof value === 'string' ? value : value.label}
                            </label>
                          )
                        })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {loadingData && <p className='mt-8 ml-1'>Loading...</p>}

          {data?.listControlDevices?.length === 0 ? <p className='my-4'>No devices found</p> :
            <div className="flex flex-col">
              {!loadingData && <>
                {
                  true && groupZones.map(group => {
                    if (data?.listControlDevices.filter(i => applyDeviceFilters(i, groupZones.length > 1 && group)).sort((a,b) => sortDevices(a,b)).length === 0) {
                      return <></>
                    }
                    const groupName = menuEventsOptions.find(event => event.value === group.eventId)?.label
                    return <div className=''>
                    { group && group.zoneName && 
                      <h3 className='mb-2 text-lg wght-semibold '>{ (isOrganizationView && groupName) ? groupName + ': ': ''}{group.zoneName}</h3>
                    }
                    <div className='flex mb-8'>
                      <div className="flex-auto overflow-x-scroll">
                        <table className="w-full border-separate whitespace-nowrap">
                          <thead className="text-xs text-left bg-white border-b border-grayLight wght-semibold whitespace-nowrap">
                            <tr className="border-b border-separate border-grayLight ">
                              <th className="w-4 border-b-2 border-grayLight"></th>
                              <th className="w-4 border-b-2 border-grayLight">
                                <label htmlFor={`selectedDevices-all`} className='flex items-center h-full px-1 py-1 cursor-pointer' >
                                  <input
                                    disabled={role === 'read'}
                                    name="selectedDevices"
                                    id={`selectedDevices-all`}
                                    type="checkbox"
                                    className="w-4 h-4 ml-1 cursor-pointer"
                                    checked={selectedDevices?.length && selectedDevices?.length === data?.listControlDevices.filter(i => applyDeviceFilters(i, groupZones.length > 1 && group)).length}
                                    onChange={() => {
                                      const numberOfFilteredDevices = data?.listControlDevices.filter(i => applyDeviceFilters(i, groupZones.length > 1 && group)).length
                                      if (numberOfFilteredDevices === selectedDevices.length) {
                                        setShowActions(null)
                                        setSelectedDevices([])
                                      } else {
                                        setSelectedDevices(data.listControlDevices.filter(i => applyDeviceFilters(i, groupZones.length > 1 && group)).map(i => i.deviceId))
                                      }
                                    }}
                                  />
                                </label>
                              </th>
                              {visibleColumns.map(([key, value]) => {
                                return (
                                  <HeaderCell value={key} label={value} key={value} />
                                )
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {data?.listControlDevices.filter(i => applyDeviceFilters(i, groupZones.length > 1 && group)).sort((a,b) => sortDevices(a,b)).map(({ name, deviceName, deviceId, batteryLevel, batteryState, ticketId, zoneId, zoneName, updateDate, nfcMode, offlineCapable, type: deviceType, freezed, hexId, lastAccess, eventId }, index) => (
                              <tr id={`${deviceId}-${name}`} key={`${deviceId}-${name}`} className={`h-10 text-xs relative border-b border-separate border-grayLight
                                ${index % 2 === 0 && 'bg-grayLighter'  || freezed && 'bg-yellow' || 'bg-white'}
                              `} >
                                <td className={`w-4 ${status({ updateDate, batteryLevel, batteryState, type: 'bg' })}`} dangerouslySetInnerHTML={{ __html: '&nbsp;' }}></td>
                                
                                <td>
                                  <label htmlFor={`selectedDevices-${deviceId}-${name}`} className='flex items-center justify-center h-full px-2 py-2 cursor-pointer ' >
                                    <input
                                      disabled={role === 'read' || !deviceId}
                                      name="selectedDevices"
                                      id={`selectedDevices-${deviceId}-${name}`}
                                      type="checkbox"
                                      className="w-4 h-4 cursor-pointer "
                                      checked={selectedDevices?.includes(deviceId)}
                                      onChange={() => {
                                        if (selectedDevices?.includes(deviceId)) {
                                          if (selectedDevices.length === 1) {
                                            setShowActions(null)
                                            setSelectedDevices([])
                                          } else {
                                            setSelectedDevices(selectedDevices.filter(i => i !== deviceId))
                                          }
                                        } else {
                                          setSelectedDevices([...selectedDevices, deviceId])
                                        }
                                      }}
                                    />
                                  </label>
                                </td>

                                <BodyCell column="Name" className={`${editedDevice === deviceId ? 'bg-purple text-white' : ''}`} >
                                  {name}
                                </BodyCell>
                                <BodyCell column="Device Name" className={`${editedDevice === deviceId ? 'bg-purple text-white' : ''}`} >
                                  {deviceName}
                                </BodyCell>

                                <BodyCell column="Last update">
                                  {dayjs(updateDate).format('DD/MM/YY H:m:s')}
                                </BodyCell>

                                {/* <BodyCell column="Last access">
                                  {lastAccess && dayjs(lastAccess).format('DD/MM/YY H:m:s')}
                                </BodyCell> */}

                                <BodyCell column="Battery" className={`${status({ updateDate, batteryLevel, batteryState, type: 'text' })}`}>
                                  {batteryLevel && <span>{batteryLevel}%</span>}
                                  {batteryState && <span>({batteryState})</span>}
                                  {!batteryLevel && !batteryState && <span>No data</span>}
                                </BodyCell>

                                <BodyCell
                                  column="Type"
                                  onClick={() => {
                                    if (role === 'read') return
                                    const quickData = { type: deviceType === 'exit' ? 'entry' : 'exit', ticketId, deviceId, zoneId }
                                    singlePropUpdate(quickData)
                                  }}
                                  className={`${ticketId && deviceId && zoneId ? 'cursor-pointer hover:wght-semibold hover:text-primary ' : ''}`}
                                >
                                  {deviceType === 'exit' ? 'exit' : 'entry'}
                                </BodyCell>

                                <BodyCell column="Event">
                                  <Link
                                    to={`/admin/${event}/edit/Event/${eventId}`}
                                    className="cursor-pointer hover:text-primary hover:wght-semibold"
                                  >
                                    {menuEventsOptions.find(event => event.value === eventId)?.label}
                                  </Link>
                                </BodyCell>

                                <BodyCell column="Zone">
                                  <Link
                                    to={`/admin/${event}/edit/EventLocationZone/${zoneId}`}
                                    className="cursor-pointer hover:text-primary hover:wght-semibold"
                                  >
                                    {zoneName || zoneId}
                                  </Link>
                                </BodyCell>

                                <BodyCell column="TicketId">
                                  <Link
                                    to={`/admin/${event}/edit/Ticket/${eventTickets?.find(i => i.value === ticketId)?._id}`}
                                    className="cursor-pointer hover:text-primary hover:wght-semibold"
                                  >
                                    {eventTickets?.find(i => i.value === ticketId)?.label}
                                  </Link>
                                </BodyCell>

                                <BodyCell column="NFC Mode"
                                  onClick={() => {
                                    if (role === 'read') return
                                    const quickData = { nfcMode: !nfcMode, ticketId, deviceId, zoneId }
                                    singlePropUpdate(quickData)
                                  }}
                                  className={`${ticketId && deviceId && zoneId ? 'cursor-pointer hover:wght-semibold hover:text-primary ' : ''}`}
                                >
                                  {nfcMode ? 'ON' : 'OFF'}
                                </BodyCell>

                                <BodyCell column="Offline Capable"
                                  onClick={() => {
                                    if (role === 'read') return
                                    const quickData = { offlineCapable: !offlineCapable, ticketId, deviceId, zoneId }
                                    singlePropUpdate(quickData)
                                  }}
                                  className={`${ticketId && deviceId && zoneId ? 'cursor-pointer hover:wght-semibold hover:text-primary ' : ''}`}
                                >
                                  {offlineCapable || offlineCapable === null ? 'YES' : 'NO'}
                                </BodyCell>

                                <BodyCell column="PublicId">
                                  <Link
                                    to={`/admin/${event}/edit/Ticket/${eventTickets?.find(i => i.value === ticketId)?._id}`}
                                    className="cursor-pointer hover:text-primary hover:wght-semibold"
                                  >
                                    {ticketId}
                                  </Link>
                                </BodyCell>

                                <BodyCell column="HexId">
                                  {hexId}
                                </BodyCell>

                                <BodyCell column="DeviceId">
                                  {deviceId}
                                </BodyCell>

                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="">
                        <table className="border-separate whitespace-nowrap">
                          <thead className="text-xs text-left bg-white border-b border-grayLight wght-semibold whitespace-nowrap">
                            <tr className="border-b border-separate border-grayLight hover:bg-primaryLight">
                              <th className="sticky right-0 px-1 py-2 bg-white border-b-2 border-l-2 md:px-2 border-grayLight">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.listControlDevices.filter(i => applyDeviceFilters(i, groupZones.length > 1 && group)).sort((a,b) => sortDevices(a,b)).map(({ name, deviceId, freezed }, index) => (
                              <tr
                                id={deviceId}
                                key={`${deviceId}-${name}`}
                                className={`${
                                  (index % 2 === 0 && 'bg-grayLighter') ||
                                  (freezed && 'bg-yellow') ||
                                  'bg-white'
                                } h-10 relative border-b border-separate border-grayLight`}
                              >
                                <td className="right-0 flex items-center px-1 py-1 text-xs text-white border-l-2 border-b-1 border-grayLight"
                                >
                                  <Button
                                    size="small"
                                    color="white"
                                    to={`${history.location.pathname.split("/list")[0]}/controldevices/${deviceId}`}
                                  >View</Button>
                                  <Button
                                    size="small"
                                    color="white"
                                    disable={role === 'read' || !deviceId}
                                    onClick={() => {
                                      setDevice(data.listControlDevices.find(d => d.deviceId === deviceId))
                                      setShowActions(deviceId)
                                    }}
                                  >Update</Button>
                                  <Button
                                    disable={!deviceId || selectedDevices?.length > 0}
                                    size="small"
                                    color="white"
                                    onClick={() => setShowActions(deviceId)}
                                  >↖︎</Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      </div>
                    </div>
                  })
                }
              </>}
            </div>
          }
        </>}
      </div>
    </div>
  )
}

export default ControlDevices