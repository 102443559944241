import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useSession } from "../contexts/AuthContext"
import Cookies from "js-cookie"

const TicketsTypeGuestsStats = ({ modelName }) => {
  const [loading, setLoading] = useState(true)
  const [ticketsTypeStats, setTicketsTypeStats] = useState([])
  const [sortedTicketsType, setSortedTicketsType] = useState([])
  const [sortedValue, setSortedValue] = useState("")
  const [sortAscending, setSortAscending] = useState(true)
  const token = useSession()
  const history = useHistory()

  const getTicketsTypeGuestsStats = async () => {
    fetch(`${process.env.REACT_APP_API_URL}get-tickets-type-guests-stats`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        currentOrganization: Cookies.get('organization'),
        currentEvent: Cookies.get('event'),
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        if (response.status === 200) {
          setTicketsTypeStats(response.body.stats)
          setSortedTicketsType(response.body.stats)
        } else {
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${history.location.pathname}`)
          }
        }

        setLoading(false)
      })
  }

  useEffect(() => {
    (async () => {
      await getTicketsTypeGuestsStats()
    })()
  }, [])

  const getTitle = useCallback(
    (key) => {
      switch (key) {
        case "title":
          return "TICKET TYPE"
        case "price":
          return "PRICE"
        case "totalTickets":
          return "TICKETS"
        case "totalTicketsPrice":
          return "TICKETS PRICE"
        case "totalChangedTicketsPrice":
          return "CHANGED TICKETS PRICE"
        case "changedTickets":
          return "TICKETS CHANGED"
        case "percentTicketsChanged":
          return "% TICKETS CHANGED"
      }
    },
    [ticketsTypeStats],
  )
  const getIcon = useCallback(
    (key) => {
      if (key === "price" || key === "totalTicketsPrice" || key === "totalChangedTicketsPrice") return " €"
      if (key === "percentTicketsChanged") return " %"
      return ""
    },
    [ticketsTypeStats],
  )

  const handleSort = (key) => {
    if (sortedValue === key) {
      if (sortAscending) {
        setSortAscending(false)
      } else {
        setSortedValue(undefined)
        setSortAscending(undefined)
      }
    } else {
      setSortedValue(key)
      setSortAscending(true)
    }
  }

  const stringsComparator = (a, b) => {
    if (typeof a === 'undefined' && typeof b === 'undefined') return 0
    if (typeof a === 'undefined') return sortAscending ? 1 : -1
    if (typeof b === 'undefined') return sortAscending ? -1 : 1

    if (a < b) return sortAscending ? -1 : 1
    if (a > b) return sortAscending ? 1 : -1
    return 0
  }

  const numbersComparator = (a, b) => {
    if (sortAscending) return a - b
    return b - a
  }

  useEffect(() => {
    if (ticketsTypeStats.length > 0) {
      const items = [...ticketsTypeStats]
      if (sortedValue) {

        const sorted = items.sort((a, b) => {
          const itemA = typeof a[sortedValue] === 'string' ? a[sortedValue].toLowerCase() : parseInt(a[sortedValue])
          const itemB = typeof b[sortedValue] === 'string' ? b[sortedValue].toLowerCase() : parseInt(b[sortedValue])

          const result = sortedValue === "title" ? stringsComparator(itemA, itemB) : numbersComparator(itemA, itemB)
          return result
        })

        setSortedTicketsType(sorted)
      } else {
        setSortedTicketsType(ticketsTypeStats)
      }
    }
  }, [ticketsTypeStats, sortedValue, sortAscending, modelName])

  return (
    <div className="px-6 pb-6 mb-12 overflow-scroll">
      <table className="w-full overflow-x-auto bg-white border border-grayLight">
        <thead className="w-full text-left borde-b border-grayLight">

          {sortedTicketsType.length > 0 && sortedTicketsType.slice(0, 1).map((ticktType) => {
            const keys = Object.keys(ticktType);
            return (
              <tr
                key={ticktType._id}
                className="border-b border-grayLight"
              >
                {keys.map((key, index) =>
                  key !== "_id"
                  && (
                    <th
                      key={index} className="px-4 py-2"
                      onClick={() => handleSort(key)}
                    >
                      <span className="relative text-xs cursor-pointer wght-normal whitespace-nowrap">
                        {key === sortedValue && (
                          <span className="absolute inset-y-0 right-0 flex items-center justify-center -mr-4 text-center wght-semibold">
                            {sortAscending ? "↑" : "↓"}
                          </span>
                        )}
                        {getTitle(key)}
                      </span>
                    </th>
                  )
                )}
              </tr>
            )
          })}
        </thead>

        {sortedTicketsType.length > 0 && sortedTicketsType.map(ticktType => {
          const keys = Object.keys(ticktType);
          return (
            <tbody key={ticktType._id}>
              <tr className="relative align-top bg-white border-b border-grayLight hover:bg-primaryLight">
                {keys.map((key, index) => {
                  const icon = getIcon(key)
                  return key !== "_id"
                    && <td key={index} className={`px-4 py-2 text-xs ${key === "title" ? "wght-semibold" : ""}`}>
                      {ticktType[key] + icon}
                    </td>
                })}
              </tr>
            </tbody>
          )
        })}
      </table>

      {
        (!loading && sortedTicketsType.length === 0) &&
        <div className="mt-8">Nothing to list</div>
      }

      {
        loading &&
        <div className="mt-0 mb-12">Loading...</div>
      }
    </div >
  )
}

export default TicketsTypeGuestsStats