import { getAuth, signOut } from 'firebase/auth';
import Cookies from 'js-cookie';

export const logout = () => {
  const auth = getAuth();
  signOut(auth).then(() => {
    // Sign-out successful.
  }).catch((error) => {
    console.log('🚀 ~ file: firebase-service.js:8 ~ signOut ~ error:', error)
    // An error happened.
  });

  Cookies.remove('event')
  Cookies.remove('eventTimezone')
  Cookies.remove('user')
  Cookies.remove('userManagerPreferences')
  Cookies.remove('organization')
}