import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const Checkbox = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label, readOnly, activeByDefault = false },
  validations,
  languages,
  readMode,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable, activeByDefault ? 1 : 0),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState(parseInt(value) === 1 || value === true)

  const onChangeText = e => {
    const checkboxValue = e.target.checked
    if (translatable) {
      value[language] = checkboxValue ? 1 : 0
      setValue({ ...value })
      setCurrentValue(checkboxValue)
    } else {
      setValue(checkboxValue ? 1 : 0)
      setCurrentValue(checkboxValue)
    }
  }

  useEffect(() => {
    setCurrentValue(
      translatable ? parseInt(value[language]) === 1 : parseInt(value) === 1 || value === true,
    )
  }, [language, translatable, value])

  useEffect(() => {
    setValue(defaultValue(name, storedData, translatable, activeByDefault ? 1 : 0))
  }, [storedData])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const input = (
    <div>
      <input
        disabled={readMode}
        name={name}
        className={`rounded ${
          translatable ? 'rounded-tl-none' : ''
        } px-2 py-1
        ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } bg-white outline-none placeholder-gray`}
        type="checkbox"
        checked={currentValue}
        onChange={readOnly || readMode ? null : onChangeText}
        readOnly={readOnly || readMode}
        value="1"
      />
    </div>
  )

  return (
    <div className={`${readOnly? 'opacity-50' : ''}`}>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {input}
        </LanguageSelector>
      ) : (
        input
      )}
    </div>
  )
}

export default Checkbox
