import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import Button from '../Button'
import { DeleteUser } from '../../externalApi/gql.services'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'

const EditUsers = () => {
  const title = 'Edit users'
  const history = useHistory()
  const { name: modelName, page: pageParam, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [data, setData] = useState(false)
  const [page, setPage] = useState(pageParam - 1 || false)
  const [hasMorePages, setHasMorePages] = useState(false)
  const pageLimit = 50
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [role, setRole] = useState(false)
  const token = useSession()

  useEffect(() => {
    setLoading(true)
    getData()
  }, [page, modelName])

  useEffect(() => {
  }, [loading])

  const getData = (clear = false) => {
    setData(false)
    fetch(`${process.env.REACT_APP_API_URL}edit-users`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName: 'edit-user',
        page,
        pageLimit,
        email: !clear && searchValue,
        filters: {
          event: getEvent(),
          organization: getOrganization(),
        },
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
          const rawBody = { ...response.body }
          setData(rawBody)
          if (response.body.pageLimit) {
            setHasMorePages(response.body.items.length === response.body.pageLimit)
          } else {
            setHasMorePages(response.body.items.length === pageLimit)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
      .catch(error => {
        console.log('Error', error)
      })
  }

  const clearFilters = () => {
    setSearchValue('')
    filterPhones(true)
  }

  const filterPhones = (clear = false) => {
    setLoading(true)
    if (page === 0) {
      getData(clear)
    } else {
      setPage(0)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      filterPhones()
    }
  }

  const deleteUser = async ({ _id: userId, email }) => {
    if (role === 'read')
      if (window.confirm(`Are you sure you want to delete this user?  \r\n\n${email}`)) {
        setLoading(true)
        try {
          const res = await fetch(`${process.env.REACT_APP_GRAPH_URL}`, {
            method: 'POST',
            headers: {
              Authorization: `${token}`,
              'AccessTicket-App': 'manager',
            },
            body: JSON.stringify({
              query: DeleteUser,
              variables: {
                userId,
              },
            }),
          })
          const { data, errors: responseErrors } = await res.json()
          setLoading(false)
          if (data && data.deleteUser) {
            getData()
          } else {
            console.log('responseErrors', responseErrors)
          }
        } catch (error) {
          console.log('error', error)
        }
      }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {data && data.items.length === 0 && <div>Nothing to list</div>}

      {loading ? <div className="mt-8">Loading...</div> :
        <>
          <div className="w-full mb-2 sm:mb-0 sm:w-auto">
            <div className="flex flex-col md:flex-row">
              <input
                name="search"
                className={`w-1/2 rounded px-2 bg-white outline-none placeholder-gray mr-2`}
                type="text"
                placeholder="Search email"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)}
              />
              {loading ? <div>Loading...</div> : <>
                <span className="mr-2 cursor-pointer hover:wght-semibold" onClick={() => filterPhones()}>Search</span>
                <span className="mr-2">|</span>
                <span className="mr-2 cursor-pointer hover:wght-semibold" onClick={() => clearFilters()}>Reset</span>
              </>}
            </div>
          </div>
          <div className="mt-4 overflow-scroll"><table className="w-full">
            <thead className="w-full text-left bg-white border-b border-grayLight">
              <tr>
                <th className="px-4 py-2">Email</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {data && data.items.map((item, index) =>
                <tr key={item._id} className={`relative border-b bg-white border-grayLight hover:bg-primaryLight ${item.deleted && 'text-error bg-lightRose '}`}>
                  <td><div className={`px-4 py-2`}>{item.email}</div></td>
                  <td className='flex items-center px-4 py-2'>
                    {role !== 'read' && !item.deleted &&
                      <Button color='error' size="small" onClick={() => deleteUser(item)} >Delete</Button>
                    }
                  </td>
                </tr>
              )}
            </tbody>
          </table></div>
          {(hasMorePages || page > 0) &&
            <div className="flex justify-between mt-8">
              <div>
                {page > 0 && <span onClick={() => setPage(page - 1)} className="ml-1 cursor-pointer hover:wght-semibold">Previous page</span>}
              </div>
              {hasMorePages &&
                <span onClick={() => setPage(page + 1)} className="mr-1 cursor-pointer hover:wght-semibold">Next page</span>
              }
            </div>}
        </>
      }
    </>
  )
}

export default EditUsers