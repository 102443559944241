import dayjs from 'dayjs'

const DICE = 'dice'

// TODO: this should be setted in the organization
const CONFIG = {
  '642be117091b470008a2dafd': {
    marketplace: {
      disclaimer: {
        en: `\n\nLos usuarios del carnet Plastic Club podrán realizar dos tipos de reservas:
        \n1. Reserva de entrada a coste 0, con la que podrás acceder al club hasta la 01:30. Pasada esta hora, tu reserva quedará invalidada.
        \n2. Reserva con pago de entrada, que te permitirá el acceso al club durante toda la noche con una consumición incluida, excepto en eventos especiales que se detallarán.`

      }
    }
  }
}

const parseEventFromDice = (data, organizationId) => {
  if (!data) { console.log('invalid data'); return }
  const formattedDate = dayjs(data.startDatetime).locale('en').format('ddd, DD MMM, hh:mm a')
  const textDate = dayjs(data.startDatetime).locale('en').format('DD/MM')
  const venue = data.venues?.[0]?.name || ''
  const currentDate = new Date().toISOString()

  return ({
    data: {
      title: data.name,
      endDate: data.endDatetime,
      startDate: data.startDatetime,
      image: data.image,
      text: {
        en: `${textDate}${venue.length ? `: ${venue}`: ''}`,
        es: `${textDate}${venue.length ? `: ${venue}`: ''}`,
        ca: `${textDate}${venue.length ? `: ${venue}`: ''}`,
      },
    },
    appInfo: {
      description: {
        en: data.description,
        es: data.description,
        ca: data.description,
      },
      title: {
        en: data.name,
        es: data.name,
        ca: data.name,
      }
    },
    marketplacesettings: {
      status: 'default',
      hasWaitingRoom: 0,
      startDate: currentDate,
      endDate: data.endDatetime,
      venue: {
        en: data.venues?.[0]?.name,
        es: data.venues?.[0]?.name,
        ca: data.venues?.[0]?.name
      },
      text: {
        en: data.description + CONFIG[organizationId]?.marketplace?.disclaimer?.en ?? '',
        es: data.description + CONFIG[organizationId]?.marketplace?.disclaimer?.en ?? '',
        ca: data.description + CONFIG[organizationId]?.marketplace?.disclaimer?.en ?? ''
      },
      title: {
        en: data.name,
        es: data.name,
        ca: data.name
      },
      subtitle: {
        en: formattedDate,
        es: formattedDate,
        ca: formattedDate
      },
      image: data.image
    }
  })
}
const getEventFromDice = ({ eventId, token, organization }) => {
  return fetch(`${process.env.REACT_APP_API_URL}get-dice-event`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      organization,
      eventId,
    }),
  }).then(response => response
      .json()
      .then(data => {

        return ({ status: response.status, body: parseEventFromDice(data, organization) })
      }))
    .catch(error => {
      console.log('Error', error)
    })
}

const vendorFunctions = {
  [DICE]: getEventFromDice,
};

const getEventFromExternalVendor = ({ vendor, eventId, token, organization }) => {
  const selectedFunction = vendorFunctions[vendor];
  if (selectedFunction) return selectedFunction({ eventId, token, organization });
  console.log(`No vendor named ${vendor} found.`);
}

export default getEventFromExternalVendor
