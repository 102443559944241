import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import Button from './Button'
import Section from './Lib/Section'
import { useSession } from '../contexts/AuthContext'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import { getUserPreference, updateCookiePreferences } from './Lib/utils'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

const StatsPurchases = ({ menuEventsOptions }) => {
  const { page: pageParam, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(false)
  const title = 'Product purchases (ShopPurchase)'
  const [page, setPage] = useState(pageParam - 1 || 0)
  const [hasMorePages, setHasMorePages] = useState(false)
  const pageLimit = 100
  const [filters, setFilters] = useState({
    status: getUserPreference({
      parentKey: 'statsPurchases',
      key: 'status',
      defaultValue: ['EXCHANGED', 'HOLDED', 'PAID'],
    })
  })
  const [visibleSections, setVisibleSections] = useState(getUserPreference({
    parentKey: 'statsPurchases',
    key: 'visibleSections',
    defaultValue: [],
  }))
  const [firstLoad, setfirstLoad] = useState(true)
  const [day, setDay] = useState(dayjs().format('YYYY-MM-DD'))
  const [startDate, setStartDate] = useState(
    (dayjs().hour() < 8) ?
      dayjs().subtract(1, 'day').hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
      :
      dayjs().hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
  )
  const [endDate, setEndDate] = useState(
    (dayjs().hour() < 8) ?
      dayjs().hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
      :
      dayjs().add(1, 'day').hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
  )
  // const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
  const [role, setRole] = useState(false)
  const token = useSession()
  const [insightType, setinsightType] = useState(getUserPreference({
    parentKey: 'statsPurchases',
    key: 'insightType',
    defaultValue: 'products',
  }))
  const [productsOptionsView, setProductsOptionsView] = useState(getUserPreference({
    parentKey: 'statsPurchases',
    key: 'productsOptionsView',
    defaultValue: false,
  }))
  const [autoRefresh, setAutoRefresh] = useState(getUserPreference({
    parentKey: 'statsShopPurchases',
    key: 'autoRefresh',
    defaultValue: false,
  }))
  const [applyfilters, setApplyfilters] = useState(false)


  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsShopPurchases',
      key: 'autoRefresh',
      value: autoRefresh,
    })
  }, [autoRefresh])

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        setLoading(true)
      }, 10000)
      return () => clearInterval(interval)
    }
  }, [autoRefresh])

  useEffect(() => {
    if (event !== 'all') {
      getData()
    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (loading) {
      setApplyfilters(true)
      getData()
    }
  }, [loading])

  useEffect(() => {
    setLoading(true)
  }, [startDate, endDate, page, filters, insightType, productsOptionsView])

  useEffect(() => {
    if (!firstLoad && visibleSections?.includes('consumables')) {
      getData()
    }
  }, [visibleSections])

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsPurchases',
      key: 'status',
      value: filters.status,
    })
  }, [filters])

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}model-stats-purchases`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        organization: getOrganization(),
        event: getEvent(),
        startDate: dayjs(startDate).utc().format(),
        endDate: dayjs(endDate).utc().format(),
        page,
        pageLimit,
        filters,
        consumables: visibleSections?.includes('consumables'),
        insightType,
        productsOptionsView,
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setApplyfilters(false)
        setLoading(false)
        const rawData = response.body
        if (response.status === 200) {
          setRole(rawData.role)
          setData(rawData)

          setHasMorePages(rawData.transactions && rawData.transactions.length === pageLimit)
          if (firstLoad) {
            setfirstLoad(false)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          setData({})
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
          if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const onChangeDate = (newDate, type) => {
    setPeriod(false)
    if (type === 'startDate') {
      setStartDate(dayjs(newDate).format('YYYY-MM-DDTHH:mm:ss'))
    } else {
      setEndDate(dayjs(newDate).format('YYYY-MM-DDTHH:mm:ss'))
    }
  }

  const [predefinedPeriod, setPredefinedPeriod] = useState('today')

  const setPeriod = (period) => {
    if (predefinedPeriod === period) {
      setPredefinedPeriod(false)
      return
    }

    setPredefinedPeriod(period)
    switch (period) {
      case 'total':
        setStartDate(dayjs().startOf('year').format('YYYY-MM-DDTHH:mm:ss'))
        setEndDate(dayjs().format('YYYY-MM-DDTHH:mm:ss'))
        break;
      case 'today':
        if (dayjs().hour() < 8) {
          setStartDate(dayjs().subtract(1, 'day').hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss'))
          setEndDate(dayjs().hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss'))
        } else {
          setStartDate(dayjs().hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss'))
          setEndDate(dayjs().add(1, 'day').hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss'))
        }
        break;
      case 'yesterday':
        setStartDate(dayjs().subtract(1, 'day').hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss'))
        setEndDate(dayjs().hour(6).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss'))
        break;
      case 'thisWeek':
        setStartDate(dayjs().startOf('week').format('YYYY-MM-DDTHH:mm:ss'))
        setEndDate(dayjs().endOf('week').format('YYYY-MM-DDTHH:mm:ss'))
        break;
      case 'lastWeek':
        setStartDate(dayjs().subtract(1, 'week').startOf('week').format('YYYY-MM-DDTHH:mm:ss'))
        setEndDate(dayjs().subtract(1, 'week').endOf('week').format('YYYY-MM-DDTHH:mm:ss'))
        break;
      case 'thisMonth':
        setStartDate(dayjs().startOf('month').format('YYYY-MM-DDTHH:mm:ss'))
        setEndDate(dayjs().endOf('month').format('YYYY-MM-DDTHH:mm:ss'))
        break;
      case 'lastMonth':
        setStartDate(dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DDTHH:mm:ss'))
        setEndDate(dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DDTHH:mm:ss'))
        break;
      default:
        break;
    }
  }

  const handleChangeFilters = (type, filter) => {
    const rawFilters = filters
    rawFilters[type] = filter
    setFilters({ ...rawFilters })
  }

  const handleVisibleSections = (section) => {
    if (visibleSections?.includes(section)) {
      setVisibleSections(visibleSections.filter(item => item !== section))
    } else {
      setVisibleSections([...visibleSections, section])
    }
  }

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsPurchases',
      key: 'visibleSections',
      value: visibleSections,
    })
  }, [visibleSections])

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsPurchases',
      key: 'insightType',
      value: insightType,
    })
  }, [insightType])

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsPurchases',
      key: 'productsOptionsView',
      value: productsOptionsView,
    })
  }, [productsOptionsView])

  const handleExport = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }

    const body = JSON.stringify({
      modelName: 'stats-purchases',
      currentOrganization: Cookies.get('organization'),
      currentEvent: Cookies.get('event'),
      insightType,
      productsOptionsView,
      data
    })

    fetch(`${process.env.REACT_APP_API_URL}export-product-purchases`, {
      method: 'POST',
      headers,
      body,
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          const csv = response?.body?.body
          if (csv) {
            const blob = new Blob([csv], { type: 'text/csv' })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')

            const today = dayjs().format('YYYY-MM-DD_HH-mm-ss')
            const fileName = `product-purchases-${today}.csv`
            link.setAttribute('href', url)
            link.setAttribute('download', `${fileName}`)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }

        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      <div className='flex items-end justify-end mt-2'>
        <div className='flex'>
          <Button
            className='mr-1'
            onClick={() => setAutoRefresh(!autoRefresh)}
            size='small'
            color={autoRefresh ? 'green' : 'white'}
          >
            Auto refresh
          </Button>
          <Button
            color="primary"
            className="ml-0"
            size="small"
            onClick={handleExport}
            disable={applyfilters}
          >
            Export
          </Button>
        </div>
      </div>

      <div className="mt-2 h-fit">
        <p className="mr-2 wght-semibold">View</p>
        <div className='flex'>
          <select className="p-1 border rounded cursor-pointer hover:border-primary border-gray" name="template" id="template"
            onChange={(e) => {
              setData(false)
              setinsightType(e.target.value)
            }} value={insightType}
          >
            <option value="products">Products</option>
            <option value="productsGroupedByShop">Products By Shop</option>
            <option value="shops">Shops</option>
            <option value="purchases">Purchases</option>
          </select>
          <Button visibility={!!(insightType === 'products')} disable={loading} color={productsOptionsView === 'optionsCombined' ? 'primary' : 'white'} className="ml-2 text-sm" onClick={() => {
            setProductsOptionsView(productsOptionsView === 'optionsCombined' ? false : 'optionsCombined')
          }}>Show combined options</Button>
          <Button visibility={!!(insightType === 'products')} disable={loading} color={productsOptionsView === 'optionsUnits' ? 'primary' : 'white'} className="ml-2 text-sm" onClick={() => {
            setProductsOptionsView(productsOptionsView === 'optionsUnits' ? false : 'optionsUnits')
          }}>Show single options</Button>
        </div>
      </div>

      {event === 'all' ? <h3 className="mt-8">Select an event from menu</h3> :
        <div className="mt-4">
          <div className="flex flex-col my-4 md:justify-center">
            <span className="mr-2 wght-semibold">Period</span>
            <div className="flex-1 md:flex-initial">
              <div className="flex flex-col md:flex-row">
                {/* // TODO: date in event timezone */}
                <input
                  name="startDate"
                  className={`rounded border border-gray px-2 bg-white outline-none placeholder-gray md:mr-2 mb-2 md:mb-0`}
                  type="datetime-local"
                  placeholder="Start date"
                  value={startDate}
                  onChange={(e) => onChangeDate(e.target.value, 'startDate')}
                />
                <input
                  name="endDate"
                  className={`rounded px-2 border border-gray bg-white outline-none placeholder-gray md:mr-2 mb-2 md:mb-0`}
                  type="datetime-local"
                  placeholder="End date"
                  value={endDate}
                  onChange={(e) => onChangeDate(e.target.value, 'endDate')}
                />
                <Button color="white" className="px-4 mx-0 text-sm" onClick={() => setLoading(true)} >Filter</Button>
              </div>
            </div>
            <div className='flex items-center mt-2'>
              <Button color={`${!day ? 'primary' : 'white'}`} size="small" onClick={() => setPeriod('total')} className="ml-0 mr-0.5" >Total</Button>
              <span className='mx-1'> | </span>
              <Button color={`${predefinedPeriod === 'today' ? 'primary' : 'white'}`} size="small" onClick={() => setPeriod('today')} className="mx-0.5" >Today</Button>
              <Button color={`${predefinedPeriod === 'yesterday' ? 'primary' : 'white'}`} size="small" onClick={() => setPeriod('yesterday')} className="mx-0.5" >Yesterday</Button>
              <Button color={`${predefinedPeriod === 'thisWeek' ? 'primary' : 'white'}`} size="small" onClick={() => setPeriod('thisWeek')} className="mx-0.5" >This week</Button>
              <Button color={`${predefinedPeriod === 'lastWeek' ? 'primary' : 'white'}`} size="small" onClick={() => setPeriod('lastWeek')} className="mx-0.5" >Last week</Button>
              <Button color={`${predefinedPeriod === 'thisMonth' ? 'primary' : 'white'}`} size="small" onClick={() => setPeriod('thisMonth')} className="mx-0.5" >This month</Button>
              <Button color={`${predefinedPeriod === 'lastMonth' ? 'primary' : 'white'}`} size="small" onClick={() => setPeriod('lastMonth')} className="mx-0.5" >Last month</Button>
            </div>
          </div>

          <div>
            <span className="mr-2 wght-semibold">Filters</span>
            <div className='flex justify-between'>
              <div className="flex">
                <Button color={`${filters.status.length > 1 ? 'primary' : 'white'}`} size="small" onClick={() => handleChangeFilters('status', ['EXCHANGED', 'HOLDED', 'PAID'])} className={`ml-0 mr-1`} >All</Button>
                <Button color={`${filters.status.length === 1 && filters.status[0] === 'EXCHANGED' ? 'primary' : 'white'}`} size="small" onClick={() => handleChangeFilters('status', ['EXCHANGED'])} className={`mx-1`} >Exchanged</Button>
                <Button color={`${filters.status.length === 1 && filters.status[0] === 'HOLDED' ? 'primary' : 'white'}`} size="small" onClick={() => handleChangeFilters('status', ['HOLDED'])} className={`mx-1`} >Holded</Button>
                <Button color={`${filters.status.length === 1 && filters.status[0] === 'PAID' ? 'primary' : 'white'}`} size="small" onClick={() => handleChangeFilters('status', ['PAID'])} className={`mx-1`} >Paid</Button>
              </div>
            </div>
          </div>

          {!loading && data && data.stats &&
            <table className="w-full mt-4">
              <thead className="w-full text-left bg-white border-b border-grayLight">
                <tr>
                  <th className="px-4 py-2">
                    <span className="relative cursor-pointer">Event</span>
                  </th>
                  <th className="px-4 py-2">
                    <span className="relative cursor-pointer">Transactions</span>
                  </th>
                  <th className="px-4 py-2">
                    <span className="relative cursor-pointer">Total</span>
                  </th>
                  <th className="px-4 py-2">
                    <span className="relative cursor-pointer">Real Total (without wallet credit)</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr className="border-b border-grayLight">
                  <td className="px-4 py-2">
                    <span className="mr-2">{menuEventsOptions.find(i => i.slug === event)?.label}</span>
                  </td>
                  <td className="px-4 py-2">
                    <span className="w-full mr-2 text-right">{data.stats && data.stats.transactions?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 0}</span>
                  </td>
                  <td className="px-4 py-2">
                    <span className="w-full mr-1 text-right">{data.stats.realAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 0}</span>
                    <span className="mr-2">€</span>
                  </td>
                  <td className="px-4 py-2">
                    <span className="w-full mr-1 text-right">{data.stats && data.stats.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || 0}</span>
                    <span className="mr-2">€</span>
                  </td>
                </tr>
              </tbody>
            </table>
          }

          {
            !loading && data && insightType === 'purchases' &&
            <div className="mt-4" >
              <Section color="lightRose" title="Consumables" open={visibleSections?.includes('consumables')} onClick={() => handleVisibleSections('consumables')}>
                <table>
                  <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                    <tr>
                      <th className="relative px-4 py-2 text-left wght-semibold ">Zone</th>
                      <th className="relative px-4 py-2 text-left wght-semibold ">Transactions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data && data.consumables && data.consumables.map((consumable, index) => (
                      <tr key={index} className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight">
                        <td className="px-4 py-2 whitespace-nowrap">{consumable._id}</td>
                        <td className="px-4 py-2 whitespace-nowrap">{consumable.transactions}</td>
                      </tr>
                    ))}
                    <tr className="relative text-left border-t-2 border-grayLight bg-primaryLight">
                      <td className="px-4 py-2 whitespace-nowrap">Total</td>
                      <td className="px-4 py-2 whitespace-nowrap">{data.consumables && data.consumables.reduce((prev, curr) => prev + curr.transactions, 0)}</td>
                    </tr>
                  </tbody>
                </table>
              </Section>
            </div>
          }

          {loading && <div className="my-4 ml-auto">Loading...</div>}

          {!loading && (!data || !data.transactions) && <div>{!loading && <p className="my-4">No transactions</p>}</div>}

          {!loading && insightType === 'purchases' && data && data.transactions && <table className="w-full mt-4">
            <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
              <tr>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer wght-semibold">Date</span>
                </th>

                <th className="px-4 py-2">
                  <span className="relative cursor-pointer wght-semibold">Shop</span>
                </th>
                <th className="px-4 py-2 ">
                  <span className="relative cursor-default wght-semibold">Items</span>
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer wght-semibold">Total price</span>
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer wght-semibold">Total real price</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.transactions &&
                data.transactions.map((info, index) => (
                  <tr
                    key={index}
                    className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight"
                  >
                    <td className="px-4 py-2 whitespace-nowrap">
                      {dayjs(info.exchangeDate || info.creationDate).format('DD/MM/YYYY HH:mm:ss')}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap ">
                      {info?.shop?.title?.en}
                    </td>
                    <td className="px-4 py-2 text-xs">
                      {info.products?.map((product, index) => (
                        <div key={index} className={`${info.products?.length > 1 && index < info?.products.length - 1 ? 'border-b border-grayLight' : ''}`}>
                          <div className="flex justify-start">
                            <span className="mr-2">{product.units}</span>
                            <span className="w-full mr-2">{product.title.en}</span>
                            <span className="whitespace-nowrap">{product.pricePaid} €</span>
                          </div>
                          <span className="text-xxs text-gray">{product?.sections.map(section => section?.options.map(option => option.title.en).join(', ')).filter(a => a.trim() !== '').join(', ')}</span>
                        </div>

                      ))}
                    </td>
                    <td className="px-4 py-2">
                      {info.totalPrice} €
                    </td>

                    <td className="px-4 py-2">
                      {info.priceWithoutWallet} €
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>}

          {!loading && insightType === 'products' && !productsOptionsView && data?.transactions && <table className="w-full mt-4">
            <thead className="w-full text-left bg-white border-b border-grayLight">
              <tr>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer">Product</span>
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer">Shops</span>
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer">Categories</span>
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer">Transactions</span>
                </th>
                <th className="px-4 py-2 ">
                  <span className="relative cursor-default">Total</span>
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer">Real total</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.transactions &&
                data.transactions.map((info, index) => (
                  <tr
                    key={index}
                    className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight"
                  >
                    <td className="px-4 py-2">
                      <a href={`/admin/all/edit/ShopProduct/${info.product?._id}`} target='_blank' className=' hover:wght-semibold hover:text-primary'>
                        {info.product?.title['en']}
                      </a>
                    </td>
                    <td className="px-4 py-2">
                      {
                        info.shops?.length > 0 && info.shops.map((shop, index) => (
                          <div key={index}>
                            <a href={`/admin/all/edit/Shop/${shop?._id}`} target='_blank' className=' hover:wght-semibold hover:text-primary'>
                              {shop?.title['en']}
                            </a>
                          </div>
                        ))
                      }
                    </td>
                    <td className="px-4 py-2">
                      {
                        info.categories.length > 0 && info.categories.map((category, index) => (
                          <div key={index}>
                            {category?.title['en']}
                          </div>
                        ))
                      }
                    </td>
                    <td className="px-4 py-2">
                      {info.transactions?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" }
                    </td>
                    <td className="px-4 py-2">
                      {info.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                    </td>
                    <td className="px-4 py-2">
                      {info.realAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          }

          {!loading && insightType === 'products' && productsOptionsView === 'optionsUnits' && data?.transactions && data.transactions.map((product, index) => {
            if (data.transactions.filter(i => i.product._id.toString() === product._id.toString()).length === 0) return null
            return <div key={`product-${product._id}`} className="mt-4 bg-white" >
              {/* <p className="px-4 py-2 text-lg border-b wght-semibold text-primary border-grayLight">{product.title.en}</p> */}
              <table className="w-full" >
                <thead className="w-full text-left bg-white border-b border-grayLight">
                  <tr>
                    <th className="px-4 py-2" style={{ width: "20%" }}>
                      <span className="relative cursor-pointer">Product</span>
                    </th>
                    <th className="px-4 py-2" style={{ width: "20%" }}>
                      <span className="relative cursor-pointer">Categories</span>
                    </th>
                    <th className="px-4 py-2">
                      <span className="relative cursor-pointer">Transactions</span>
                    </th>
                    <th className="px-4 py-2 ">
                      <span className="relative cursor-default">Total</span>
                    </th>
                    <th className="px-4 py-2">
                      <span className="relative cursor-pointer">Real total</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="relative text-left border-b border-grayLight bg-grayLighter">
                    <td className="px-4 py-2">
                      <a href={`/admin/all/edit/ShopProduct/${product?._id}`} target='_blank' className=' hover:wght-semibold hover:text-primary'>
                        {product?.product.title['en']}
                      </a>
                    </td>
                    <td className="px-4 py-2">
                      {product?.categories.length > 0 && product?.categories.map((category, index) => (
                        <div key={index}>
                          {category?.title['en']}
                        </div>
                      ))}
                    </td>
                    <td className="px-4 py-2">
                      {product.transactions?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" }
                    </td>
                    <td className="px-4 py-2">
                      {product.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                    </td>
                    <td className="px-4 py-2">
                      {product.realAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                    </td>
                  </tr>
                  {
                    data?.transactionsByOptionType?.filter(i => i.product._id.toString() === product._id.toString())?.length > 0 &&
                    <tr className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight">
                      <th className="px-4 py-2" style={{ width: "20%" }}>
                        <span className="relative cursor-pointer">Option</span>
                      </th>
                      <th className="px-4 py-2" style={{ width: "20%" }}>
                        <span className="relative cursor-pointer">Quantity</span>
                      </th>
                    </tr>
                  }
                  {data.transactions && data.transactionsByOptionType &&
                    data.transactionsByOptionType.filter(i => i.product._id.toString() === product._id.toString()).length > 1 &&
                    data.transactionsByOptionType.filter(i => i.product._id.toString() === product._id.toString())?.map((info, index) => (
                      <tr
                        key={index}
                        className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight"
                      >
                        <td className="px-4 py-2">
                          {info?.options?.optionTitle['en']}
                        </td>
                        <td className="px-4 py-2">
                          {info.transactions}
                        </td>
                        <td className="px-4 py-2"></td>
                        <td className="px-4 py-2"></td>
                        <td className="px-4 py-2"></td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          })}

          {!loading && insightType === 'products' && productsOptionsView === 'optionsCombined' && data?.transactions &&
            data.transactions.map((product, index) => {

              if (data.transactions.filter(i => i.product._id.toString() === product._id.toString()).length === 0) return null

              return <div key={`product-${product._id}`} className="mt-4 bg-white" >
                <table className="w-full" >
                  <thead className="w-full text-left bg-white border-b border-grayLight">
                    <tr>
                      <th className="px-4 py-2" style={{ width: "20%" }}>
                        <span className="relative cursor-pointer">Product</span>
                      </th>
                      <th className="px-4 py-2" style={{ width: "20%" }}>
                        <span className="relative cursor-pointer">Categories</span>
                      </th>
                      <th className="px-4 py-2">
                        <span className="relative cursor-pointer">Transactions</span>
                      </th>
                      <th className="px-4 py-2 ">
                        <span className="relative cursor-default">Total</span>
                      </th>
                      <th className="px-4 py-2">
                        <span className="relative cursor-pointer">Real total</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="relative text-left border-b border-grayLight bg-grayLighter">
                      <td className="px-4 py-2">
                        <a href={`/admin/all/edit/ShopProduct/${product?._id}`} target='_blank' className=' hover:wght-semibold hover:text-primary'>
                          {product?.product.title['en']}
                        </a>
                      </td>
                      <td className="px-4 py-2">
                        {product?.categories.length > 0 && product?.categories.map((category, index) => (
                          <div key={index}>
                            {category?.title['en']}
                          </div>
                        ))}
                      </td>
                      <td className="px-4 py-2">
                        {product.transactions?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" }
                      </td>
                      <td className="px-4 py-2">
                        {product.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                      </td>
                      <td className="px-4 py-2">
                        {product.realAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                      </td>
                    </tr>
                    {data.transactions && data.transactionsByOptions &&
                      data.transactionsByOptions.filter(i => i.product._id.toString() === product._id.toString()).length > 1 &&
                      data.transactionsByOptions.filter(i => i.product._id.toString() === product._id.toString())?.map((info, index) => (
                        <tr
                          key={index}
                          className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight"
                        >
                          <td className="px-4 py-2">

                            {info?.options?.filter(i => i?.length > 0).map((option, index) => {
                              return <div key={index}>
                                {(index !== 0) && ' + '} {option}
                              </div>
                            })}
                          </td>
                          <td className="px-4 py-2"></td>
                          <td className="px-4 py-2">
                            {info.transactions?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" }
                          </td>
                          <td className="px-4 py-2">
                            { info.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                          </td>
                          <td className="px-4 py-2">
                            {info.realAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            })}

          {!loading && insightType === 'productsGroupedByShop' && data && data.transactions && data.shops &&
            data.shops.map((shop, index) => {
              if (data.transactions.filter(i => i._id.shop.toString() === shop._id.toString()).length === 0) return null
              return <div key={`shop-${shop._id}`} className="mt-4 bg-white" >
                <p className="px-4 py-2 text-lg border-b wght-semibold text-primary border-grayLight">{shop.title.en}</p>
                <table className="w-full" >
                  <thead className="w-full text-left bg-white border-b border-grayLight">
                    <tr>
                      <th className="px-4 py-2">
                        <span className="relative cursor-pointer">Product</span>
                      </th>
                      <th className="px-4 py-2">
                        <span className="relative cursor-pointer">Categories</span>
                      </th>
                      <th className="px-4 py-2">
                        <span className="relative cursor-pointer">Transactions</span>
                      </th>
                      <th className="px-4 py-2 ">
                        <span className="relative cursor-default">Total</span>
                      </th>
                      <th className="px-4 py-2">
                        <span className="relative cursor-pointer">Real total</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.transactions &&
                      data.transactions.filter(i => i._id.shop.toString() === shop._id.toString()).map((info, index) => (
                        <tr
                          key={index}
                          className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight"
                        >
                          <td className="px-4 py-2">
                            <a href={`/admin/all/edit/ShopProduct/${info.product?._id}`} target='_blank' className=' hover:wght-semibold hover:text-primary'>
                              {info.product?.title['en']}
                            </a>
                          </td>
                          <td className="px-4 py-2">
                            {
                              info.categories.length > 0 && info.categories.map((category, index) => (
                                <div key={index}>
                                  {category?.title['en']}
                                </div>
                              ))
                            }
                          </td>
                          <td className="px-4 py-2">
                            {info.transactions?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" }
                          </td>
                          <td className="px-4 py-2">
                            {info.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                          </td>
                          <td className="px-4 py-2">
                            {info.realAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                          </td>
                        </tr>
                      ))}
                    {data.transactions.filter(i => i._id.shop.toString() === shop._id.toString()).length > 1 &&
                      <tr className='bg-grayLighter'>
                        <td className="px-4 py-2">
                          <span >Total</span>
                        </td>
                        <td className="px-4 py-2">
                          <span ></span>
                        </td>
                        <td className="px-4 py-2">
                          <span >{data.transactions.filter(i => i._id.shop.toString() === shop._id.toString()).reduce((a, b) => a + b.transactions, 0)}</span>
                        </td>
                        <td className="px-4 py-2">
                          <span >{data.transactions.filter(i => i._id.shop.toString() === shop._id.toString()).reduce((a, b) => a + b.amount, 0)} €</span>
                        </td>
                        <td className="px-4 py-2">
                          <span >{data.transactions.filter(i => i._id.shop.toString() === shop._id.toString()).reduce((a, b) => a + b.realAmount, 0)} €</span>
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            })}

          {!loading && insightType === 'shops' && data && data.transactions && <table className="w-full mt-4">
            <thead className="w-full text-left bg-white border-b border-grayLight">
              <tr>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer">Shop</span>
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer">Transactions</span>
                </th>
                <th className="px-4 py-2 ">
                  <span className="relative cursor-default">Total</span>
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer">Real total</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.transactions &&
                data.transactions.map((info, index) => (
                  <tr
                    key={index}
                    className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight"
                  >
                    <td className="px-4 py-2">
                      <a href={`/admin/all/edit/ShopProduct/${info.shop?._id}`} target='_blank' className=' hover:wght-semibold hover:text-primary'>
                        {info.shop?.title['en']}
                      </a>
                    </td>
                    <td className="px-4 py-2">
                      {info.transactions?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" }
                    </td>
                    <td className="px-4 py-2">
                      {info.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                    </td>
                    <td className="px-4 py-2">
                      {info.realAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") || "0" } €
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          }

          {(hasMorePages || page > 0) && !loading && (
            <div className="flex justify-between mt-8">
              <div>
                {page > 0 && (
                  <span
                    onClick={() => setPage(page - 1)}
                    className="ml-1 cursor-pointer hover:wght-semibold"
                  >
                    Previous page
                  </span>
                )}
              </div>
              {hasMorePages && (
                <span
                  onClick={() => setPage(page + 1)}
                  className="mr-1 cursor-pointer hover:wght-semibold"
                >
                  Next page
                </span>
              )}
            </div>
          )}

        </div>
      }
    </div>
  )
}

export default StatsPurchases
