import React, { useState, useEffect } from 'react'
import ReactMde, { commands } from 'react-mde'
import * as Showdown from 'showdown'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true,
})

const listCommands = [
  {
    commands: [
      commands.boldCommand,
      commands.italicCommand,
      commands.linkCommand,
      commands.unorderedListCommand,
      commands.orderedListCommand,
    ],
  },
]

const Textarea = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label, markdown, readOnly, height },
  validations,
  languages,
  innerForm,
  readMode,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')
  const [selectedTab, setSelectedTab] = useState('write')

  const onChangeText = v => {
    if (translatable) {
      value[language] = v
      setValue({ ...value })
    } else {
      setValue(v)
    }
    setCurrentValue(v)
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [language, translatable, value])

  useEffect(() => {
    setValue(defaultValue(name, storedData, translatable))
  }, [storedData])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const textarea = markdown ? (
    <div
      className={`${
        showErrors && errors.length > 0 ? 'border border-error' : ''
      }`}
    >
      <ReactMde
        readOnly={readMode || readOnly}
        commands={listCommands}
        value={currentValue}
        onChange={onChangeText}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        minEditorHeight={height ? height : 200}
        minPreviewHeight={height ? height : 200}
        heightUnits="px"
        generateMarkdownPreview={() => Promise.resolve(converter.makeHtml(currentValue))
        }
      />
    </div>
  ) : (
    <div>
      <textarea
        readOnly={readMode || readOnly}
        name={name}
        style={{ height: height ? height + 'px' : '200px' }}
        className={`w-full px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } rounded ${
          translatable && Object.keys(languages)[0] === language
            ? 'rounded-tl-none'
            : ''
        } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none resize-y`}
        placeholder={label}
        value={currentValue}
        onChange={e => onChangeText(e.target.value)}
      />
    </div>
  )

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {textarea}
        </LanguageSelector>
      ) : (
        textarea
      )}
    </div>
  )
}

export default Textarea
