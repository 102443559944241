import React from 'react'

const Spacer = ({
  options,
}) => {
  const label = options && options.label || ''

  return (
    <div className='mt-4 border-b'>
      <label>{label}</label>
    </div>
  )
}

export default Spacer
