import React, { useState, useEffect } from 'react'

const Section = ({ title, open = true, children, onClick, color = 'primary', className = '' }) => {

  const [isOpen, setIsOpen] = useState(open)

  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick()
    }
    setIsOpen(!isOpen)
  }

  let textColor = 'black'
  let bgColor = 'white'
  let bgColorTitle = color
  let textColorTitle = 'white'
  let borderColor = color

  switch (color) {
    case 'lightRose':
      textColorTitle = 'black'
      textColor = 'black'
      bgColor = 'white'
      break;
  }

  return (
    <div className={`${className} relative mb-4 bg-${bgColor} border border-${borderColor} border-1 text-${textColor} overflow-hidden`}>
      <h2 onClick={handleClick} className={`flex justify-between px-2 py-1 text-sm text-${textColorTitle} cursor-pointer bg-${bgColorTitle}`}>
        <span>{title}</span>
        <span className={`select-none transition ${isOpen ? 'rotate-180' : ''}`}>&darr;</span>
      </h2>
      {isOpen &&
        <div className={`text-sm p-1 overflow-x-auto`}>
          {children}
        </div>}
    </div>
  )

}

export default Section