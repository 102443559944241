import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Form from './Form/Form'
import flatten from './Lib/flatten'
import { useSession } from '../contexts/AuthContext'

const EditMultiple = ({ modelsToShow = [], selectedItems = [], modelName }) => {
  const history = useHistory()
  const [data, setData] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false)
  const [notUniqueId, setNotUniqueId] = useState(false)
  const token = useSession()
  const {
    GuestsEvent,
    GuestsCategory,
    GuestsEntity,
    GuestsQuota,
    GuestsInvitation,
  } = useParams()

  const updateStored = (key, value, errs = []) => {
    setNotUniqueId()

    if (!data.stored) {
      data.stored = {}
    }

    if (typeof data.stored.modelName === 'undefined') {
      data.stored.modelName = modelName
    }

    data.stored[key] = value
    errors[key] = errs
    setData({ ...data })
    setErrors({ ...errors })
  }

  useEffect(() => {
    setData(false)



    fetch(`${process.env.REACT_APP_API_URL}model-edit`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        filters: {
          event: GuestsEvent || Cookies.get('event'),
          organization: Cookies.get('organization'),
        },
        defaultSelectedOptions: {
          GuestsEvent,
          GuestsCategory,
          GuestsEntity,
          GuestsQuota,
          GuestsInvitation,
        },
        modelsToShow,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          const guestsInvitationModel = response.body.models.GuestsInvitation;

          Object.values(guestsInvitationModel).forEach((value) => {
            value?.validations && delete value.validations;
          });

          setData(response.body)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/guests/list/${modelName}`);
          }
        }
      })
  }, [modelName])

  const orderModels = function (data) {
    Object.keys(data).forEach(key => {
      const staticElement = data[key]
      if (Array.isArray(staticElement)) {
        staticElement.forEach((jsonElement, key2) => {
          if (jsonElement['items']) {
            data[key][key2]['items'] = jsonElement['items'].sort((a, b) => a.position - b.position)
          }
        });
      }
    })
  }

  const checkIfTicketIdIsUnique = async (data, ticketModel) => {
    if (!ticketModel) return false
    const bodyData = { ...data }

    let isUnique = false
    await fetch(`${process.env.REACT_APP_API_URL}ticket-unique`, {
      body: JSON.stringify(bodyData),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.body.unique) {
          isUnique = true
        }
      })
      .catch((err) => console.log(err))
    return isUnique
  }

  const save = async () => {
    const errs = flatten(errors)
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setSaving(true)
      orderModels(data.stored)

      const ticketModel = (modelName === 'Ticket')
      const uniqueTicketId = await checkIfTicketIdIsUnique(data.stored, ticketModel)

      const storedFilter = {}

      for (const key in data.stored) {
        if (Object.hasOwnProperty.call(data.stored, key)) {
          const value = data.stored[key];
          if (value) {
            if (typeof value === "object") {
              if (Object.values(value).length) {
                storedFilter[key] = value
              }
            }
            if (value.length && value !== '-1') {
              storedFilter[key] = value
            }
          }
        }
      }

      const selectedIds = selectedItems.map(item => item._id)

      if (!ticketModel || uniqueTicketId) {
        fetch(`${process.env.REACT_APP_API_URL}model-update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            ...storedFilter,
            modelName,
            filters: {
              event: GuestsEvent || Cookies.get('event'),
              organization: Cookies.get('organization'),
            },
            selectedItems: selectedIds,
            modelsToShow
          }),
        })
          .then(response => response
            .json()
            .then(data => ({ status: response.status, body: data })))
          .then(response => {
            setSaving(false)
            if (response.status === 200) {
              window.location.reload()
            } else {
              console.log('Error', response.status, response.body.error)
              if (response.status === 403) {
                Cookies.remove('token')
                history.push('/login')
              } else if (response.status === 401) {
                history.push(`/admin/no-access?url=${window.location.pathname}`)
              }
            }
          })
          .catch(() => setSaving(false))
      } else {
        setSaving(false)
        if (!uniqueTicketId) {
          setNotUniqueId(true)
        }
      }
    }
  }

  return (
    <div className="px-4">
      {!data && <div className="px-4 py-8">Loading...</div>}
      {data && modelName && (
        <div>
          <Form
            onChange={updateStored}
            data={data}
            name={modelName}
            showErrors={showErrors}
            mode="create"
            currentData={data}
          />

          <div className="sticky bottom-0 left-0 flex items-end justify-end p-8 text-xs bg-white border-grayLight">
            <div>
              {saving ? null : (
                <span
                  onClick={() => save()}
                  className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                >
                  Save &amp; close &#8599;
                </span>
              )}
            </div>
          </div>
        </div >
      )}
    </div >
  )
}

export default EditMultiple