import React from 'react'

const Pagination = ({
  page,
  setPage,
  hasMorePages,
  loading,
  disabled,
}) => {

  if (loading || (!hasMorePages && page === 0)) {
    return <></>
  }

  return (
    <div className="flex justify-between mt-8">
      <div>
        {page > 0 && (
          <span
            onClick={() => !disabled && setPage(page - 1)}
            className={`ml-1 ${!disabled ? 'cursor-pointer hover:wght-semibold' : 'cursor-default'}`}
          >
            Previous page
          </span>
        )}
      </div>
      {hasMorePages && (
        <span
          onClick={() => !disabled && setPage(page + 1)}
          className={`mr-1 ${!disabled ? 'cursor-pointer hover:wght-semibold' : 'cursor-default'}`}
        >
          Next page
        </span>
      )}
    </div>
  )
}

export default Pagination