import React from 'react'
import { Link } from 'react-router-dom'

const StatsProgressBar = ({ index, title, total, current, url, subtitle, className }) => {
  let totalValue = Number(total) || current
  const step = Math.round(totalValue / 10)
  const marks = []
  let newMark = step || 1
  while (newMark < totalValue) {
    marks.push(newMark)
    newMark += step || 1
  }

  let barWidth = 99 - (current / totalValue) * 100
  if (barWidth === -1) barWidth = 0
  if (isNaN(barWidth)) barWidth = 99

  const wrapperStyle = `${className || ''} p-2 my-2 bg-white border border-white`
  const ItemWrapper = ({ children }) => {
    if (url) {
      return (
        <Link
          to={url}
          key={title}
          className={`${wrapperStyle || ''} hover:border-primary block`}
        >
          {children}
        </Link>
      )
    }
    return (
      <div key={title} className={wrapperStyle}>
        {children}
      </div>
    )
  }

  return (
    <ItemWrapper key={title}>
      <div>
        <h3>
          {title}:
          {totalValue && (
            <span className="wght-semibold">
              &nbsp;&nbsp;&nbsp;
              {current || '0'}
              {totalValue !== '0' ? ` / ${total}` : ''}
            </span>
          )}
        </h3>
        { subtitle && <p className="text-xs text-gray">{subtitle}</p> }
      </div>

      {totalValue !== '0' && (
        <div className={`w-full mt-2`}>
          <div className="relative w-full h-4 bg-white">
            <div
              className={`absolute border-gray border top-0 left-0 w-full h-4 bg-gradient-to-r from-red-500`}
              style={{
                background:
                  'linear-gradient(-90deg, #49e126 0%, #EFE15C 55%, rgba(255,125,87,1) 85%, rgba(187,26,0,1) 100%)',
              }}
            ></div>
            <div
              className={`absolute border-gray border border-l-0 top-0 right-0 w-full h-4 bg-gradient-to-r from-red-500`}
              style={{
                background: 'white',
                width: `${barWidth}%`,
                transition: 'width 1s',
              }}
            ></div>
          </div>
          <div className="flex justify-between w-full">
            <span className="text-xxs">0</span>
            {marks.map((mark, index) => (
              <span key={index} className="text-xxs">
                {mark}
              </span>
            ))}
            <span className="text-xxs">{totalValue}</span>
          </div>
        </div>
      )}
    </ItemWrapper>
  )
}

export default StatsProgressBar
