import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useHistory, Link } from 'react-router-dom'
import Text from './Form/Text'
import Password from './Form/Password'
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  deleteUser,
} from "firebase/auth";
import useLocalStorage from '../hooks/useLocalStorage'

const Login = () => {
  const history = useHistory()
  const [data, setData] = useState({})
  const [errorMessage, setErrorMessage] = useState(false)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [loading, setLoading] = useState(false)
  const [basicLogin, setBasicLogin] = useState(false)
  const [redirect, setRedirect] = useLocalStorage("redirect")
  const [newUser, setNewUser] = useState(false)

  const updateData = (key, value, errs = []) => {
    data[key] = value
    setData({ ...data })
    errors[key] = errs
    setErrors({ ...errors })
  }

  const login = async () => {
    setLoading(true)
    try {
      const auth = getAuth();

      const { user } = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );

      const response = await fetch(`${process.env.REACT_APP_API_URL}session-login`, {
        method: 'POST',
        body: JSON.stringify(user),
      })
      setLoading(false)

      const responseData = await response.json()
      switch (response.status) {
        case 200:
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)
          tomorrow.setHours(8, 0, 0, 0)
          Cookies.set('user', JSON.stringify(responseData.user), { expires: tomorrow })
          setRedirect('redirect', null)
          window.localStorage.removeItem('redirect')
          history.replace(redirect ? redirect : `/admin/`)
          break;

        case 401:
          await deleteUser(user)
          alert(responseData.error)
          break;

        default:
          setErrorMessage(response.body.message)
          break;
      }
    } catch (err) {
      setLoading(false)
      console.log('🚀 ~ file: Login.js:78 ~ login ~ err:', err)
      setErrorMessage(err.message)
    }
  }

  const register = async () => {
    setLoading(true)
    try {
      const auth = getAuth();
      const { user } = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      )

      await updateProfile(user, { displayName: data.name })

      const response = await fetch(`${process.env.REACT_APP_API_URL}session-login`, {
        method: 'POST',
        body: JSON.stringify(user),
      })
      const responseData = await response.json()
      setLoading(false)
      switch (response.status) {
        case 200:
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)
          tomorrow.setHours(8, 0, 0, 0)
          Cookies.set('user', JSON.stringify(responseData.user), { expires: tomorrow })
          setRedirect('redirect', null)
          window.localStorage.removeItem('redirect')
          history.replace(redirect ? redirect : `/admin/`)
          break;

        case 401:
          await deleteUser(user)
          alert(responseData.error)
          break;

        default:
          setErrorMessage(response.body.message)
          break;
      }
    } catch (err) {
      setLoading(false)
      setErrorMessage(err.message)
    }
  }

  const googleLogin = async () => {
    setLoading(true)
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const { user } = await signInWithPopup(auth, provider)

      const response = await fetch(`${process.env.REACT_APP_API_URL}session-login`, {
        method: 'POST',
        body: JSON.stringify(user),
      })
      setLoading(false)

      const data = await response.json()
      switch (response.status) {
        case 200:
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)
          tomorrow.setHours(8, 0, 0, 0)
          Cookies.set('user', JSON.stringify(data.user), { expires: tomorrow })
          setRedirect('redirect', null)
          window.localStorage.removeItem('redirect')
          history.replace(redirect ? redirect : `/admin/`)
          break;

        case 401:
          await deleteUser(user)
          alert(data.error)
          break;
      }
    } catch (err) {
      setLoading(false)
      console.log('🚀 ~ file: Login.js:71 ~ googleLogin ~ err:', err)
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (newUser) {
        register()
      } else {
        login()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress)
    return () => {
      document.removeEventListener('keypress', handleKeyPress)
    }
  })

  const handleBasicLogin = () => setBasicLogin(state => !state)

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="w-4/5 p-6 bg-white md:w-1/2 lg:w-2/5">
        <h2 className="mb-4 text-xl text-center wght-semibold">Sign In</h2>
        <div className="flex justify-center">
          <span onClick={googleLogin} className="px-4 py-2 text-sm text-white rounded-sm shadow-sm cursor-pointer wght-semibold bg-blue hover:shadow-blue">Continue with Google</span>
        </div>
        <div className="flex items-center justify-center mt-2rem">
          <div className='bg-gray h-0.5 grow-5'></div>
          <div className='text-center grow-1 text-gray text-'>or</div>
          <div className='bg-gray h-0.5 grow-5'></div>
        </div>
        <div className="flex items-end justify-center mt-4">
          <span onClick={handleBasicLogin} className="text-xs cursor-pointer hover:wght-semibold hover:text-blue">Continue with Email</span>
        </div>

        {
          basicLogin &&
          <div className="p-6 bg-white">
            <div>
              {newUser && <div className="mb-4">
                <Text
                  storedData={{}}
                  showErrors={showErrors}
                  onChange={updateData}
                  name="name"
                  translatable={false}
                  options={{ label: 'Name' }}
                  validations={{ not_blank: 'Field required' }}
                />
              </div>}
              <div className="mb-4">
                <Text
                  storedData={{}}
                  showErrors={showErrors}
                  onChange={updateData}
                  name="email"
                  translatable={false}
                  options={{ label: 'Email' }}
                  validations={{ not_blank: 'Field required' }}
                />
              </div>
              <Password
                storedData={{}}
                showErrors={showErrors}
                onChange={updateData}
                name="password"
                translatable={false}
                options={{ label: 'Password' }}
                validations={{ not_blank: 'Field required' }}
                hideRepeat={true}
                onKeyPress={e => (e.key === 'Enter' && login())}
              />
              {errorMessage && <span className="block mt-4 text-error">{errorMessage}</span>}
            </div>
            <div className="flex items-end justify-center mt-4">
              {loading ? (
                <span className="mr-2 wght-bold">Loading...</span>
              ) : (
                <>
                  {newUser ? (
                    <span onClick={register} className="mr-2 cursor-pointer wght-bold hover:wght-semibold hover:text-primary"
                    > Register &#8599;</span>
                  ) : (
                    <span onClick={login} className="mr-2 cursor-pointer wght-bold hover:wght-semibold hover:text-primary"
                    >Login &#8599;</span>
                  )}
                </>
              )}
            </div>
            <div className="flex items-end justify-between mt-4">
              <Link
                to={`/recovery`}
                className="text-xs cursor-pointer hover:wght-semibold hover:text-primary"
              >Forgot your password?
              </Link>
              <span
                onClick={() => setNewUser(!newUser)}
                className="text-xs cursor-pointer hover:wght-semibold hover:text-primary "
              >
                {newUser ? 'Already have an account?' : 'Create an account'}
              </span>
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default Login