import React, { useState, useRef, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useHistory, useParams } from 'react-router-dom'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'
import {
  DiceListTicketsManager,
  DiceExchangeTicketsManager,
} from '../../externalApi/gql.services'
import Button from '../Button'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const DiceList = () => {
  const modelName = 'claim-dice'
  const { event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const history = useHistory()
  const token = useSession()
  const [loading, setLoading] = useState()
  const [claimingtickets, setClaimingtickets] = useState()
  const [phoneNumber, setPhoneNumber] = useState('')
  const inputPhoneNumber = useRef(null)
  const [tickets, setTickets] = useState([])
  const [email, setEmail] = useState('')
  const [role, setRole] = useState()

  useEffect(() => {
    inputPhoneNumber.current.focus()
    getData()
  }, [])

  const handleKeyDownPhone = (event) => {
    if (event.key === 'Enter') {
      getDiceTicketFromPhone()
    }
  }

  const handleKeyDownEmail = (event) => {
    if (event.key === 'Enter') {
      claimTickets()
    }
  }

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}model-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        organization: getOrganization(),
        event: getEvent(),
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const getDiceTicketFromPhone = async () => {
    setLoading(true)
    setTickets([])

    const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        query: DiceListTicketsManager,
        variables: {
          fanPhoneNumber: phoneNumber.trim(),
        },
      }),
    })

    setLoading(false)

    if (res.status !== 200) {
      console.log('res.status', res.status)
      const { errors } = await res.json()
      console.log('errors', errors[0].message)
      return
    }

    try {
      const { data } = await res.json()
      setTickets(data.diceListTicketsManager)
    } catch (e) {
      console.log('error', e)
    }
  }

  const claimTickets = async () => {
    const rawEmail = email.trim().replace(/[^a-zA-Z0-9@._-]/g, '')
    if (!rawEmail.includes('@') || !rawEmail.includes('.') || rawEmail.length < 3) {
      alert('Invalid email')
      return
    }

    if (window.confirm(`Claim all not exchanged ticket to email ${rawEmail}?`)) {
      setClaimingtickets(true)
      const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
        method: 'POST',
        headers: {
          Authorization: `${token}`,
          'AccessTicket-App': 'manager',
        },
        body: JSON.stringify({
          query: DiceExchangeTicketsManager,
          variables: {
            fanPhoneNumber: phoneNumber,
            email: rawEmail,
          },
        }),
      })
  
      setClaimingtickets(false)
  
      if (res.status !== 200) {
        console.log('res.status', res.status)
        const { errors } = await res.json()
        console.log('errors', errors[0].message)
        return
      }
  
      try {
        const { data } = await res.json()
        if (data.diceExchangeTicketsManager?.valid) {
          alert('Tickets claimed')
          getDiceTicketFromPhone(phoneNumber)
        } else if (data && !data.diceExchangeTicketsManager) {
          alert('No tickets to claim')
        }
      } catch (e) {
        console.log('error', e)
      }
    }
  }

  return (
    <div>
      <h1 className="text-xl wght-semibold">Dice Tickets</h1>
      {role && (
        <div className="flex">
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className="wght-semibold">{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col my-4">
        <h3 className=" wght-semibold">Dice user phone number</h3>
        <p className="text-sm">country prefix + phone number, no spaces, no plus sign: 34666777888</p>
        <div className="flex flex-row">
          <input
            ref={inputPhoneNumber}
            className="p-1 border rounded focus:shadow-outline border-grayLighter"
            type="text"
            value={phoneNumber}
            placeholder="Phone Number"
            onKeyDown={(e) => handleKeyDownPhone(e)}
            onChange={(e) => {
              setPhoneNumber(e.target.value)
            }}
          />
          <Button
            size="small"
            color="primary"
            className="ml-2"
            onClick={() => {
              getDiceTicketFromPhone()
            }}
          >
            Search
          </Button>
        </div>
      </div>

      {loading && <p>Loading...</p>}

      {!loading && !!tickets.length && (
        <div className="flex flex-col my-4">
          <h3 className="mb-2 wght-semibold">List of Dice Tickets</h3>
          <table className="table-fixed">
            <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
              <tr>
                <th className="w-1/4 px-4 py-2">TicketId</th>
                <th className="w-1/4 px-4 py-2">Title</th>
                <th className="w-1/4 px-4 py-2">Start Date</th>
                <th className="w-1/4 px-4 py-2">End Date</th>
                <th className="w-1/4 px-4 py-2">Exchanged</th>
                <th className="w-1/4 px-4 py-2">Credit</th>
              </tr>
            </thead>
            <tbody>
              {tickets.map((ticket, index) => {
                return (
                  <tr
                    key={index}
                    className={`relative text-sm text-left ${
                      ticket.exchanged
                        ? 'bg-white hover:bg-grayLighter'
                        : 'bg-primary text-white '
                    } border-b border-grayLight `}
                  >
                    <td className="px-4 py-2 whitespace-nowrap">
                      {
                        ticket?.id ? 
                        <Link
                          to={`/admin/all/list/Ticket?id=${ticket?.id}&showDeleted=true`}
                          className="text-primary hover:wght-semibold"
                        >
                          {ticket?.id}
                        </Link>
                        :
                        '-'
                      }
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {ticket.title}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {ticket.startDate
                        ? dayjs
                            .unix(ticket.startDate)
                            .format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {ticket.endDate
                        ? dayjs
                            .unix(ticket.endDate)
                            .format('DD/MM/YYYY HH:mm:ss')
                        : '-'}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {ticket.exchanged ? 'YES' : '-'}
                    </td>
                    <td className="px-4 py-2 whitespace-nowrap">
                      {ticket.credit || '-'}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {claimingtickets ? (
            <p className="mt-2">Claiming...</p>
          ) : (
            <div className="flex mt-2">
              <input
                className="px-1 border rounded focus:shadow-outline border-grayLighter"
                type="text"
                value={email}
                placeholder="Email"
                onKeyDown={(e) => handleKeyDownEmail(e)}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
              <Button
                disable={!email || role === 'read'}
                size="small"
                color="primary"
                className="ml-2"
                onClick={() => {
                  claimTickets()
                }}
              >
                Claim Tickets
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default DiceList