import React from 'react'
import { Modal, CloseButton } from "./Modal"

const FieldModelModal = ({
  infoModelModal,
  showModal,
  setShowModal,
}) => {
  if (!showModal) return null

  return (
    <Modal setShowModal={setShowModal} showModal={showModal}>
      <div className="relative w-auto pb-2 overflow-auto bg-white z-1 max-h-128">
        <CloseButton setShowModal={setShowModal} />
        <div className="w-full p-8 pb-2 h-fit">
          <h2 className="text-xl wght-semibold">{infoModelModal.title}</h2>
        </div>
        <div className="w-full p-8 pt-2">
          <textarea
            className="p-4"
            disabled
            readOnly
            rows="4"
            cols="25"
            value={infoModelModal.text}
          />
        </div>
      </div>
    </Modal>
  )
}

export default FieldModelModal