import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import generateToken from '../Lib/generateToken'

const Autogenerated = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label, toUpperCase },
  validations,
  languages,
  innerForm,
  readMode,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )

  let defaultGeneratedValue = generateToken(30)
  if (toUpperCase) {
    defaultGeneratedValue = defaultGeneratedValue.toUpperCase()
  }

  const [value, setValue] = useState(
    () => storedData[name] ? storedData[name] : defaultGeneratedValue
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')


  const onChangeText = e => {
    if (translatable) {
      value[language] = `${e.target.value}`
      setValue({ ...value })
    } else {
      setValue(`${e.target.value}`)
    }
    setCurrentValue(e.target.value)
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [language, translatable])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const input = (
    <input
      readOnly={readMode}
      name={name}
      className={`w-full rounded ${
        translatable && Object.keys(languages)[0] === language
          ? 'rounded-tl-none'
          : ''
      } px-2 py-1 ${
        showErrors && errors.length > 0 ? 'border border-error' : ''
      } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
      type="text"
      placeholder={label}
      value={currentValue}
      onChange={onChangeText}
    />
  )

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {input}
        </LanguageSelector>
      ) : (
        input
      )}
      {showErrors && errors.length > 0 && 
        <p className="text-error">{errors[0]}</p>
      }
    </div>
  )
}

export default Autogenerated
