import React from 'react'
import Wildcard from './Wildcard'
import Cookies from 'js-cookie'

const Form = ({
  name,
  data,
  onChange,
  showErrors,
  mode,
  innerForm = false,
  parentIsSorting = false,
  currentData = false,
  readMode = false,
  timezone,
}) => {
  const typesWithModelsInfo = ['models', 'model', 'rules', 'ticketpermissions']
  // Get timezone from another field (tz or timezone) and avoid default select value -1. If not, get from props (parent field), or get from selected event (by cookie) or set it by default.
  const timezoneForFields = data.stored?.tz !== -1 && data.stored?.tz || data.stored?.timezone !== -1 && data.stored?.timezone
    || timezone
    || Cookies.get('eventTimezone')
    || 'Europe/Madrid'

  return (
    <div className={`${innerForm ? '' : 'p-4 bg-white'}`}>
      <div className="flex flex-wrap">
        {typeof data.models[name] !== 'undefined' &&
          data.models[name].map((item, index) => (
            <Wildcard
              mode={mode}
              onChange={onChange}
              showErrors={showErrors}
              storedData={data.stored}
              languages={data.languages}
              find={data.find}
              files={data.files}
              key={item.name + index}
              {...item}
              models={
                typesWithModelsInfo.includes(item.type) ? data.models : null
              }
              currentData={
                item.type === 'ticketpermissions' ? currentData : null
              }
              innerForm={innerForm}
              parentIsSorting={parentIsSorting}
              readMode={readMode}
              timezone={timezoneForFields}
            />
          ))}
      </div>
    </div>
  )
}

export default Form
