import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import { useSession } from '../contexts/AuthContext'
import StatsProgressBar from './StatsProgressBar'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import Button from './Button'
import { getUserPreference, updateCookiePreferences } from './Lib/utils'
import Loader from "./Loader"

const StatsProducts = ({ organizationTitle = '' }) => {
  const history = useHistory()
  const { name: modelName, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [loading, setLoading] = useState(true)
  const title = 'Stock ShopProducts'
  const [eventTitle, setEventTitle] = useState()
  const [totalTickets, setTotalTickets] = useState()
  const [totalSold, setTotalSold] = useState()
  const [products, setProducts] = useState([])
  const [productsByShop, setProductsByShop] = useState([])
  const [role, setRole] = useState(false)
  const token = useSession()
  const [showNoStock, setShowNoStock] = useState(getUserPreference({
    parentKey: 'statsProducts',
    key: 'showNoStock',
    defaultValue: false,
  }))
  const [view, setView] = useState(getUserPreference({
    parentKey: 'statsProducts',
    key: 'view',
    defaultValue: 'shops',
  }))
  const [autoRefresh, setAutoRefresh] = useState(getUserPreference({
    parentKey: 'statsProducts',
    key: 'autoRefresh',
    defaultValue: false,
  }))

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsProducts',
      key: 'autoRefresh',
      value: autoRefresh,
    })
  }, [autoRefresh])

  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        setLoading(true)
      }, 10000)
      return () => clearInterval(interval)
    }
  }, [autoRefresh])

  useEffect(() => {
    getData()
  }, [loading])

  useEffect(() => {
    setProducts([])
    getData()
  }, [showNoStock, event])

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsProducts',
      key: 'view',
      value: view,
    })
  }, [view])

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsProducts',
      key: 'showNoStock',
      value: showNoStock,
    })
  }, [showNoStock])

  const getData = () => {
    if (event === 'all') {
      setLoading(false)
      return
    }
    fetch(`${process.env.REACT_APP_API_URL}model-stats-products`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        organization: getOrganization(),
        event: getEvent(),
        showNoStock,
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        const rawData = response.body
        if (response.status === 200) {
          setRole(rawData.role)
          setEventTitle(rawData.eventTitle)
          setTotalTickets(rawData.statsEvent.total)
          setTotalSold(rawData.statsEvent.paid)
          setProducts(rawData.statsProducts)
          setProductsByShop(rawData.productsByShop)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
          if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {role && (
        <div className="flex">
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className="wght-semibold">{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      )}

      <div className='flex items-end justify-between mt-2'>
        <div className={`wght-semibold ${loading ? '' : 'opacity-0'}`}>Loading...</div>
        <div className='flex'>
          <Button
            className='mr-1'
            onClick={() => setAutoRefresh(!autoRefresh)}
            size='small'
            color={autoRefresh ? 'green' : 'white'}
          >
            Auto refresh
          </Button>
        </div>
      </div>

      {event === 'all' ? <h3 className="mt-8">Select an event</h3> :
        <>
          {totalTickets ? (
            <div className="mt-4">
              <h2 className="block mt-4 mb-2 text-lg wght-semibold">
                {event === 'all' ? `Organization: ${organizationTitle}` : `Event: ${eventTitle}`}
              </h2>

              <StatsProgressBar
                index={0}
                title="Total sold tickets"
                total={totalTickets}
                current={totalSold}
              />

              <div className="flex justify-between my-2">
                <div>
                  <input className="cursor-pointer" type='checkbox' checked={showNoStock} onChange={() => setShowNoStock(!showNoStock)} id="showNoStock" />
                  <label className="ml-2 cursor-pointer" htmlFor="showNoStock" >Show products with no limit stock</label>
                </div>
                <div className="flex my-2">
                  <Button size={'small'} onClick={() => setView('products')} className='mx-0 mr-0.5 ml-0' color={`${view === 'products' ? 'primary' : 'white'}`} >By products</Button>
                  <Button size={'small'} onClick={() => setView('shops')} className='mx-0 mr-0.5 ml-0' color={`${view === 'shops' ? 'primary' : 'white'}`} >By shops</Button>
                </div>
              </div>

              {view === 'products' && products.length && (
                <div>
                  {event !== 'all' && products.length ? products.map((product, index) => {
                    if (product && product.total) {
                      return (
                        <StatsProgressBar
                          key={product.title}
                          index={index}
                          title={product.title}
                          subtitle={product.shopTitle}
                          total={product.total}
                          current={product.paid}
                          url={`/admin/${event}/edit/ShopProduct/${product._id}`}
                        />
                      )
                    } else {
                      return (
                        <Link
                          to={`/admin/${event}/edit/ShopProduct/${product._id}`}
                          key={product.title}
                          className="block p-2 my-2 bg-white border border-white hover:border-primary"
                        >
                          <h3 className="">{product.title}:&nbsp;&nbsp;<span className='wght-semibold'>{product.paid} </span></h3>
                          <p className="text-sm text-orange">No limit</p>
                        </Link>
                      )
                    }
                  }) : false}
                </div>
              )}

              {view === 'shops' && productsByShop.length && (
                <div>
                  {event !== 'all' && productsByShop.length ? productsByShop.map((shop, index) => {
                    return (
                      <div key={`${shop._id}-${shop.title}-container`} className='mb-8' >
                        <h3 className="">
                          <Link
                            to={`/admin/${event}/edit/Shop/${shop._id}`}
                            key={`${shop._id}-${shop.title}-link`}
                            className="block p-2 my-2 border border-grayLighter bg-grayLighter hover:border-primary"
                          >{shop.title}</Link>
                        </h3>
                        {
                          shop.products.map((product, index2) => {
                            if (product && product.total) {
                              return (
                                <StatsProgressBar
                                  key={`${product._id}-${product.title}-product-${index2}`}
                                  index={`${index}-${index2}`}
                                  title={product.title}
                                  subtitle={`Product ID: ${product._id}`}
                                  total={product.total}
                                  current={product.paid}
                                  url={`/admin/${event}/edit/ShopProduct/${product._id}`}
                                />
                              )
                            } else {
                              return (
                                <Link
                                  to={`/admin/${event}/edit/ShopProduct/${product._id}`}
                                  key={`${product._id}-${product.title}-link-${index2}`}
                                  className="block p-2 my-2 bg-white border border-white hover:border-primary"
                                >
                                  <h3 className="">{product.title}:&nbsp;&nbsp;<span className='wght-semibold'>{product.paid} </span></h3>
                                  <p className="text-sm text-orange">No limit</p>
                                </Link>
                              )
                            }
                          })
                        }
                      </div>
                    )
                  }) : false}
                </div>
              )}
            </div>
          ) : (
            <Loader loading={loading} className="mt-8" text="">
              <h3 className="mt-8">No stock for this event</h3>
            </Loader>
          )}
        </>
      }

    </div >
  )
}

export default StatsProducts
