import React, { useState, useEffect } from 'react'
import Button from './Button'
import Webcam from "react-webcam";
import { useSession } from '../contexts/AuthContext'
import uploadUserImage from './Lib/uploadUserImage'

const ModalCaptureImage = ({ show, showState, ticketId, hasBeenUsed = false }) => {
  const token = useSession()
  const webcamRef = React.useRef(null)
  const modalRef = React.useRef(null)
  const [imgWebcam, setImgWebcam] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [fetchErrors, setFetchErrors] = useState()
  const [videoInputs, setVideoInputs] = useState([])
  const [selectedVideoInput, setSelectedVideoInput] = useState('')
  const [videoConstraints, setVideoConstraints] = useState({
    width: 300,
    height: 300,
    facingMode: "user"
  })


  const closeModal = () => {
    showState(false)
  }

  const handleCapture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot()
    setImgWebcam(imageSrc)
  }, [webcamRef, setImgWebcam])

  const deleteImage = () => {
    setImgWebcam(null)
  }

  const saveImage = async () => {
    setIsLoading(true)
    const result = await uploadUserImage({
      ticketId,
      token,
      image: imgWebcam,
      imageType: 'base64',
    })

    setIsLoading(false)
    if (!result.valid) {
      setFetchErrors(result.error)
    } else {
      showState(false)
      window.location.reload()
    }
  }

  useEffect(() => {
    if (selectedVideoInput.length > 0) {
      setVideoConstraints(oldVideoConstraints => ({
        ...oldVideoConstraints,
        deviceId: selectedVideoInput
      }))
    }
  }, [selectedVideoInput])


  useEffect(() => {
    (async () => {
      try {
        if (navigator) {
          navigator.getUserMedia = navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia;

          const captureStream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } })

          if (captureStream.active) {
            const inputs = await navigator.mediaDevices.enumerateDevices()
            const videoInputs = inputs.filter(device => device.kind === 'videoinput' && device.deviceId)

            if (videoInputs.length > 0) {
              setVideoInputs(videoInputs)
              setSelectedVideoInput(videoInputs[0].deviceId)
            }
          }
        }
      } catch (err) {
        console.log('🚀 ~ file: ModalCaptureImage.js:105 ~ err:', err)
        setFetchErrors("Allow cameras not found")
      }
    })()
  }, [])

  const handleSelectVideoInput = async (e) => {
    const deviceId = e.target.value
    try {
      const captureStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: deviceId } })
      if (captureStream.active) setSelectedVideoInput(deviceId)
    } catch (err) {
      const videoInputsUpdated = videoInputs.filter(videoInput => videoInput.deviceId !== deviceId)
      setVideoInputs(videoInputsUpdated)
    }
  }

  if (!show) return false

  return (
    <div className="fixed inset-0 z-40 flex items-center justify-center p-4 pt-0" style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
      <div className='absolute inset-0' onClick={closeModal}></div>
      {ticketId ?
        <div className="relative z-50 flex flex-col justify-center p-0 bg-white border rounded border-gray" ref={modalRef}>
          <div className='flex items-center justify-end'>
            <div onClick={closeModal} className='p-2 cursor-pointer'>×</div>
          </div>
          <div className='px-4 pb-4'>
            {hasBeenUsed &&
              <div className='flex justify-between p-2 mt-2 mb-2 text-white rounded bg-warning'>
                <p className='m-2'>This ticket has already accessed the event.</p>
              </div>
            }

            <div className="flex items-center justify-center ">
              <div
                className="relative rounded"
                style={{ height: `${videoConstraints.height}px`, width: `${videoConstraints.width}px` }}
              >
                {!imgWebcam && <div
                  className='absolute flex items-center justify-center left-0 right-0 z-0 text-center w-full h-full'
                >
                  <p>Loading camera...</p>
                </div>
                }

                {(!imgWebcam && selectedVideoInput) &&
                  <Webcam
                    videoConstraints={videoConstraints}
                    audio={false}
                    screenshotQuality={0.6}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    className='relative z-10 m-auto rounded cursor-pointer'
                    onClick={handleCapture}
                  />
                }

                {imgWebcam && (
                  <img src={imgWebcam} className="relative z-10 m-auto rounded" />
                )}
              </div>
            </div>

            <div className='flex w-full'>
              {
                !imgWebcam && videoInputs.length > 0 &&
                <div className='inline-flex flex-col w-1/2 mt-2'>
                  <span className="text-xs">Select camera</span>
                  <select
                    name="cameras"
                    type="text"
                    value={selectedVideoInput}
                    onChange={handleSelectVideoInput}
                    className="bg-grayLight rounded outline-none placeholder-gray py-1 mb-1 text-xs"
                  >
                    {videoInputs.map(input => (
                      <option key={input.deviceId} className='h-8 text-black' value={input.deviceId}>{input.label}</option>
                    ))}
                  </select>
                </div>
              }

              {!fetchErrors &&
                <>
                  {!imgWebcam && videoInputs.length > 0 ?
                    <div className='flex justify-end items-end w-1/2 mb-1 mt-2'>
                      <Button onClick={handleCapture} size="small">Capture</Button>
                    </div>
                    :
                    <div className='flex w-full items-end justify-end mb-1 mt-6'>
                      {!isLoading && <Button onClick={deleteImage} color="error" size="small">Delete</Button>}
                      {!isLoading
                        ? <Button className='ml-1' onClick={saveImage} size="small">Upload Selfie</Button>
                        : <Button className='ml-1' size="small" disabled>Uploading...</Button>
                      }
                    </div>
                  }
                </>
              }
            </div>

            {fetchErrors &&
              <div className='w-full mb-1 mt-2'>
                <p className='text-error wght-semibold'>{fetchErrors}</p>
              </div>
            }
          </div>
        </div>
        :
        <div className="relative z-50 flex flex-col justify-center h-32 p-4 text-white border rounded bg-gray border-gray" ref={modalRef}>
          <p>For new tickets, you have to save it before upload an image</p>
        </div>
      }
    </div>
  )
}

export default ModalCaptureImage
