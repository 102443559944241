import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import defaultValue from '../Lib/defaultValue'
import Revolvapp from '../Lib/revolvapp/revolvapp.js'
import '../Lib/revolvapp/plugins/reorder/reorder.js'
import '../Lib/revolvapp/plugins/code/code.js'
import '../Lib/revolvapp/plugins/variable/variable.js'
import defaultTemplates from '../Lib/revolvapp/templates/default.js'

const EmailHtml = ({
  storedData,
  onChange,
  name,
  translatable,
  options: { label, readOnly = false },
  languages,
}) => {
  const [language, setLanguage] = useState(translatable ? Object.keys(languages)[0] : null)
  const [value, setValue] = useState(defaultValue(name, storedData, translatable))
  const [editorApp, setEditorApp] = useState()
  const variables = [
    'name',
    'email',
    'event',
    'ticketType',
    'date',
    'ticketId',
    'publicId',
  ]

  const updateData = (that) => {
    const rawValue = value || {}
    if (!rawValue.html) rawValue.html = {}
    if (!rawValue.template) rawValue.template = {}
    if (translatable) {
      rawValue.template[language] = that.app.editor.getTemplate()
      rawValue.html[language] = that.app.editor.getHtml()
    } else {
      rawValue.template = that.app.editor.getTemplate()
      rawValue.html = that.app.editor.getHtml()
    }
    setValue({ ...rawValue })
  }

  const getSettings = () => {
    const initialValue = translatable ? value?.template && value.template[language] : value?.template
    return {
      content: initialValue || defaultTemplates[language] || defaultTemplates['en'],
      editor: {
        font: '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
        viewOnly: readOnly,
        path: '/revolvapp/',
      },
      plugins: ['reorder', 'code', 'variable'],
      variable: {
        items: variables,
        template: {
          start: '{{',
          end: '}}',
        }
      },
      subscribe: {
        'editor.load': function() {
          updateData(this)
        },
        'editor.change': function() {
          updateData(this)
        },
        'source.change': function() {
          updateData(this)
        },
      }
    }
  }

  useEffect(() => {
    setEditorApp(Revolvapp('#email-editor', getSettings()))
  }, [])

  useEffect(() => {
    setEditorApp(Revolvapp('#email-editor', getSettings()))
  }, [storedData.useAlternativeContent])

  useEffect(() => {
    if (translatable && editorApp) {
      editorApp.stop()
      editorApp.start(getSettings())
      const element = document.getElementById('wrapper-email-editor')
      setTimeout(() => {
        element.scrollIntoView({ block: 'start' })
      }, 100);
    }
  }, [language, translatable])

  useEffect(() => {
    onChange(name, value, [])
  }, [value])


  const [showLabel, setShowLabel] = useState(false)
  const copyToClipboard = (value) => {
    setShowLabel(false)
    const el = document.createElement('textarea')
    el.value = value
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    setTimeout(() => {
      setShowLabel(true)
      setTimeout(() => {
        setShowLabel(false)
      }, 2000);
    }, 0);
  }

  if (storedData.useAlternativeContent === 1 || storedData.useAlternativeContent === true) {
    return <></>
  }

  return (
    <div id="wrapper-email-editor">
      <div className='flex justify-between'>
        <label>{label}</label>
        <div className='text-xs'>
          <a className='text-primary hover:wght-semibold' target='_blank' href="https://imperavi.com/revolvapp/docs/syntax/quick-start/">Template Syntax</a> | <a className='text-primary hover:wght-semibold' target='_blank' href="https://imperavi.com/revolvapp/docs/syntax/tags/">Tags documentation</a>
        </div>
      </div>
      <p className='text-xs' >Variables: {variables.map(i => (
        <span key={i} onClick={() => copyToClipboard(`{{${i}}}`) }  className='mr-1 font-mono bg-gray-200 rounded-sm cursor-pointer hover:text-primary'>{`{{${i}}}`}</span>
      ))} { showLabel && <span className='text-success wght-semibold'>Copied to clipboard!</span> } </p>
      {translatable && (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={(v) => setLanguage(v)}
        />
      )}
      <div id="email-editor"></div>
    </div>
  )
}

export default EmailHtml
