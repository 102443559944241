
import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import Form from './Form/Form'
import flatten from './Lib/flatten'
import Select from './Form/Select'
import { useSession } from '../contexts/AuthContext'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import Button from './Button'

const modelsWithoutList = []

const EditUser = ({ setUpdateEvents, setUpdateOrganizations, organization, events }) => {
  const history = useHistory()
  const { id: urlId, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const modelName = 'PttUser'
  const [id, setId] = useState(urlId)
  const [data, setData] = useState(false)
  const [originalData, setOriginalData] = useState(false)
  const [name, setName] = useState(modelName)
  const [title, setTitle] = useState(name)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [notUniqueId, setNotUniqueId] = useState(false)
  const [permissions, setPermissions] = useState([])
  const [role, setRole] = useState(false)
  const token = useSession()


  const updateStored = (key, value, errs = []) => {
    setNotUniqueId()

    if (!data.stored) {
      data.stored = {}
    }

    if (typeof data.stored.modelName === 'undefined') {
      data.stored.modelName = name
    }

    data.stored[key] = key === 'email' ? value.trim() : value
    errors[key] = errs
    setData({ ...data })
    setErrors({ ...errors })
  }

  useEffect(() => {
    setData(false)
    setOriginalData(false)
    setName(modelName)

    fetch(`${process.env.REACT_APP_API_URL}model-edit`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        organization: getOrganization(),
        event: getEvent(),
        _id: id || undefined,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setTitle(`${id ? 'Edit' : 'Create'} ${response.body.modelConfig.title}`)
          setData(response.body)
          if (response.body?.stored) {
            setOriginalData(JSON.parse(JSON.stringify(response.body?.stored)))
          }
          if (response.body.stored.permissions) {
            setRole(response.body.role)
            setPermissions(response.body.stored.permissions)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/list/${modelName}`);
          }
        }
      })
      .catch((err) => console.log(err))
  }, [modelName, id])

  const orderModels = function (data) {
    Object.keys(data).forEach(key => {
      const staticElement = data[key]
      if (Array.isArray(staticElement)) {
        staticElement.forEach((jsonElement, key2) => {
          if (jsonElement['items']) {
            data[key][key2]['items'] = jsonElement['items'].sort((a, b) => a.position - b.position)
          }
        });
      }
    })
  }

  const checkIfTicketIdIsUnique = async (data, ticketModel) => {
    if (!ticketModel) return false
    const bodyData = { ...data }

    let isUnique = false
    await fetch(`${process.env.REACT_APP_API_URL}ticket-unique`, {
      body: JSON.stringify(bodyData),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.body.unique) {
          isUnique = true
        }
      })
      .catch((err) => console.log(err))
    return isUnique
  }

  const save = async (type = 'default', callback = false) => {
    const errs = flatten(errors)
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setSaving(true)
      orderModels(data.stored)

      const bodyData = {
        ...data.stored,
        modelName,
        filters: {
          organization: getOrganization(),
          event: getEvent(),
        },
        originalData,
      }

      const ticketModel = (modelName === 'Ticket')
      const uniqueTicketId = await checkIfTicketIdIsUnique(data.stored, ticketModel)
      if (!ticketModel || uniqueTicketId) {
        fetch(`${process.env.REACT_APP_API_URL}model-update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        })
          .then(response => response
            .json()
            .then(data => ({ status: response.status, body: data })))
          .then(response => {
            setSaving(false)
            if (callback) callback(response)
            if (response.status === 200) {
              if (data.stored) {
                setOriginalData(JSON.parse(JSON.stringify(data.stored)))
              }
              if (modelName === 'Event') {
                setUpdateEvents(true)
              }
              if (modelName === 'Organization') {
                setUpdateOrganizations(true)
              }
              if (type === 'default') {
                if (typeof id === 'undefined') {
                  setId(response.body._id)
                  history.push(`/admin/${event}/edit/${modelName}/${response.body._id}`)
                }
                setSaved(true)
                setTimeout(() => setSaved(false), 3000)
                window.location.reload()
                return
              } else if (type === 'save-and-another') {
                setId(null)
                history.push(`/admin/${event}/edit/${modelName}`)
                history.go(0)
                return
              } else if (type === 'save-and-close') {
                if (modelsWithoutList.includes(modelName)) {
                  history.push(`/admin/${event}/edit/${modelName}`)
                } else {
                  history.push(`/admin/${event}/list/${modelName}`)
                }
              }
            } else {
              console.log('Error', response.status, response.body.error)
              if (response.status === 403) {
                Cookies.remove('user')
                history.push('/login')
              } else if (response.status === 401) {
                history.push(`/admin/no-access?url=${window.location.pathname}`)
              } else if (response.body.error === 'email_not_available') {
                setErrors({ ...errors, email: 'Email not available' })
                setShowErrors(true)
              }
            }
          })
          .catch(() => setSaving(false))
      } else {
        setSaving(false)
        if (!uniqueTicketId) {
          setNotUniqueId(true)
        }
      }
    }
  }

  const handlePermissions = (type, id, value, relatedEvent) => {
    const rawPermissions = [...permissions]

    if (type === 'zone') {
      // find permission with the same id and same relatedEvent, and change role with the new value
      const index = rawPermissions.findIndex(permission => permission.id === id && permission.relatedEvent?.toString() === relatedEvent?.toString())
      if (index !== -1) {
        rawPermissions[index].role = value
      }
    } else {
      const index = rawPermissions.findIndex(permission => permission.id?.toString() === id?.toString())
      if (index !== -1) {
        rawPermissions[index].role = value
      }
    }


    setPermissions(rawPermissions)
  }

  const removePermission = (type, id, relatedEvent) => {
    if (!window.confirm(`⚠️ Are you sure you want to delete this ${type.toUpperCase()} permission? ${type === 'zone' ? '' : 'All related permissions will be removed.'} ⚠️`)) return

    if (type === 'zone') {
      const index = permissions.findIndex(permission => permission.id === id && permission.relatedEvent?.toString() === relatedEvent?.toString())
      if (index !== -1) {
        const rawPermissions = [...permissions]
        rawPermissions.splice(index, 1)
        setPermissions([...rawPermissions])
      }
    } else if (type === 'event') {
      const rawPermissions = permissions.filter(permission => (
        !(permission.type === 'event' && permission.id?.toString() === id?.toString()) &&
        !(permission.type === 'zone' && permission.relatedEvent?.toString() === id?.toString())
      ))
      setPermissions([...rawPermissions])
    } else if (type === 'organization') {
      const rawPermissions = permissions.filter(permission => (
        !(permission.type === 'event' && events.find(e => e.value.toString() === permission.id.toString())) &&
        !(permission.type === 'zone' && events.find(e => e.value.toString() === permission.relatedEvent.toString())) &&
        !(permission.type === 'organization' && permission.id.toString() === organization.value.toString())
      ))
      setPermissions([...rawPermissions])
    }
  }

  useEffect(() => {
    if (data) {
      updateStored('permissions', permissions)
    }
  }, [permissions])

  const remove = () => {
    if (window.confirm('Recover user?')) {
      updateStored('deleted', !data.stored.deleted)
      setTimeout(() => {
        save()
      }, 200);
    }
  }

  return (
    <div>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
        <Link
          to={`/admin/${event}/list/${modelName}`}
          className="cursor-pointer hover:wght-semibold hover:text-primary"
        >
          Cancel
        </Link>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      {!data && <div className="mt-8 mb-8">Loading...</div>}

      {data && data.stored && data.stored.deleted &&
        <div>
          <div className='flex items-center justify-between p-2 my-4 text-base border rounded bg-grayLight border-error text-error'>
            <span>This user is deleted.</span>
            <Button onClick={remove} disabled={saving} className='mx-0 mr-0.5 ml-0' color='success'>Recover user</Button>
          </div>
        </div>
      }

      {data && modelName === name && (
        <div className="mt-8">
          <Form
            onChange={updateStored}
            data={data}
            name={name}
            showErrors={showErrors}
            mode={id ? 'edit' : 'create'}
            currentData={data}
            readMode={!!data.stored.deleted}
          />

          <div className='w-full mt-4 md:w-full'>
            <div className="flex mb-2">
              <h3 className="text-lg wght-semibold">Permissions</h3>
            </div>
            <div className='p-2 mb-2 bg-black'>
              {!permissions.find(perm => organization && perm.type === 'organization' && perm.id.toString() === organization.value.toString()) && <>
                <p className='text-white'>User has no permission for this organization.</p>
              </>}
              {permissions.map((perm, index) => {
                if (organization && perm.type === 'organization' && perm.id.toString() === organization.value.toString()) {
                  return (
                    <div className='p-4 pb-0 mb-2' key={`${perm.id}-${index}`}>
                      <div className='flex flex-wrap items-center justify-between '>
                        <div className='w-full mb-2 md:pr-4 md:mb-4'>
                          <p className='text-white'>Organization: <strong>{organization.label}</strong></p>
                          <div className='flex'>
                            <span className='w-1/2'>
                              <Select
                                key={index}
                                readMode={data.stored.deleted}
                                name='role'
                                storedData={perm}
                                options={{
                                  type: 'static',
                                  options: {
                                    'admin': 'Admin',
                                    'write': 'Write',
                                    'read': 'Read',
                                  },
                                }}
                                onChange={(name, value, errs) => handlePermissions('organization', perm.id, value)}
                              />
                            </span>
                            <button
                              onClick={() => removePermission('organization')}
                              disabled={data.stored.deleted}
                              className="px-2 py-1 ml-2 text-xs border rounded cursor-pointer text-error border-error hover:wght-semibold"
                            >
                              x
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return null
                }
              })}
              {permissions.map((perm, index) =>
                <div key={index}>
                  {perm && perm.type === 'event' && events.find(e => e.value.toString() === perm.id.toString()) && (
                    <div className='p-4 pb-0 mb-2 bg-white border-b border-grayLight' key={`${perm.id}`}>
                      <div className='flex flex-wrap items-center justify-between '>
                        <div className='w-full mb-2 md:pr-4 md:mb-4'>
                          <p>Event: <strong>{events.find(e => e.value.toString() === perm.id.toString()).label}</strong></p>
                          <div className='flex'>
                            <span className='w-1/2'>
                              <Select
                                key={index}
                                readMode={data.stored.deleted}
                                name='role'
                                label='Role'
                                storedData={perm}
                                onChange={(name, value, errs) => handlePermissions('event', perm.id, value)}
                                options={{
                                  type: 'static',
                                  options: {
                                    'admin': 'Admin',
                                    'write': 'Write',
                                    'read': 'Read',
                                  },
                                }}
                              />
                            </span>
                            <button
                              onClick={() => removePermission('event', perm.id)}
                              disabled={data.stored.deleted}
                              className="px-2 py-1 ml-2 text-xs border rounded cursor-pointer text-error border-error hover:wght-semibold"
                            >
                              x
                            </button>
                          </div>
                        </div>

                        <div className='flex justify-end w-full'>

                        </div>
                      </div>

                      {permissions.map((perm2, index2) => <>
                        {perm2 && perm2.type === 'zone'
                          && perm2.relatedEvent.toString() === perm.id.toString()
                          && (
                            <div className='flex flex-wrap items-center justify-between p-2 mb-4 border rounded bg-grayLighter border-gray' key={`${perm2.id}`}>
                              <div className='w-full mb-4 md:pr-4'>
                                <p>Zone: <strong>{perm2.id}</strong></p>
                                <div className='flex'>
                                  <span className='w-1/2'>
                                    <Select
                                      key={index}
                                      readMode={data.stored.deleted}
                                      name='role'
                                      label='Role'
                                      storedData={perm2}
                                      onChange={(name, value, errs) => handlePermissions('zone', perm2.id, value, perm2.relatedEvent)}
                                      options={{
                                        type: 'static',
                                        options: {
                                          'admin': 'Admin',
                                          'write': 'Write',
                                          'read': 'Read',
                                        },
                                      }}
                                    />
                                  </span>
                                  <button
                                    onClick={() => removePermission('zone', perm2.id, perm2.relatedEvent)}
                                    disabled={data.stored.deleted}
                                    className="px-2 py-1 ml-2 text-xs border rounded cursor-pointer text-error border-error hover:wght-semibold"
                                  >
                                    x
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                      </>)}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {!data?.stored?.deleted &&
            <div className="sticky bottom-0 z-30 flex justify-between p-4 text-xs bg-white border-t border-grayLight">
              <div>
                {saving ? (
                  <span className="text-black wght-bold">Saving...</span>
                ) : (
                  <span
                    onClick={() => save()}
                    className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                  >
                    Save &#8599;{' '}
                  </span>
                )}
                {saved && <span className="ml-4 text-black">Saved successfully!</span>}
                {errors && showErrors && <span className="ml-4 text-red">Error saving: {
                  Object.keys(errors).map((err, index) => {
                    if (errors[err]) {
                      return errors[err]
                    }
                  }
                  )
                }</span>}
                {modelName === 'Ticket' && notUniqueId && <span className="pl-2 text-red">Ticket ID must be unique</span>}
              </div>
              <div>
                {saving ? null : (
                  <span
                    onClick={() => save('save-and-another')}
                    className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                  >
                    Save &amp; create &#8599;{' '}
                  </span>
                )}
              </div>
              <div>
                {saving ? null : (
                  <span
                    onClick={() => save('save-and-close')}
                    className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                  >
                    Save &amp; close &#8599;
                  </span>
                )}
              </div>
            </div>}
        </div>
      )}
    </div>
  )
}

export default EditUser
