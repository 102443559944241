import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import InvitationModal from './InvitationModal'
import Button from './Button'
import { useSession } from '../contexts/AuthContext'
import sendMail from './Lib/sendMail'
import HeaderStats from './HeaderStats'
import { redirectGuestsURL, getModelName } from './Lib/guestsUtils'
import { getUserPreference, updateCookiePreferences, getUpdatedColumns } from './Lib/utils'
import GuestsInvitationsImport from './GuestsInvitationsImport'
import GuestsInvitationsExport from './GuestsInvitationsExport'
import Pagination from './Pagination'
import FieldModelModal from './FieldModelModal'
import EditMultipleModal from './EditMultipleModal'
import debounce from 'lodash/debounce'
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const ListGuests = ({
  setSelectedMenuEvent,
  selectedMenuEvent,
  menuEventsOptions,
}) => {
  const history = useHistory()
  let { GuestsEvent, GuestsCategory, GuestsEntity, GuestsQuota, GuestsInvitation } = useParams()
  const pageParamNumber = [GuestsEvent, GuestsCategory, GuestsEntity, GuestsQuota, GuestsInvitation].find((param) => !isNaN(param) && Number.isInteger(parseInt(param))) || 1

  const { initialModelName, initialTitle } = getModelName(useParams())
  const [title, setTitle] = useState(initialTitle)
  const [guestsView, setGuestsView] = useState(getUserPreference({
    parentKey: 'guestsMainList',
    key: 'view',
    defaultValue: 'categories',
  }))
  let initialModelNameForMainList = initialModelName
  if (initialModelName === 'GuestsCategory' && guestsView === 'categories') {
    initialModelNameForMainList = 'GuestsCategory'
  } else if (initialModelName === 'GuestsCategory' && guestsView === 'invitations') {
    initialModelNameForMainList = 'GuestsInvitation'
  }
  const [modelName, setModelName] = useState(initialModelNameForMainList)
  const debounced = useRef(debounce((fn, value) => fn(value), 300))

  const [data, setData] = useState(false)
  const [page, setPage] = useState(pageParamNumber ? parseInt(pageParamNumber) - 1 : 0)
  const [hasMorePages, setHasMorePages] = useState(false)
  const [pageLimit, setPageLimit] = useState(50)
  const [loading, setLoading] = useState(false)
  const [loadingStats, setLoadingStats] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [role, setRole] = useState(false)
  const [breadcrumb, setBreadcrumb] = useState([])
  const [headerStats, setHeaderStats] = useState({})
  const [selectedItems, setSelectedItems] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [showModelModal, setShowModelModal] = useState(false)
  const [infoModelModal, setInfoModelModal] = useState({ title: "", text: "" })
  const token = useSession()
  const [filters, setFilters] = useState(useParams() || {})
  const [quotaReached, setQuotaReached] = useState(false)
  const [itemsToSend, setItemsToSend] = useState([])
  const [exportFieldsSelected, setExportFieldsSelected] = useState([])
  const [showExportModal, setShowExportModal] = useState(false)

  const [uploading, setUploading] = useState(false)
  const [showImportModal, setShowImportModal] = useState(false)

  const [columnsData, setColumnsData] = useState([])
  const [showColumnsFilters, setShowColumnsFilters] = useState(false)
  const [visibleColumns, setVisibleColumns] = useState([])

  const [showSortOptions, setShowSortOptions] = useState(false)
  const [pageSortDirection, setPageSortDirection] = useState(1)
  // NOTE: sorting by mailstatus by default
  const [pageSortField, setPageSortField] = useState('mailstatus')
  const [userCustomSort, setUserCustomSort] = useState()
  const [selectedItemIsDeleted, setSelectedItemIsDeleted] = useState({ name: "", deleted: false })
  const [invitationInputValue, setInvitationInputValue] = useState("")

  const [firstLoad, setFirstLoad] = useState(true)

  const timezone = data?.modelConfig?.timezone ?? 'Europe/Madrid'

  const userPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))

  let getStatsActive

  const getParsedPathname = useCallback(
    () => {
      if (page > 0) {
        const pathname = history.location.pathname
        const pathnameWithoutPage = pathname.substring(0, pathname.lastIndexOf('/'));
        return pathnameWithoutPage
      }

      return history.location.pathname
    },
    [page, history.location.pathname],
  )

  useEffect(() => {
    setFilters({ GuestsEvent, GuestsCategory, GuestsEntity, GuestsQuota, GuestsInvitation })
  }, [GuestsEvent, GuestsCategory, GuestsEntity, GuestsQuota, GuestsInvitation])

  useEffect(() => {
    if (!loading && selectedMenuEvent) {
      getData()
    }

    if (modelName) {
      setVisibleColumns(userPreferences?.[modelName]?.visibleColumns || [])
    }
  }, [filters, selectedMenuEvent, modelName])

  useEffect(() => {
    setPage(pageParamNumber ? parseInt(pageParamNumber) - 1 : 0)
  }, [pageParamNumber])

  useEffect(() => {
    if ((page + 1) !== parseInt(pageParamNumber)) {
      const pathname = history.location.pathname
      if (page === 0) {
        history.push(`${pathname.replace(`/${pageParamNumber}`, '')}`)
      } else if (pageParamNumber === 1 && page === 1) {
        const hasSlashOnEnd = pathname[pathname.length - 1] === '/'
        history.push(`${pathname}${!hasSlashOnEnd ? '/' : ''}${page + 1}`)
      } else {
        const newPageNumber = page + 1
        const newPathname = pathname.replace(new RegExp(`/${pageParamNumber}$`), `/${newPageNumber}`)
        history.push(newPathname)
      }     
    }
  }, [page])

  useEffect(() => {
    let rawModelName = initialModelName
    if (rawModelName === 'GuestsCategory' && guestsView === 'categories') {
      rawModelName = 'GuestsCategory'
      setTitle('Guests - Entity Categories')
    } else if (rawModelName === 'GuestsCategory' && guestsView === 'invitations') {
      rawModelName = 'GuestsInvitation'
      setTitle('Guests - All Invitations')
    }

    if (rawModelName !== modelName) {
      setModelName(rawModelName)
    }

    updateCookiePreferences({
      parentKey: 'guestsMainList',
      key: 'view',
      value: guestsView,
    })
  }, [initialModelName, guestsView])

  const updateBreadcrumb = () => {
    const pathname = getParsedPathname()
    let rawbreadcrumb = pathname?.split('/admin/guests/')[1]?.split('/') || []
    let basepath = '/admin/guests'
    rawbreadcrumb = rawbreadcrumb.map((item) => {
      const path = `${basepath}/${item}`
      basepath = path
      return {
        title: item.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
        path,
      }
    })
    setBreadcrumb(rawbreadcrumb)
  }

  useEffect(() => {
    setShowModal(false)
    updateBreadcrumb()
  }, [history.location.pathname, page])

  useEffect(() => {
    if (
      GuestsEvent &&
      menuEventsOptions.length
    ) {
      redirectGuestsURL({
        GuestsEvent,
        menuEventsOptions,
        setSelectedMenuEvent,
        getData,
        endpoint: 'model-list',
        token,
        modelName,
        history,
      })
    }
  }, [menuEventsOptions])

  useEffect(() => {

    if (!(modelName === 'GuestsInvitation' && !GuestsQuota)) {
      const { initialTitle } = getModelName({
        GuestsEvent,
        GuestsCategory,
        GuestsEntity,
        GuestsQuota,
        GuestsInvitation,
      })
      setTitle(initialTitle)
    }
  }, [modelName])

  useEffect(() => {
    setItemsToSend([])
    setSelectedItems([])
  }, [data])

  const getData = async (removeData) => {
    setLoading(true)
    if (!removeData) setData(false)

    filters['organization'] = Cookies.get('organization')
    filters['event'] = GuestsEvent || Cookies.get('event') || selectedMenuEvent?.value

    if (!GuestsEntity && filters.GuestsCategory === pageParamNumber) {
      filters.GuestsCategory = false
    } else if (!GuestsQuota && filters.GuestsEntity === pageParamNumber) {
      filters.GuestsEntity = false
    } else if (!GuestsInvitation && filters.GuestsQuota === pageParamNumber) {
      filters.GuestsQuota = false
    }

    let rawUserPreferences = userPreferences
    if (!rawUserPreferences?.length) {
      rawUserPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))
    }

    let pageSort = false
    if (pageSortField) {
      pageSort = {
        [pageSortField]: pageSortDirection,
      }
    } else if (firstLoad && rawUserPreferences?.[modelName]?.pageSortField) {
      pageSort = {
        [rawUserPreferences[modelName].pageSortField]:
          rawUserPreferences[modelName].pageSortDirection,
      }
      setPageSortField(rawUserPreferences[modelName].pageSortField)
      setPageSortDirection(
        rawUserPreferences[modelName].pageSortDirection
      )
    }

    await fetch(`${process.env.REACT_APP_API_URL}model-list-guests`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userManagerPreferences: filters,
        modelName,
        filters: filters,
        page,
        pageLimit,
        url: getParsedPathname(),
        pageSort,
        searchValue: invitationInputValue,
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)

          const rawBody = { ...response.body }

          setColumnsData(rawBody.modelConfig.list)
          if (!userPreferences?.[modelName]?.visibleColumns?.length) {
            setVisibleColumns(Object.keys(rawBody.modelConfig.list))
          }

          Object.keys(rawBody.modelConfig.list).map((key) => {
            if (typeof rawBody.modelConfig.list[key] === 'object') {
              rawBody.modelConfig.list[key] =
                rawBody.modelConfig.list[key].label
            }
          })
          setData(rawBody)

          if (rawBody.modelConfig.pageLimit) {
            setPageLimit(rawBody.modelConfig.pageLimit)
          }

          if (!pageSortField && (!rawUserPreferences?.[modelName]?.pageSortField || !firstLoad)) {
            setPageSortField()
            setPageSortDirection(
              Object.values(rawBody.modelConfig.sort)[0] === 'asc' ? -1 : 1
            )

            setUserCustomSort(false)
            updateCookiePreferences({ parentKey: modelName, key: 'pageSortField', value: pageSortField, remove: true })
            updateCookiePreferences({ parentKey: modelName, key: 'pageSortDirection', value: pageSortDirection, remove: true })
          } else {
            setUserCustomSort(true)
            updateCookiePreferences({ parentKey: modelName, key: 'pageSortField', value: pageSortField })
            updateCookiePreferences({ parentKey: modelName, key: 'pageSortDirection', value: pageSortDirection })
          }

          if (response.body.pageLimit) {
            setHasMorePages(
              response.body.items.length === response.body.pageLimit
            )
          } else {
            setHasMorePages(response.body.items.length === pageLimit)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          switch (response.status) {
            case 400:
              if (response.body.error === "guests_entity_invitation") alert("Cannot delete an entity with invitations")
              break;
            case 403:
              Cookies.remove('user')
              history.push('/login')
              break;
            case 401:
              history.push(`/admin/no-access?url=${window.location.pathname}`)
              break;
          }
        }
        setFirstLoad(false)
      })

    if (!getStatsActive && !loadingStats) {
      getStatsActive = true
      setLoadingStats(true)
      fetch(`${process.env.REACT_APP_API_URL}model-list-guests-stats`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userManagerPreferences: filters,
          modelName,
          filters: filters,
          page,
          pageLimit,
          url: getParsedPathname(),
          pageSort,
          searchValue: invitationInputValue,
        }),
      })
        .then((response) =>
          response
            .json()
            .then((data) => ({ status: response.status, body: data }))
        )
        .then((response) => {
          if (response.status === 200) {
            const headerStatsResponse = response.body.headerStats
            setHeaderStats(headerStatsResponse)

            if (headerStatsResponse) {
              for (const key of Object.keys(headerStatsResponse)) {
                if (headerStatsResponse[key]?.deleted) {
                  setSelectedItemIsDeleted({
                    name: key,
                    deleted: headerStatsResponse[key]?.deleted,
                  })
                }
              }
            }

            if (modelName === 'GuestsInvitation') {
              const stats = response.body.headerStats
              const quotaData = stats && Object.values(stats).find(stat => stat.modelName === 'GuestsQuota')
              setQuotaReached(quotaData && quotaData.quotasAvailables > 0 ? false : true)
            } else {
              setQuotaReached(false)
            }
          } else {
            console.log('Error Stats', response.status, response.body.error)
          }
          getStatsActive = false
          setLoadingStats(false)
        })
      }
  }

  useEffect(() => {
    if (!firstLoad) {
      setSelectedItems([])
      getData()
      if (!pageSortField) {
        setFirstLoad(true)
      }
    }
  }, [pageSortDirection, pageSortField])

  const handleShowModal = () => setShowModal((state) => !state)

  const goTo = (item) => {
    history.push(`${getParsedPathname()}/${item.slug}`)
  }

  const remove = async (id) => {
    const hasMultipleItems = selectedItems?.length > 0
    const deleteMessage = hasMultipleItems? `You are about to delete ${selectedItems?.length} invitations. Are you sure?` : 'Delete?'
    if (window.confirm(deleteMessage)) {
      setLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}model-delete`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName,
          _id: hasMultipleItems ? selectedItems.map(item => item._id) : id,
          organization: Cookies.get('organization'),
          event: GuestsEvent || Cookies.get('event'),
          url: getParsedPathname(),
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(async (response) => {
          if (response.status === 200) {
            if (modelName === 'GuestsInvitation' && quotaReached === true) {
              setQuotaReached(false)
            }
            await getData(true)
          } else {
            console.log('Error', response.status, response.body.error)
            switch (response.status) {
              case 400:
                alert(response.body.error)
                break;
              case 403:
                Cookies.remove('user')
                history.push('/login')
                break;
              case 401:
                history.push(`/admin/no-access?url=${window.location.pathname}`)
                break;
            }
          }
        }).finally(() => setLoading(false))
    }
  }

  const Actions = ({ id, modelName, role }) => {
    if (role !== 'admin' && modelName !== 'GuestsInvitation') return null
    return (
      <div className={`relative flex items-center justify-center text-xs ${modelName === "GuestsEntity" ? "pr-4" : ""}`}>
        <Link className="mr-4 cursor-pointer hover:text-primary hover:wght-semibold" to={{ pathname: `${getParsedPathname()}/edit/${modelName}/${id}`, state: { page } }}>View</Link>
        <span className="block cursor-pointer hover:text-primary hover:wght-semibold" onClick={() => remove(id)}>Delete</span>
      </div>
    )
  }

  const handleSelectedItem = (selectedItem) => {
    setSelectedItems(itemsState => {
      if (itemsState.some(itemState => itemState._id === selectedItem._id)) {
        const filteredItems = itemsState.filter(itemState => itemState._id !== selectedItem._id)
        return filteredItems
      }

      return [...itemsState, selectedItem]
    })
  }

  useEffect(() => {
    if (modelName && visibleColumns.length) {
      updateCookiePreferences({ parentKey: modelName, key: 'visibleColumns', value: [...visibleColumns] })
    }
  }, [visibleColumns])
  
  const shouldShowExportButton = ((modelName === 'GuestsCategory') || (modelName === 'GuestsInvitation')) && !loading && role !== 'read'
  const shouldShowAddButton = role === 'admin' ||  (modelName === 'GuestsInvitation' && role !== 'read')

  useEffect(() => {
    if (invitationInputValue && invitationInputValue.length > 2 || invitationInputValue === '') {
      debounced.current(getData, data)
    }
  }, [invitationInputValue])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex">
        <div className="relative flex grow-1">
          <div>
            <h2 className="text-xl wght-semibold">{title}</h2>
            <div className="relative flex text-xs text-gray wght-light">
              <span className="wght-semibold">
                {role && role.toUpperCase()}
              </span>
            </div>
          </div>
        </div>

        {!selectedItemIsDeleted.deleted &&
          <div className="flex justify-between grow-0">
            <div className="relative flex items-center justify-center">
              <Button
                key="share"
                size="small"
                color="success"
                visibility={role === 'admin' && modelName !== 'Event' || (modelName === 'GuestsQuota' && role !== 'read')} 
                onClick={handleShowModal}
              >
                Share
              </Button>
              {showModal && <InvitationModal token={token} handleShowModal={handleShowModal} modelName={getParsedPathname()} event={GuestsEvent || Cookies.get('event')} role={role}/>}

              <Button
                key="import"
                size="small"
                visibility={!loading && role !== 'read' && modelName === 'GuestsInvitation' && !quotaReached}
                onClick={() => setShowImportModal(true)}
                disabled={itemsToSend.length > 0}
              >
                Import &#8593;
              </Button>

              <GuestsInvitationsImport
                GuestsEvent={GuestsEvent}
                showImportModal={showImportModal}
                setShowImportModal={setShowImportModal}
                uploading={uploading}
                setUploading={setUploading}
                GuestsQuota={GuestsQuota}
                getData={getData}
                entityId={headerStats?.entity?._id}
                quotasAvailables={headerStats?.invitationsStats?.quotasAvailables}
                loading={loading}
                setLoading={setLoading}
                filters={filters}
                getParsedPathname={getParsedPathname}
              />

              {
                role === 'admin' ? (
                  <Button
                  key="export"
                  size="small"
                  visibility={shouldShowExportButton}
                  onClick={() => setShowExportModal(true)}
                >
                  Export &#8595;
                </Button> 
                ) : null
              }
       
              <GuestsInvitationsExport
                showModal={showExportModal}
                setShowModal={setShowExportModal}
                exportFieldsSelected={exportFieldsSelected}
                setExportFieldsSelected={setExportFieldsSelected}
                loading={loading}
                setLoading={setLoading}
                modelName={modelName}
                filters={filters}
                page={page}
                getParsedPathname={getParsedPathname}
              />

              <Button
                key="add"
                size="small"
                visibility={shouldShowAddButton && !quotaReached}
                to={`${getParsedPathname()}/edit/${modelName}`}
              >Add</Button>

            </div>
          </div>
        }
      </div >


      {
        selectedItemIsDeleted.deleted &&
        <div className='p-2 mt-8 mb-8 text-white rounded bg-error'>
          <span>The {selectedItemIsDeleted.name} and its items have been deleted</span>
        </div>
      }

      {
        !selectedItemIsDeleted.deleted &&
        <HeaderStats
          stats={headerStats}
          page={page}
          pathname={getParsedPathname()}
          modelName={modelName}
          role={role}
        />
      }
      {
        (modelName === 'GuestsCategory' || modelName === 'GuestsInvitation') &&
          <div className="flex text-xs mb-4">
            <select
              className="p-1 mb-0 bg-white border rounded outline-none cursor-pointer hover:wght-semibold placeholder-gray border-gray"
              onChange={(e) => setGuestsView(e.target.value)}
              value={guestsView}
            >
              <option className="p-4 text-xs" value="categories">
                View: Categories
              </option>
              <option className="p-4 text-xs" value="invitations">
                View: Invitations
              </option>
            </select>
            {(modelName === 'GuestsInvitation' && !GuestsQuota) && <div className="mx-2">
              <input
                name="invitation"
                id="invitation"
                className="px-2 py-1 text-xs bg-white border rounded outline-none"
                type="text"
                placeholder="Search..."
                onChange={(e) => setInvitationInputValue(e.target.value)}
                value={invitationInputValue}
              />
            </div>}
          </div>
      }
      {
        !selectedItemIsDeleted.deleted &&
        <div className="flex items-end justify-between">
          {role === 'admin' &&
            (modelName === 'GuestsCategory' || modelName === 'GuestsInvitation') ? (
            null
          ) : (
            <>
              {headerStats && Object.keys(headerStats).length > 0 &&
                breadcrumb &&
                breadcrumb.length > 1 ? (
                <div className="flex mt-4">
                  <div className="relative flex text-xs text-gray wght-light">
                    <div>
                      <span className="wght-semibold">
                        <span>
                          <span
                            className="cursor-pointer hover:text-primary hover:wght-semibold"
                            onClick={() => {
                              setSelectedItems([])
                              history.push(breadcrumb[0].path)
                            }}
                          >
                            Categories
                          </span>
                        </span>
                        {breadcrumb.map((item, index) => {
                          if (index === 0) return null
                          return (
                            <span key={index}>
                              {' > '}
                              <span
                                className="cursor-pointer hover:text-primary hover:wght-semibold"
                                onClick={() => {
                                  setSelectedItems([])
                                  history.push(item.path)
                                }}
                              >
                                {item.title}
                              </span>
                            </span>
                          )
                        })}
                      </span>
                    </div>
                  </div>
                </div>
              ) : false}
            </>
          )}



          <div className="flex select-none">
            {!loading ? (
              <div
                className="relative mr-2"
                onClick={() => {
                  setShowSortOptions(!showSortOptions)
                  setShowColumnsFilters(false)
                }}
              >
                <div
                  className={`px-2 py-1 text-xs border rounded cursor-pointer text-primary border-primary hover:wght-semibold bg-white ${!userCustomSort ? 'bg-white' : 'bg-primaryLight'
                    }`}
                >
                  <span className="px-2">Sort</span>
                  <span
                    className={`pr-2 pt-1 ${showSortOptions ? '' : 'hidden'}`}
                  >
                    ↑
                  </span>
                  <span
                    className={`pr-2 pt-1 ${showSortOptions ? 'hidden' : ''}`}
                  >
                    ↓
                  </span>
                </div>

                <div className="relative">
                  <div
                    className={`absolute z-10 border top-2 right-0 text-xs rounded cursor-pointer text-primary ${showSortOptions ? '' : 'hidden'
                      }`}
                  >
                    <span className="flex flex-col flex-wrap p-2 bg-white rounded whitespace-nowrap">
                      {Object.entries(columnsData).length ? (
                        Object.entries(columnsData).map(([key, value]) => {
                          if (key === '_id') return null
                          return (
                            <span key={key}>
                              <input
                                disabled={loading}
                                className="mr-1 cursor-pointer"
                                type="checkbox"
                                id={`sort-${key}`}
                                name={`sort-${key}`}
                                value=""
                                onChange={() => {
                                  if (pageSortField === key) {
                                    setPageSortDirection(pageSortDirection * -1)
                                  } else {
                                    setPageSortField(key)
                                  }
                                }}
                                checked={pageSortField === key ? true : false}
                              />
                              <label
                                className="mr-4 cursor-pointer select-none md:mr-2"
                                htmlFor={`sort-${key}`}
                              >
                                {typeof value === 'string' ? value : value.label}
                                {pageSortField === key && (
                                  <span className={`pl-1 pt-1`}>
                                    {pageSortDirection === 1 ? '↑' : '↓'}{' '}
                                  </span>
                                )}
                              </label>
                            </span>
                          )
                        }))
                        : <></>
                      }
                      <span className={`mr-1 cursor-pointer`} onClick={() => setPageSortField()}>Reset</span>
                    </span>
                  </div>
                </div>
              </div>
            ) : false}

            {!loading ? (
              <div
                className=""
                onClick={() => {
                  setShowColumnsFilters(!showColumnsFilters)
                  setShowSortOptions(false)
                }}
              >
                <div className="px-2 py-1 text-xs bg-white border rounded cursor-pointer text-primary border-primary hover:wght-semibold">
                  <span className="px-2">Columns</span>
                  <span
                    className={`pr-2 pt-1 ${showColumnsFilters ? '' : 'hidden'}`}
                  >
                    ↑
                  </span>
                  <span
                    className={`pr-2 pt-1 ${showColumnsFilters ? 'hidden' : ''}`}
                  >
                    ↓
                  </span>
                </div>

                <div className="relative">
                  <div
                    className={`absolute z-10 border top-2 right-0 text-xs rounded cursor-pointer text-primary ${showColumnsFilters ? '' : 'hidden'
                      }`}
                  >
                    <span className="flex flex-col flex-wrap p-2 bg-white rounded whitespace-nowrap">
                      {Object.entries(columnsData).length ? (
                        Object.entries(columnsData).map(([key, value]) => {
                          if (key === '_id') return null
                          return (
                            <span key={key}>
                              <input
                                disabled={loading}
                                className="mr-1 cursor-pointer"
                                type="checkbox"
                                id={`column-${key}`}
                                name={`column-${key}`}
                                value=""
                                onChange={() =>
                                  setVisibleColumns([
                                    ...getUpdatedColumns(key, visibleColumns),
                                  ])
                                }
                                checked={visibleColumns.includes(key)}
                              />
                              <label
                                className="mr-4 cursor-pointer select-none md:mr-2"
                                htmlFor={`column-${key}`}
                              >
                                {typeof value === 'string' ? value : value.label}
                              </label>
                            </span>
                          )
                        })
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ) : false}
          </div>
        </div>
      }

      {
        !loading && data && data.items.length === 0 && (
          <div className="mt-8">Nothing to list</div>
        )
      }

      {loading && <div className="mt-8">Loading...</div>}

      {!loading && data && data.items.length > 0 && (
        <div className="mt-2 overflow-scroll">
          <table className="w-full overflow-x-auto">
            <thead className="w-full text-left bg-white border-b border-grayLight">
              <tr>
                {Object.keys(data.modelConfig.list).map((key) => {
                  if (!visibleColumns.includes(key)) return null

                  if (key === '_id') return
                  if (typeof data.modelConfig.list[key] === 'undefined') return
                  return (
                    <th key={key} className="px-4 py-2">
                      <span className="relative wght-semibold">
                        {typeof data.modelConfig.list[key] === 'string' &&
                          data.modelConfig.list[key]}
                        {typeof data.modelConfig.list[key] === 'object' &&
                          data.modelConfig.list[key].label}
                        {pageSortField === key && (
                          <span className="absolute inset-y-0 right-0 flex items-center justify-center -mr-4 text-center">
                            {pageSortDirection === 1 ? '↑' : '↓'}
                          </span>
                        )}
                      </span>
                    </th>
                  )
                })}
                <th className="px-4 py-2 text-right wght-semibold">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>
              {data?.items?.map((item, index) => (
                <tr
                  key={item._id}
                  className={`align-top cursor-pointer relative bg-white border-b border-grayLight hover:bg-primaryLight ${modelName === 'ticket' ? 'text-sm' : ''
                    }`}
                >
                  {Object.keys(data.modelConfig.list).map((key) => {
                    if (!visibleColumns.includes(key)) return null

                    if (key === '_id') return
                    if (typeof data.modelConfig.list[key] === 'undefined')
                      return
                    return (
                      <td
                        key={key}
                        className="px-4 py-2"
                        onClick={() =>
                          modelName !== 'GuestsInvitation'
                            ? goTo(item)
                            : (key.includes('modal') && item[key].length) ||
                              (key === 'mailstatus' &&
                                !!parseInt(item['sendmail']))
                              ? null
                              : history.push({
                                pathname: `${getParsedPathname()}/edit/${modelName}/${item._id
                                  }`,
                                state: { page },
                              })
                        }
                      >
                        {typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'string' &&
                          key !== 'sendmail' &&
                          !key.includes('modal') &&
                          key !== 'mailstatus' &&
                          key !== 'exchangeDate' &&
                          key !== 'hasBeenUsedDate' &&
                          item[key]}

                        {typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'string' &&
                          key !== 'sendmail' &&
                          !key.includes('modal') &&
                          key !== 'mailstatus' &&
                          key === 'exchangeDate' &&
                          <span className="whitespace-nowrap">
                            {dayjs(item[key]).isValid() ? dayjs(item[key]).format('DD/MM/YYYY, HH:mm') : item[key]}
                          </span>
                        }
                        {
                        typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'string' &&
                          key !== 'sendmail' &&
                          !key.includes('modal') &&
                          key !== 'mailstatus' &&
                          key === 'hasBeenUsedDate' &&
                          <span className="whitespace-nowrap">
                            {dayjs(item[key]).isValid() ? dayjs(item[key]).tz(timezone).format('DD/MM/YYYY, HH:mm') : ''}
                          </span>
                        }

                        {typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'string' &&
                          key !== 'sendmail' &&
                          !key.includes('modal') &&
                          key === 'mailstatus' && (
                            <div className="flex">
                              <p className="mr-2">{item[key]}</p>
                              <button
                                className={`relative h-auto -mt-2 pt-0.5 text-xl ${itemsToSend.length > 0
                                  ? 'text-gray'
                                  : 'cursor-pointer pointer-events-auto text-primary hover:wght-semibold'
                                  }`}
                                disabled={itemsToSend.length > 0}
                                onClick={() => {
                                  setItemsToSend([item._id])
                                  if (
                                    window.confirm(
                                      'Invitation email will be resent, are you sure?'
                                    )
                                  ) {
                                    sendMail({
                                      token,
                                      history,
                                      modelName,
                                      type: 'NEWGUESTSINVITATION',
                                      recipients: [item],
                                      extraInfo: {
                                        GuestsEvent,
                                        GuestsQuota,
                                        GuestsEntity,
                                      },
                                      callback: () => getData(true),
                                      event: GuestsEvent || Cookies.get('event'),
                                    })
                                  }
                                }}
                              >
                                ⟳
                              </button>
                            </div>
                          )}

                        {typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'string' &&
                          key !== 'sendmail' &&
                          key.includes('modal') &&
                          item[key].length > 0 && (
                            <button
                              className="flex mt-1 text-xs cursor-pointer pointer-events-auto text-primary hover:wght-semibold"
                              onClick={() => {
                                setShowModelModal((state) => !state)
                                setInfoModelModal({
                                  title: data.modelConfig.list[key],
                                  text: item[key],
                                })
                              }}
                            >
                              <span className="mr-1 text-lg -mt-2 pt-0.5">
                                ≣
                              </span>{' '}
                              {data.modelConfig.list[key]}
                            </button>
                          )}

                        {typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'string' &&
                          key === 'sendmail' &&
                          `${!!parseInt(item[key])}`}

                        {typeof item[key] === 'undefined' &&
                          typeof item[key] !== 'string' &&
                          typeof item['sendmail'] !== 'undefined' &&
                          typeof item['sendmail'] === 'string' &&
                          !!parseInt(item['sendmail']) &&
                          key === 'mailstatus' && (
                            <button
                              className={`relative px-2 py-1 text-xs rounded  whitespace-nowrap text-primaryLighter ${itemsToSend.length > 0
                                ? 'bg-gray'
                                : 'cursor-pointer bg-primary hover:wght-semibold pointer-events-auto'
                                }`}
                              disabled={itemsToSend.length > 0}
                              onClick={() => {
                                setItemsToSend([item._id])
                                sendMail({
                                  token,
                                  history,
                                  modelName,
                                  extraInfo: {
                                    GuestsEvent,
                                    GuestsQuota,
                                    GuestsEntity,
                                  },
                                  type: 'NEWGUESTSINVITATION',
                                  recipients: [item],
                                  callback: () => getData(true),
                                  event: GuestsEvent || Cookies.get('event'),
                                })
                              }}
                            >
                              {itemsToSend.includes(item._id)
                                ? 'Sending...'
                                : 'Send mail'}
                            </button>
                          )}
                        {typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'number' &&
                          item[key].toString()}
                        {key !== 'userId' &&
                          typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'object' &&
                          typeof item[key].en === 'string' &&
                          item[key].en}
                        {key !== 'userId' &&
                          typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'object' &&
                          typeof item[key].label === 'string' &&
                          item[key].label}
                        {key === 'userId' &&
                          typeof item[key] !== 'undefined' &&
                          typeof item[key] === 'object' &&
                          item[key] && (
                            <Link
                              className="mr-4 cursor-pointer hover:text-primary hover:wght-semibold text-primary"
                              to={`/admin/user/${item[key].id}/${item[key].profile._id}`}
                            >
                              {item[key].profile.name}
                            </Link>
                          )}
                        {key !== 'userId' &&
                        typeof item[key] !== 'undefined' &&
                        typeof item[key] === 'boolean' &&
                        item[key].toString()}
                      </td>
                    )
                  })}
                  <td className="table-cell pl-4 text-right">
                    <div className="flex justify-end">
                      {role !== 'read' &&
                        <Actions
                          modelName={modelName}
                          id={item._id}
                          code={item.code}
                          publicId={item.publicId}
                          index={index}
                          role={role}
                        />
                      }
                      <label className={`py-2 ${modelName === 'GuestsInvitation' && GuestsQuota ? 'px-4 cursor-pointer' : 'px-0 select-none pointer-events-none opacity-0'}`}>
                        <input
                          type="checkbox"
                          checked={selectedItems.some(selectedItem => item._id === selectedItem._id)}
                          onClick={() => handleSelectedItem(item)}
                        />
                      </label>
                    </div>
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan={Object.keys(data.modelConfig.list).length}
                  className="py-2"
                >
                  <div className="flex justify-end">
                    {selectedItems.length > 0 && (
                      <>
                        <button
                          disabled={itemsToSend.length > 0}
                          className={`mr-1 px-2 py-2 text-xs rounded text-primaryLighter ${itemsToSend.length > 0
                            ? 'bg-gray'
                            : 'bg-primary hover:wght-semibold cursor-pointer'
                            }`}
                          onClick={() => {
                            if (
                              window.confirm(
                                `Invitation email will be sent to ${selectedItems.length} guests, are you sure?`
                              )
                            ) {
                              setItemsToSend(
                                selectedItems.map(
                                  (selectedItem) => selectedItem._id
                                )
                              )
                              sendMail({
                                token,
                                history,
                                modelName,
                                type: 'NEWGUESTSINVITATION',
                                extraInfo: {
                                  GuestsEvent,
                                  GuestsQuota,
                                  GuestsEntity,
                                },
                                recipients: selectedItems,
                                event: GuestsEvent || Cookies.get('event'),
                                callback: () => {
                                  getData(true)
                                },
                              })
                            }
                          }}
                        >
                          {itemsToSend.length > 0
                            ? 'Sending...'
                            : 'Send email to selected'}
                        </button>
                        <button
                          disabled={itemsToSend.length > 0}
                          className={`mr-1 px-2 py-2 text-xs rounded text-primaryLighter ${itemsToSend.length > 0
                            ? 'bg-gray'
                            : 'bg-primary hover:wght-semibold cursor-pointer'
                            }`}
                          onClick={() => setShowEditModal((state) => !state)}
                        >
                          Edit selected
                        </button>
                      </>
                    )}
                    {(modelName === 'GuestsInvitation' && GuestsQuota) && <Button
                      onClick={() => {
                        if (selectedItems.length === data?.items.length) {
                          setSelectedItems([])
                          return
                        }
                        setSelectedItems(data?.items)
                      }}
                      disabled={itemsToSend.length > 0}
                      color={selectedItems.length === data?.items.length ? 'cancel' : "white"}
                      className={`mr-1 px-2 py-2 text-xs bg-white ${selectedItems.length === data?.items.length ? "" : "border-primary text-primary"}`}
                    >
                      {selectedItems.length === data?.items.length ? 'Unselect all' : 'Select all'}
                    </Button>
                    }
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <EditMultipleModal setShowModal={setShowEditModal} showModal={showEditModal} selectedItems={selectedItems} modelName={modelName} />
          <FieldModelModal showModal={showModelModal} setShowModal={setShowModelModal} infoModelModal={infoModelModal} />
        </div>
      )}

      <Pagination
        page={page}
        setPage={setPage}
        hasMorePages={hasMorePages}
        loading={loading}
      />
    </>
  )
}

export default ListGuests
