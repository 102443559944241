import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ConfigContext = createContext()

export const GlobalConfig = ({
  children,
  config
}) => <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>

export const useConfig = () => {
  const ctx = useContext(ConfigContext) || []
  return ctx
}

export const usePrevRoute = () => {
  const location = useLocation()
  const [route, setRoute] = useState(location.pathname)

  useEffect(()=> {
    setRoute((prev)=> (location.pathname) )
  }, [location])
  return route
}
