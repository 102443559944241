import React, { useState, useEffect } from 'react'
import {
  useParams,
  useHistory,
  Link,
} from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import QRCode from 'qrcode.react'
import { useSession } from '../contexts/AuthContext'

const ImageToLoad = ({ type, id, autoLoad }) => {
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState(null)
  const token = useSession()


  useEffect(() => {
    if (autoLoad) {
      loadImage()
    }
  })

  const loadImage = () => {

    if (url) return

    setLoading(true)
    fetch(`${process.env.REACT_APP_GRAPH_URL}/public-url-for-file`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({ id, type }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setUrl(response.body.url)
        }
      })
  }

  if (url) {
    return (
      <img className="max-w-full max-h-full" alt="" src={url} />
    )
  }

  return <div className="p-1 px-2 border border-solid rounded cursor-pointer hover:text-primary border-grayLight" onClick={loadImage}>{loading ? 'Loading...' : 'Load image'}</div>
}

const statusCodes = [
  'EXPIRED',
  'VOIDED',
  'INCORRECT DATE',
  'NOT VALID',
  'UNAUTHORIZED MINOR',
  'NO ZONE PERMISSIONS',
  'VALID',
  'REQUIREMENTS MISSING',
]

const ProfileTicket = ({ _id, ticketId, userId, profileId, ticketType, event }) => {
  const [showQR, setShowQR] = useState(false)
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const token = useSession()

  const loadData = () => {

    if (loading) return

    setLoading(true)
    fetch(`${process.env.REACT_APP_GRAPH_URL}find-ticket-info`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        id: _id,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setData(response.body.ticket)
        } else {
          alert('Error ' + response.status)
        }
      })
  }

  return (
    <div className="p-2 mr-2 text-xs bg-white rounded shrink-0" style={{ flexBasis: '160px' }}>
      <div>
        <div className="mb-2 leading-tight"><strong className="wght-bold">{event.title}</strong></div>
        <div className="mb-2">{ticketType.title}</div>
        <div className="p-2 pb-0 mb-2 border rounded border-grayLight">
          <QRCode size={130} value={`acst://t/${_id}`} />
          <div className="text-xxs">{ticketId}</div>
        </div>
        <Link className="wght-bold hover:text-primary" to={`/user/${userId}/${profileId}/${_id}`}>View ticket</Link>
      </div>
    </div>
  )
}

const User = () => {
  const { userId, profileId, ticketId } = useParams()
  const history = useHistory()
  const [data, setData] = useState()
  const token = useSession()

  useEffect(() => {
    setData(false)
    fetch(`${process.env.REACT_APP_GRAPH_URL}find-ticket-info`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        id: ticketId,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setData(response.body.ticket)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/user/${userId}/${profileId}`);
          }
        }
      })
  }, [userId, profileId, ticketId])

  if (!data) {
    return <div />
  }

  return (
    <div>
      <Helmet>
        <title>{data._id}</title>
      </Helmet>
      <div>
        <div>
          <Link to={`/admin/user/${userId}/${profileId}`} className="hover:text-primary">← Go back to the profile</Link>
        </div>
        <div className="flex">
          <div className="flex-1">

            <div><span className="wght-bold">Event</span>: {data.event.title}</div>
            <div><span className="wght-bold">Ticket Type</span>: {data.ticketType.title}</div>
            <div><span className="wght-bold">Status</span>: {statusCodes[data.status]} → {data.status}</div>

            {data.fields && Object.keys(data.fields).map(key => (
              <div><span className="wght-bold">{key}</span>: {data.fields[key]}</div>
            ))}
          </div>
          <div>
            <QRCode size={300} value={`acst://t/${data._id}`} />
            <span>{data._id}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default User