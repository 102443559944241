import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useSession } from '../contexts/AuthContext'
import Button from './Button'
import { Modal, CloseButton } from './Modal'
import sendMail from './Lib/sendMail'
import { request } from "./Lib/helpers"



const ModalSendMessage = ({
  show,
  showState,
  data = [],
  event,
}) => {
  const history = useHistory()
  const token = useSession()
  const [loadingTemplates, setLoadingTemplates] = useState()
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [errorMessag, setErrorMessage] = useState()
  const [successMessage, setSuccessMessage] = useState()
  const [status, setStatus] = useState('pending')
  const [type, setType] = useState()


  const handleSendMessages = async () => {
    setSuccessMessage()
    setErrorMessage()
    setStatus('sending')
    switch (type) {
      case 'email':
        await sendMail({
          token,
          history,
          modelName: 'Ticket',
          type: 'simpleMailWithTemplate',
          extraInfo: {
            templateId: selectedTemplate,
            events: data.map(i => i.event),
          },
          recipients: data?.map(i => ({
            ticketId: i._id,
          })),
          event,
        }).then(response => {
          if (response.status === 200) {
            setStatus('sent')
            setSuccessMessage('Emails sent successfully')
          } else {
            setStatus('error')
            setErrorMessage(response.message)
          }
        })
          .catch(error => {
            setStatus('error')
            setErrorMessage(error.message)
          })
        break;
      case 'notification': {
        const response = await request({
          url: `${process.env.REACT_APP_API_URL}send-notification`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token} `,
          },
          body: {
            modelName: 'Notification',
            templateId: selectedTemplate,
            recipients: data?.map(i => ({
              ticketId: i._id,
            })),
          },
        });

        const { status, notificationsSent, notificationsNotSent, message, error } = response

        switch (status) {
          case 200:
            let messageResponse = `${notificationsSent} notifications sent successfully`
            if (notificationsNotSent > 0) {
              messageResponse = `${notificationsSent} notifications sent successfully and ${notificationsNotSent} failed`
            }
            setStatus('sent')
            setSuccessMessage(messageResponse)
            break;
          default:
            setStatus('error')
            setErrorMessage(message || error)
            break;
        }
        break;
      }
      case 'sms': {
        const response = await request({
          url: `${process.env.REACT_APP_API_URL}send-sms`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token} `,
          },
          body: {
            modelName: 'SmsTemplate',
            templateId: selectedTemplate,
            recipients: data?.map(i => ({
              ticketId: i._id,
            })),
          },
        });

        const { status, notificationsSent, notificationsNotSent, message, error } = response

        switch (status) {
          case 200:
            let messageResponse = `${notificationsSent} notifications sent successfully`
            if (notificationsNotSent > 0) {
              messageResponse = `${notificationsSent} notifications sent successfully and ${notificationsNotSent} failed`
            }
            setStatus('sent')
            setSuccessMessage(messageResponse)
            break;
          default:
            setStatus('error')
            setErrorMessage(message || error)
            break;
        }

        break;
      }
    }
  }

  useEffect(() => {
    if (type) {
      getTemplates()
    }
  }, [type])

  useEffect(() => {
    setStatus('pending')
    setSelectedTemplate()
    setType()
    setTemplates([])
  }, [show])

  useEffect(() => {
    setSuccessMessage()
    setErrorMessage()
  }, [selectedTemplate, show])

  const getTemplates = () => {
    setTemplates([])
    setLoadingTemplates(true)
    fetch(`${process.env.REACT_APP_API_URL}get-message-template`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        event: event || Cookies.get('event') || false,
        organization: Cookies.get('organization'),
        modelName: 'Ticket',
        type,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          if (response.body && response.body.templates) {
            setTemplates([...response.body.templates])
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            showState(false)
          }
        }
        setLoadingTemplates(false)
      })
      .catch(error => {
        setLoadingTemplates(false)
        console.log('Error', error)
      })
  }

  return <Modal showModal={show} setShowModal={showState} >
    <div className="relative w-2/3 pb-2 overflow-auto bg-white lg:w-1/3 z-1 max-h-128">
      <CloseButton setShowModal={showState} />
      <div className="w-full p-8 pb-2 h-fit">
        <h2 className="text-xl wght-semibold">Send message</h2>
      </div>

      <div className="px-8 h-fit">
        <p className="text-base sele">Recipients:</p>
        <p className='text-primary wght-semibold'>{data?.map(u => u.userEmail || u.purchaseEmail).join(", ")}</p>
      </div>

      <div className="px-8 my-4 h-fit">
        <p className="text-base select-none">Type:</p>
        <select
          className="w-full p-2 mt-2 border rounded"
          name="type"
          id="type"
          onChange={(e) => setType(e.target.value)}
        >
          <option value="">Select a type</option>
          <option value="email">Email</option>
          <option value="notification">Notification</option>
          <option value="sms">SMS</option>
        </select>
      </div>

      <div className="px-8 mt-4 h-fit">
        <p className="text-base select-none">Template:</p>
        <select className="w-full p-2 mt-2 border rounded" name="template" id="template" onChange={(e) => setSelectedTemplate(e.target.value)} >
          <option value="">
            {loadingTemplates && 'Loading...'}
            {!loadingTemplates && !type && 'Select a type before'}
            {!loadingTemplates && type && templates.length === 0 && 'No templates found'}
            {!loadingTemplates && type && templates.length > 0 && 'Select a template'}
          </option>
          {templates.map((template, index) => <option key={index} value={template._id}>{template.ref}</option>)}
        </select>
      </div>

      {errorMessag && <div className="px-8 mt-4 h-fit">
        <p className="text-base text-error">{errorMessag}</p>
        {status === "error" &&
          <Button
            color="white"
            size="small"
            className="mt-2 w-fit mx-0"
            to={`/admin/${event}/list/Messages?view=${type}`}
          >
            View messages
          </Button>
        }
      </div>}

      {successMessage && <div className="px-8 mt-4 h-fit">
        <p className="text-base text-success">{successMessage}</p>
      </div>}

      <div className="flex justify-end px-8 mt-4 mb-8">
        <Button
          onClick={handleSendMessages}
          loading={loadingTemplates}
          disable={!selectedTemplate || status === 'sending'}
          visibility={status !== 'sent' && status !== 'error'}
        >{status !== 'sending' ? 'Send' : 'Sending...'}</Button>
        <Button
          onClick={() => showState(false)}
          visibility={status === 'sent' || status === 'error'}
          color='success'
        >Close</Button>
      </div>
    </div>
  </Modal>
}

export default ModalSendMessage
