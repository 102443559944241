import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import StatsProgressBar from '../StatsProgressBar'
import dayjs from 'dayjs'

const GetZoneInfo = () => {
  const { id } = useParams()
  const title = 'Zone info'
  const history = useHistory()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData()
  }, [])
  

  const getData = async () => {
    setLoading(true)
    const zoneInfo = await fetch(`${process.env.REACT_APP_API_URL}public-zones`, {
      method: 'POST',
      body: JSON.stringify({ zoneId: id }),
    })
    setLoading(false)

    if (zoneInfo.status === 404) {
      history.push(`/public/no-access?url=${window.location.pathname}`)
    }
    const data = await zoneInfo.json()
    setData(data.zone)
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex'>
        <div className="relative flex grow-1">
          <h2 className="text-xl wght-semibold">{title}</h2>
        </div>
      </div>

      <div className='flex'>
        <span className='relative flex text-xs text-gray wght-semibold'>PUBLIC</span>
      </div>

      {
        loading &&
          <div className='mt-8'>
            <span className='relative flex'>Loading...</span>
          </div>
      }

      {
        data &&
          <div className='mt-4'>
            <h3 className='text-xl wght-semibold text-primary' >{data.title}</h3>
            <span className='relative flex'>Event: <span className='ml-2 wght-semibold'>{data?.event || '-'}</span></span>
            {/* <span className='relative flex'>Location: <span className='ml-2 wght-semibold'>{data?.eventLocation || '-'}</span></span> */}
            <StatsProgressBar
              index={0}
              title="Occupancy"
              total={data.capacity}
              current={data.occupancy || 0}
              className={'border-primary'}
            />
            {/* <span className='relative flex text-xs'>Last updated: <span className='ml-2 wght-semibold'>{data?.lastUpdate &&  dayjs(data?.lastUpdate).format("DD/MM/YYYY - HH:mm:ss") || '-'}</span></span> */}
          </div>
      }

    </div>
  )
}

export default GetZoneInfo