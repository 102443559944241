import Cookies from 'js-cookie';

const sendMail = async ({
  token,
  history,
  modelName,
  type,
  recipients,
  emails,
  callback,
  event,
  extraInfo,
}) => {
  try {
    const data = {
      modelName,
      organization: Cookies.get('organization'),
      event,
      type,
      recipients,
      emails,
      extraInfo,
    }
    const response = await fetch(`${process.env.REACT_APP_API_URL}send-mail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token} `,
      },
      body: JSON.stringify(data),
    });

    const body = await response.json();
    const { total_mails_sent } = body;

    if (callback) callback(total_mails_sent)

    switch (response.status) {
      case 200:
        return {
          status: 200,
          message: 'Emails sent successfully',
          total: total_mails_sent,
        }
      case 400:
        return {
          status: 400,
          message: body.error,
        }

      case 500:
        alert(body.error)
        return {
          status: 500,
          message: body.error,
        }

      case 403:
        Cookies.remove('token')
        history.push('/login')
        break;

      case 401:
        history.push(`/admin/no-access?url=${window.location.pathname}`)
        break;
    }
  } catch (err) {
    console.log('🚀 ~ file: EditGuest.js:137 ~ sendMail ~ err:', err)
  }
}

export default sendMail