import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import { ListControlDeviceLogs } from '../../externalApi/gql.services'
import { useSession } from '../../contexts/AuthContext'

const ControlDeviceDetailLog = () => {
  
  const { deviceId } = useParams()
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const token = useSession()

  const fetchData = async () => {
    setLoading(true)
    const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        query: ListControlDeviceLogs,
        variables: {
          deviceId,
        },
      }),
    })
    const {data, errors: responseErrors} = await res.json()
    setLoading(false)
    if (responseErrors) {
      responseErrors.forEach(error => console.log('Error', error))
    } else {
      setData(data?.lastControlDeviceLogs)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div>
      <Helmet>
        <title>Control Device</title>
      </Helmet>
      <div className="text-xs">
        {loading && !data && <span>Loading...</span>}
        {data && data.map((history, index) => (
        <div key={index} className={`mb-2 p-1 rounded ${Number(history.type) === 1 ? 'bg-success' : 'bg-error'}`}>
          <div className="flex justify-between">
            <span>{Number(history.type) === 1 ? 'In' : 'Out'} → <Link className="underline" to={`/admin/ticket/${history.ticketId}`}>View ticket</Link></span>
            <span>{dayjs.unix(history.date/1000).format('DD/MM/YYYY HH:mm:ss')}</span>
          </div>
        </div>))}
      </div>
    </div>
  )
}

export default ControlDeviceDetailLog