import { uploadManagerImageUrl } from '../../externalApi/gql.services'

const b64toBlob = (base64, type = 'application/octet-stream') =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob())

const getUploadUrl = async ({ ticketId, token }) => {
  try {
    const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        query: uploadManagerImageUrl,
        variables: {
          ticketId,
          token,
        },
      }),
    })
    if (res) {
      const response = await res.json()
      if (response?.errors) {
        return {
          valid: false,
          error: response?.errors[0]?.message,
        }
      }
      return {
        valid: true,
        url: response.data.uploadManagerImageUrl.url,
      }
    } else {
      return {
        valid: false,
        error: 'No response',
      }
    }
  } catch (error) {
    return {
      valid: false,
      error: error,
    }
  }
}

const uploadImage = async ({ url, image, imageType }) => {
  try {
    let parsedImage = image
    if (imageType === 'base64') {
      const preparedImage = image.replace(
        /^data:image\/(png|jpg|jpeg);base64,/,
        ''
      )
      parsedImage = await b64toBlob(preparedImage)
    }
  
    const response = await fetch(url, {
      method: 'PUT',
      body: parsedImage,
      headers: { 'Content-Type': 'image/jpeg' },
    })

    if (!response.ok) {
      return {
        valid: false,
        error: response.statusText,
      }
    }

    return { valid: true }

  } catch (error) {
    return {
      valid: false,
      error: error,
    }
  }
}

const addPhoto = async ({ token, ticketId}) => {
  try {
    const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        query: uploadManagerImageUrl,
        variables: {
          token,
          ticketId,
          addPhoto: true,
        },
      }),
    })
    if (res) {
      const response = await res.json()
      if (response?.errors) {
        return {
          valid: false,
          error: response?.errors[0]?.message,
        }
      } else {
        return {
          valid: true,
        }
      }
    } else {
      return {
        valid: false,
        error: 'No response',
      }
    }
  } catch (error) {
    return {
      valid: false,
      error: error,
    }
  }
}

const uploadUserImage = async ({ ticketId, token, image, imageType }) => {
  const uploadUrl = await getUploadUrl({ ticketId, token })
  if (!uploadUrl.valid) {
    return {
      valid: false,
      error: uploadUrl.error,
    }
  }

  const resultUploadImage = await uploadImage({
    url: uploadUrl.url,
    image,
    imageType,
  })
  if (!resultUploadImage.valid) {
    return {
      valid: false,
      error: resultUploadImage.error,
    }
  }

  const resultAddPhoto = await addPhoto({
    token,
    ticketId,
  })
  if (!resultAddPhoto.valid) {
    return {
      valid: false,
      error: resultAddPhoto.error,
    }
  }

  return { valid: true }
}

export default uploadUserImage
