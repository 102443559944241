import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import useLocalStorage from '../hooks/useLocalStorage'
import { usePrevRoute } from './Store'
import InvitationModal from './InvitationModal'
import dayjs from 'dayjs'
import { useSession } from '../contexts/AuthContext'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import Button from './Button'

const ListGuestsEvents = ({ modelName, menuEventsOptions }) => {
  const history = useHistory()
  const { page: pageParam, event } = useParams()
  const { getOrganization, getEvent } = useOrganizationInfo(event)
  const [data, setData] = useState(false)
  const [userSortSettings, setUserSortSettings] = useLocalStorage(
    'user_sort_settings',
    {}
  )
  const [sortedValue, setSortedValue] = useState()
  const [sortAscending, setSortAscending] = useState(true)
  const [sortedItems, setSortedItems] = useState()
  const [title, setTitle] = useState('Guests - Events')
  const [page, setPage] = useState(pageParam - 1 || false)
  const [hasMorePages, setHasMorePages] = useState(false)
  const pageLimit = 5000
  const [duplicatedId, setDuplicatedId] = useState(false)
  const [loading, setLoading] = useState(true)
  const route = usePrevRoute()
  const [showModal, setShowModal] = useState(false)
  const [role, setRole] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState()
  const token = useSession()


  useEffect(() => {
    setShowModal(false)
  }, [history.location.pathname])

  useEffect(() => {
    let prevRoute = false
    if (route) {
      prevRoute = route.split('/')
      prevRoute = prevRoute[3]
    }
    if (prevRoute && prevRoute !== modelName) {
      setPage(0)
    }
  }, [modelName])

  useEffect(() => {
    setLoading(true)
    getData()
  }, [page, modelName, duplicatedId])

  useEffect(() => {
    const settings = { ...userSortSettings }
    if (settings.hasOwnProperty(modelName)) {
      const { value, ascending } = settings[modelName]
      setSortedValue(value)
      setSortAscending(ascending)
    }
    setUserSortSettings(settings)
  }, [modelName])

  useEffect(() => {
    const settings = { ...userSortSettings }
    if (
      typeof sortedValue === 'undefined' ||
      typeof sortAscending === 'undefined'
    ) {
      delete settings[modelName]
    } else {
      settings[modelName] = {
        value: sortedValue,
        ascending: sortAscending,
      }
    }
    setUserSortSettings(settings)
  }, [sortedValue, sortAscending])

  useEffect(() => {
    if (typeof data === 'object' && typeof data.items !== 'undefined') {
      const items = [...data.items]
      if (typeof sortedValue !== 'undefined') {
        const sorted = items.sort((a, b) => {
          const itemA =
            typeof a[sortedValue] == 'string'
              ? a[sortedValue].toLowerCase()
              : undefined
          const itemB =
            typeof b[sortedValue] == 'string'
              ? b[sortedValue].toLowerCase()
              : undefined

          if (typeof itemA === 'undefined' && typeof itemB === 'undefined')
            return 0
          if (typeof itemA === 'undefined') return sortAscending ? 1 : -1
          if (typeof itemB === 'undefined') return sortAscending ? -1 : 1

          if (itemA < itemB) return sortAscending ? -1 : 1
          if (itemA > itemB) return sortAscending ? 1 : -1
          return 0
        })
        setSortedItems(sorted)
      } else {
        setSortedItems(data.items)
      }
    }
  }, [data, sortedValue, sortAscending, modelName])

  const getData = () => {
    setData(false)
    fetch(`${process.env.REACT_APP_API_URL}model-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName: 'Event',
        organization: getOrganization(),
        event: getEvent(),
        page,
        pageLimit,
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
          const rawBody = { ...response.body }
          Object.keys(rawBody.modelConfig.list).map((key) => {
            if (typeof rawBody.modelConfig.list[key] === 'object') {
              rawBody.modelConfig.list[key] =
                rawBody.modelConfig.list[key].label
            }
          })
          setData(rawBody)
          setSortAscending(
            Object.values(rawBody.modelConfig.sort)[0] === 'asc' ? true : false
          )
          setSortedValue(Object.keys(rawBody.modelConfig.sort)[0])
          if (response.body.pageLimit) {
            setHasMorePages(
              response.body.items.length === response.body.pageLimit
            )
          } else {
            setHasMorePages(response.body.items.length === pageLimit)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const handleSort = (key) => {
    if (sortedValue === key) {
      if (sortAscending) {
        setSortAscending(false)
      } else {
        setSortedValue(undefined)
        setSortAscending(undefined)
      }
    } else {
      setSortedValue(key)
      setSortAscending(true)
    }
  }

  const handleShowModal = () => setShowModal((state) => !state)

  const goToCategories = (item) => {
    const { _id: eventId, tz: timezone } = item
    Cookies.set('event', eventId, { expires: 7 })
    if (timezone) {
      Cookies.set('eventTimezone', timezone, { expires: 7 })
    } else {
      Cookies.remove('eventTimezone')
    }
    history.push(`/admin/guests/${item.slug}`)
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex">
        <div className="relative flex grow-1">
          <h2 className="text-xl wght-semibold">{title}</h2>
          {showModal && (
            <InvitationModal
              token={token}
              handleShowModal={handleShowModal}
              modelName={modelName}
              role={role}
            />
          )}
        </div>

        <div className="relative flex justify-between grow-0">
          <div className="relative flex items-center justify-center">
            <Button visibility={role === 'admin' && modelName !== 'Event'} size="small" onClick={handleShowModal} color='success'>Share</Button>
          </div>
        </div>
      </div>

      {role && (
        <div className="flex">
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className="wght-semibold">{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      )}

      {data && data.items.length === 0 && (
        <div className="mt-8">Nothing to list</div>
      )}

      {data && data.items.length > 0 && sortedItems && (
        <div className="mt-2 overflow-scroll">
          <table className="w-full">
            <thead className="block w-full mb-4 text-left bg-white border-b border-grayLight">
              <tr className="">
                {Object.keys(data.modelConfig.list).map((key) => {
                  if (key !== 'title') return
                  if (key === '_id') return
                  if (typeof data.modelConfig.list[key] === 'undefined') return
                  return (
                    <th key={key} className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort(key)}
                      >
                        {typeof data.modelConfig.list[key] === 'string' &&
                          data.modelConfig.list[key]}
                        {typeof data.modelConfig.list[key] === 'object' &&
                          data.modelConfig.list[key].label}
                        {sortedValue === key && (
                          <span className="absolute inset-y-0 right-0 flex items-center justify-center -mr-4 text-center">
                            {sortAscending ? '↑' : '↓'}
                          </span>
                        )}
                      </span>
                    </th>
                  )
                })}
              </tr>
            </thead>

            <tbody>
              {sortedItems.map((item, index) => (
                <tr
                  key={item._id}
                  className={`align-top block mb-2 h-32 cursor-pointer relative bg-white border-b border-grayLight hover:bg-primaryLight ${modelName === 'ticket' ? 'text-sm' : ''
                    }`}
                  onClick={() => goToCategories(item)}
                >
                  {Object.keys(data.modelConfig.list).map((key) => {
                    if (key !== 'title') return
                    if (key === '_id') return
                    if (typeof data.modelConfig.list[key] === 'undefined')
                      return

                    return (
                      <td key={key} className={`px-4 py-2`}>
                        <div>
                          <p className=" wght-semibold">{item.title}</p>
                          <p className="text-sm ">
                            From {dayjs(item.startDate).format('DD/MM/YYYY')}{' '}
                            to {dayjs(item.endDate).format('DD/MM/YYYY')}
                          </p>
                        </div>
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {loading && <div className="mt-8">Loading...</div>}

      {!loading && (hasMorePages || page > 0) && (
        <div className="flex justify-between mt-8">
          <div>
            {page > 0 && (
              <span
                onClick={() => setPage(page - 1)}
                className="ml-1 cursor-pointer hover:wght-semibold"
              >
                Previous page
              </span>
            )}
          </div>
          {hasMorePages && (
            <span
              onClick={() => setPage(page + 1)}
              className="mr-1 cursor-pointer hover:wght-semibold"
            >
              Next page
            </span>
          )}
        </div>
      )}
    </>
  )
}

export default ListGuestsEvents
