import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useSession } from '../contexts/AuthContext'
import Button from './Button'
import { Modal, CloseButton } from './Modal'

const ModalSendBatchMessages = ({
  show,
  showState,
  count,
  setShowCount,
  event,
  filters,
  filterTemplates,
  selectedFilterTemplate,
  developer,
}) => {
  const history = useHistory()
  const token = useSession()
  const [loadingTemplates, setLoadingTemplates] = useState()
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState()
  const [errorMessag, setErrorMessage] = useState()
  const [successMessage, setSuccessMessage] = useState()
  const [status, setStatus] = useState('pending')
  const [type, setType] = useState()
  const [developerMode, setDeveloperMode] = useState()
  const [ticketIds, setTicketIds] = useState()

  useEffect(() => {
    if (show && !count && count !== 0) {
      setShowCount(true)
    }
    if (!show) {
      setTicketIds()
      setDeveloperMode()
      setType()
      setStatus('pending')
    }
  }, [show])


  const handleSendMessages = async () => {
    setSuccessMessage()
    setErrorMessage()
    setStatus('sending')

    const filtersRecord = {}
    Object.keys(filters).map(key => {
      if (filters[key] && key !== 'filterTemplate') {
        filtersRecord[key] = filters[key]
      }
    })
    const segment = selectedFilterTemplate && filterTemplates.find(template => template.value === selectedFilterTemplate.value)?.templateFilters
    segment && Object.keys(segment).map(key => {
      if (!segment[key]) {
        delete segment[key]
      }
    })

    const usingOnlySegment = segment && Object.keys(segment).length === Object.keys(filtersRecord).length ? true : false

    const rawFilters = {
      ...filters,
      event: event || Cookies.get('event') || false,
      organization: Cookies.get('organization'),
    }

    let ids = []
    if (ticketIds && ticketIds.length > 0) {
      ids.push(...ticketIds?.split(','))
    }

    const body = JSON.stringify({
      modelName: 'Ticket',
      filters: rawFilters,
      segment: usingOnlySegment ? {
        _id: selectedFilterTemplate._id,
        label: selectedFilterTemplate.label,
      } : false,
      processBatch: true,
      batchType: type,
      template: selectedTemplate,
      batchTicketsIds: ids.length > 0 ? ids : false,
    })

    await fetch(`${process.env.REACT_APP_API_URL}tickets`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setStatus('sent')
          setSuccessMessage('Messages are being processing and will be sent soon.')
        } else {
          setStatus('error')
          setErrorMessage(response.message)
        }
      })
      .catch(error => {
        setStatus('error')
        setErrorMessage(error.message)
      })
  }

  useEffect(() => {
    setSelectedTemplate()
    if (type) {
      getTemplates()
    }
  }, [type])

  useEffect(() => {
    setSuccessMessage()
    setErrorMessage()
  }, [selectedTemplate, show])

  const getTemplates = () => {
    setTemplates([])
    setLoadingTemplates(true)
    fetch(`${process.env.REACT_APP_API_URL}get-message-template`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        event: event || Cookies.get('event') || false,
        organization: Cookies.get('organization'),
        modelName: 'Ticket',
        type,
      }),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          if (response.body && response.body.templates) {
            setTemplates([...response.body.templates])
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            showState(false)
          }
        }
        setLoadingTemplates(false)
      })
      .catch(error => {
        setLoadingTemplates(false)
        console.log('Error', error)
      })
  }

  return <Modal showModal={show} setShowModal={showState} >
    <div className="relative w-2/3 pb-2 overflow-auto bg-white lg:w-1/3 z-1 max-h-128">
      <CloseButton setShowModal={showState} />
      <div className="w-full p-8 pb-2 h-fit">
        <h2 className="text-xl wght-semibold">Send batch messages</h2>
      </div>

      {
        developer && <div className="px-8 mb-2 text-sm hmix-blend-soft-light">
          <div className='flex items-center'>
            <input
              className="inline-block w-4 h-4 mr-2 border rounded cursor-pointer "
              type="checkbox"
              name="developerMode"
              id="developerMode"
              onChange={(e) => setDeveloperMode(e.target.checked)}
            />
            <label htmlFor='developerMode' className="cursor-pointer">Developer mode</label>
          </div>
          {
            developerMode && <div className="py-2 h-fit">
              <p>Send messages to specific ticket IDs</p>
              <textarea
                className="w-full p-2 border rounded"
                name="ticketIds"
                id="ticketIds"
                rows="2"
                onChange={(e) => setTicketIds(e.target.value)}
              />
            </div>
          }
        </div>
      }

      {
        !count && count !== 0
          ? <p className="p-8 h-fit">Loading...</p> :
          <>
            <div className="px-8 h-fit">
              <p className="text-base">Send message to: <span className='text-primary wght-semibold'>{ticketIds ? ticketIds?.split(',').length || 0 : count} users</span></p>
            </div>

            <div className="px-8 my-4 h-fit">
              <p className="text-base select-none">Type:</p>
              <select
                className="w-full p-2 mt-2 border rounded"
                name="type"
                id="type"
                onChange={(e) => setType(e.target.value)}
              >
                <option value="">Select a type</option>
                <option value="email">Email</option>
                <option value="notification">Notification</option>
                {/* <option value="sms">SMS</option> */}
              </select>
            </div>

            <div className="px-8 mt-4 h-fit">
              <p className="text-base select-none">Template:</p>
              <select className="w-full p-2 mt-2 border rounded" name="template" id="template" onChange={(e) => setSelectedTemplate(e.target.value)} value={selectedTemplate} >
                <option value="">
                  {loadingTemplates && 'Loading...'}
                  {!loadingTemplates && !type && 'Select a type before'}
                  {!loadingTemplates && type && templates.length === 0 && 'No templates found'}
                  {!loadingTemplates && type && templates.length > 0 && 'Select a template'}
                </option>
                {templates.map((template, index) => <option key={index} value={template._id}>{template.ref}</option>)}
              </select>
            </div>

            {errorMessag && <div className="px-8 mt-4 h-fit">
              <p className="text-base text-error">{errorMessag}</p>
            </div>}

            {successMessage && <div className="px-8 mt-4 h-fit">
              <p className="text-base text-success">{successMessage}</p>
              {/* // TODO: Add a link to the batch insight page */}
            </div>}

            <div className="flex justify-end px-8 mt-4 mb-8">
              <Button
                onClick={handleSendMessages}
                loading={loadingTemplates}
                disable={!selectedTemplate || status === 'sending'}
                visibility={status !== 'sent'}
              >{status !== 'sending' ? 'Send' : 'Processing...'}</Button>
              <Button
                onClick={() => showState(false)}
                disable={status !== 'sent'}
                visibility={status === 'sent'}
                color='success'
              >Close</Button>
            </div>
          </>
      }
    </div>
  </Modal>
}

export default ModalSendBatchMessages
