import { useMemo } from 'react';

import useLocalStorage from './useLocalStorage'
import Cookies from 'js-cookie'

function useOrganizationInfo(event) {
  const [ organizationEvents ] = useLocalStorage("organizationEvents")

  const eventDictionary = useMemo(() => {
    return organizationEvents?.reduce((acc, event) => {
      acc[event.value] = event.label;
      return acc;
    }, {}) || {};
  }, [organizationEvents])

  const getEvent = (onlyObjectId) => {
    let eventId = false
    if (event !== 'all') {
      if (organizationEvents) {
        const organizationEvent = organizationEvents.find(e => e.slug === event)
        eventId = organizationEvent?.value || event
      } else if (!onlyObjectId) {
        eventId = event
      }
    }

    return eventId
  }

  const getOrganization = () => {
    return Cookies.get('organization')
  }

  return { getOrganization, getEvent, organizationEvents: eventDictionary }
}

export default useOrganizationInfo