const qr = {
  'Ticket': 't',
  'User': 'u',
  'Service': 's',
  'RAT': 'r',
  'ShopLocation': 'sl',
  'Event': 'e',
  'EventLocation': 'el',
  'EventLocationZone': 'elz',
  'ShopPurchase': 'sp',
  'Giftcard': 'gc',
}

export default qr