import React from 'react'

const LanguageSelector = ({
  onChangeLanguage, language, languages, children,
}) => (
  <div>
    <div className="flex">
      {Object.keys(languages).map(key => (
        <div
          key={key}
          className={`text-xs rounded-t pt-1 px-4 cursor-pointer ${
            key === language ? 'bg-grayLight wght-semibold' : ''
          }`}
          onClick={() => onChangeLanguage(key)}
        >
          {languages[key]}
        </div>
      ))}
    </div>
    {children}
  </div>
)

export default LanguageSelector
