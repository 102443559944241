import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import Section from '../Lib/Section'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'

const TickettypeZones = () => {
  const title = 'Ticket types zones'
  const history = useHistory()
  const { name: modelName, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [data, setData] = useState(false)
  const pageLimit = 50
  const [loading, setLoading] = useState(true)
  const token = useSession()

  useEffect(() => {
    setLoading(true)
    getData()
  }, [modelName])

  useEffect(() => {
  }, [loading])

  const getData = () => {
    setData(false)
    fetch(`${process.env.REACT_APP_API_URL}tickettype-zones`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName: 'tickettype-zones',
        pageLimit,
        event: getEvent(),
        organization: getOrganization(),
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          const rawBody = { ...response.body }
          console.log(rawBody.csv)
          setData(rawBody)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const [visibleSections, setVisibleSections] = useState([])

  const handleVisibleSections = (section) => {
    if (visibleSections.includes(section)) {
      setVisibleSections(visibleSections.filter(item => item !== section))
    } else {
      setVisibleSections([...visibleSections, section])
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {data && data.items.length === 0 && <div>Nothing to list</div>}

      {loading ? <div className="mt-8">Loading...</div> :
        <>
          {data && data.items && data.items.map(item => {
            return <Section title={item.ticketType} open={visibleSections.includes('rawTicket')} onClick={() => handleVisibleSections('rawTicket')}>
              {item.zones.map(zone => (
                <p>{zone.title}</p>
              ))}
            </Section>
          })}
        </>
      }
    </>
  )
}

export default TickettypeZones