import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'
import { parseField } from '../Lib/utils'
import Pagination from '../Pagination'
import { getUserPreference, updateCookiePreferences } from '../Lib/utils'
import debounce from 'lodash/debounce'


const BatchMessages = () => {
  const title = 'Sent messages'
  const history = useHistory()
  const debounced = useRef(debounce((fn, value) => fn(value), 300))
  const { page: pageParam, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [data, setData] = useState(false)
  const [page, setPage] = useState(pageParam - 1 || false)
  const [hasMorePages, setHasMorePages] = useState(false)
  const pageLimit = 50
  const [loading, setLoading] = useState(true)
  const [notificationText, setNotificationText] = useState('')
  const [role, setRole] = useState(false)
  const token = useSession()
  const [modelName, setModelName] = useState(getUserPreference({ parentKey: 'Messages', key: 'modelName', defaultValue: 'MessagesEmail' }))
  const [searchInputValue, setSearchInputValue] = useState('')

  const getData = () => {
    setData(false)

    fetch(`${process.env.REACT_APP_API_URL}single-message`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        organization: getOrganization(),
        event: getEvent(),
        page,
        pageLimit,
        searchValue: searchInputValue,
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
          const rawBody = { ...response.body }
          setData(rawBody)
          if (response.body.pageLimit) {
            setHasMorePages(response.body.items?.length === response.body.pageLimit)
          } else {
            setHasMorePages(response.body.items?.length === pageLimit)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  useEffect(() => {
    updateCookiePreferences({ parentKey: 'Messages', key: 'modelName', value: modelName })
    setLoading(true)
    getData()
  }, [page, modelName])


  const handleCopyText = (field, text) => {
    navigator.clipboard.writeText(text)
    setNotificationText(`Copied ${field}: ${text}`)
  }

  useEffect(() => {
    if (notificationText) {
      setTimeout(() => {
        setNotificationText('')
      }, 3000);
    }
  }, [notificationText])

  useEffect(() => {
    if (searchInputValue && searchInputValue.length > 2 || searchInputValue === '') {
      debounced.current(getData, data)
    }
  }, [searchInputValue])


  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      <div className="mt-4 mb-2 h-fit">
        <p className="text-xs select-none">Type:</p>
        <select className="mt-1 px-2 py-1 border rounded text-xs" name="template" id="template" onChange={(e) => setModelName(e.target.value)} value={modelName}>
          <option value="MessagesEmail">Email</option>
          <option value="MessagesNotification">Notification</option>
          <option value="MessagesSMS">SMS</option>
        </select>
        <input
          name="search"
          id="search"
          className="ml-2 px-2 py-1 text-xs bg-white border rounded outline-none"
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchInputValue(e.target.value)}
          value={searchInputValue}
        />
      </div>


      {notificationText &&
        <span className='fixed z-10 px-2 py-1 text-sm border-2 rounded bottom-5 right-5 bg-purple'>{notificationText}</span>
      }

      {loading && <div className="mt-8">Loading...</div>}

      {!loading && data?.items?.length > 0 &&
        <>
          <div className="mt-8 overflow-scroll">
            <table className="w-full">
              <thead className="w-full text-left bg-white border-b border-grayLight whitespace-nowrap">
                <tr>
                  {Object.keys(data.modelConfig.list).map(key => {
                    if (key === '_id') return
                    if (typeof data.modelConfig.list[key] === 'undefined') return
                    return (
                      <th key={key} className="px-4 py-2">
                        <span
                          className="relative cursor-pointer wght-semibold"
                        >
                          {typeof data.modelConfig.list[key] === 'string' && data.modelConfig.list[key]}
                          {typeof data.modelConfig.list[key] === 'object' && data.modelConfig.list[key].label}
                        </span>
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody className='whitespace-nowrap'>

                {data && data.items.map((item, index) =>
                  <tr key={item._id} className={`relative bg-white border-b border-grayLight hover:bg-primaryLight ${modelName === 'ticket' ? 'text-sm' : ''}`}>
                    {Object.keys(data.modelConfig.list).map(key => {
                      if (key === '_id') return
                      if (typeof data.modelConfig.list[key] === 'undefined') return
                      if (item[key] && (key === 'creationDate' || key === 'updateDate')) {
                        return <td key={key} className={`px-4 py-2`}>
                          {dayjs(item[key]).format('DD/MM/YYYY HH:mm:ss')}
                        </td>
                      }
                      if (item[key] && key === 'response') {
                        if (item.status === 'failed') {
                          return <td key={key} className={`px-4 py-2`}>
                            {item[key] && JSON.stringify(item[key])}
                          </td>
                        } else {
                          return <td key={key} className={`px-4 py-2`}></td>
                        }
                      }
                      return (
                        <td key={key} className={`px-4 py-2`}>
                          {parseField(key, item)}
                        </td>
                      )
                    })}
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Pagination
            page={page}
            setPage={setPage}
            hasMorePages={hasMorePages}
            loading={loading}
          />
        </>
      }

      {
        !loading && data?.items?.length === 0 &&
        <p className="mt-8">Nothing to list</p>
      }
    </>
  )
}

export default BatchMessages