import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { GetAccessCode } from '../../externalApi/gql.services'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'
import Button from '../Button'
import Cookies from 'js-cookie'
import InvitationButton from '../InvitationButton'

const EmailCode = () => {
  const title = 'User email code'
  const { event, page: pageParam } = useParams()
  const modelName = 'user-email-code'
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [data, setData] = useState(false)
  const history = useHistory()
  const [page, setPage] = useState(pageParam - 1 || false)
  const [loading, setLoading] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const token = useSession()
  const [role, setRole] = useState()

  useEffect(() => {
    setLoading(true)
    if (searchValue) {
      getData()
    }
  }, [page, modelName])

  useEffect(() => {
    getRole()
  }, [])

  const getRole = () => {
    fetch(`${process.env.REACT_APP_API_URL}model-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        organization: getOrganization(),
        event: getEvent(),
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const getData = async () => {
    setData(false)

    try {
      const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
        method: 'POST',
        headers: {
          Authorization: `${token}`,
          'AccessTicket-App': 'manager',
        },
        body: JSON.stringify({
          query: GetAccessCode,
          variables: {
            email: searchValue,
          },
        }),
      })
      if (res) {
        const response = await res.json()
        const { data: { getAccessCode: code }, errors } = response
        if (!code) {
          setData({ code: errors?.message === 'CODE_EXPIRED' ? 'CODE_EXPIRED' : false })
        } else {
          setData(code)
        }
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const clearFilters = () => {
    setSearchValue('')
    searchEmail(true)
  }
  
  const searchEmail = (clear = false) => {
    if (clear) {
      setData()
      return
    }
    setLoading(true)
    if (page === 0) {
      getData()
    } else {
      setPage(0)
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchEmail()
    }
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex items-center justify-between'>
        <h1 className="text-xl wght-semibold">{title}</h1>
        <InvitationButton
          event={event}
          modelName={modelName}
          role={role}
        />
      </div>
      {role && (
        <div className="flex">
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className="wght-semibold">{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      )}

      <div className="w-full mt-8 mb-2 sm:mb-0 sm:w-auto">
        <div className="flex flex-col md:flex-row">
          <input
            name="search"
            className={`rounded px-2 bg-white outline-none placeholder-gray mr-2`}
            type="text"
            placeholder="Search email"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <Button
            className="mr-2"
            onClick={() => searchEmail()}
            color='primary'
            disable={loading}
          >
            {loading ? 'Loading...' : 'Search'}
          </Button>
          <Button
            className="mr-2"
            onClick={() => clearFilters()}
            color="cancel"
          >
            Reset
          </Button>
        </div>
      </div>

      { data &&
        <div className=''>
          {data.code ? <div className='mt-4'>
            <table className='table-auto '>
              <thead className="w-full text-left bg-white border-b border-grayLight">
                <tr>
                  <th className='px-4 py-2'>Email</th>
                  <th className='px-4 py-2'>Code</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr className="border-b border-grayLight">
                  <td className='px-4 py-2 border-b border-grayLight'>{searchValue}</td>
                  <td className='px-4 py-2 border-b border-grayLight'>{data.code}</td>
                </tr> 
              </tbody>
            </table>
          </div>
          : <div className='mt-4'>No code for this email</div>
          }
        </div>
      }

    </>
  )
}

export default EmailCode