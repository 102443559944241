import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Form from './Form'
import defaultValue from '../Lib/defaultValue'

const generateToken = length => {
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split(
    '',
  )
  const b = []
  for (let i = 0; i < length; i++) {
    const j = (Math.random() * (a.length - 1)).toFixed(0)
    b[i] = a[j]
  }
  return b.join('')
}

const Model = ({
  showErrors,
  storedData,
  onChange,
  models,
  name,
  translatable,
  options: { label, model, dropdown, dropdownState },
  languages,
  find,
  files,
  readMode,
  timezone,
}) => {

  const [language, setLanguage] = useState(translatable ? Object.keys(languages)[0] : null)
  const [showContent, setShowContent] = useState(dropdown && dropdownState === 'hidden' ? false : true)

  const dValue = defaultValue(name, storedData, translatable, {})
  if (typeof dValue.key === 'undefined') dValue.key = generateToken(30)

  const [value, setValue] = useState(dValue)

  useEffect(() => {
    onChange(name, value)
  }, [value])

  useEffect(() => {
    setValue(defaultValue(name, storedData, translatable, {}))
  }, [storedData])

  const updateStored = ( modelName, key, v) => {
    const updatedValue = {...value}

    if (typeof updatedValue === 'undefined') { updatedValue = { modelName } }
    updatedValue[key] = v

    if (JSON.stringify(updatedValue) !== JSON.stringify(value)) {
      setValue(updatedValue)
    }
  }

  const removePosition = (models) => {
    if (typeof models[model] !== 'undefined' && typeof models[model].static !== 'undefined') {
      models[model].static = models[model].static.filter(field => field.name !== 'position')
    }
    return models
  }

  const input = (
    <div className={`border-2 border-black rounded `}>
      <div className={`flex items-center justify-between p-2 text-white bg-black select-none md:px-4 ${dropdown && 'cursor-pointer'}`} onClick={() => { if (dropdown) {setShowContent(!showContent)} }}>
        <span>{label}</span>
        {dropdown &&
          <div className={`w-4`}>
            <svg className="fill-current" style={{ transform: `${showContent? 'rotate(-90deg)' : 'rotate(90deg)'}`}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.002 512.002"><path d="M388.425 241.951L151.609 5.79c-7.759-7.733-20.321-7.72-28.067.04-7.74 7.759-7.72 20.328.04 28.067l222.72 222.105-222.728 222.104c-7.759 7.74-7.779 20.301-.04 28.061a19.8 19.8 0 0014.057 5.835 19.79 19.79 0 0014.017-5.795l236.817-236.155c3.737-3.718 5.834-8.778 5.834-14.05s-2.103-10.326-5.834-14.051z"/></svg>
          </div>
        }
      </div>
      <div className={`${dropdown && !showContent? 'h-0' : ''} overflow-hidden bg-grayLight`}>
        <div className="p-2 md:p-4">
          <Form
            innerForm={true}
            parentIsSorting={false}
            onChange={(n, v) => updateStored(model, n, v)}
            showErrors={showErrors}
            name={model}
            data={{ models, stored: value, languages, find, files }} 
            readMode={readMode}
            timezone={timezone}
          />
        </div>
      </div>
    </div>
  )

  return translatable ? (
    <LanguageSelector
      language={language}
      onChangeLanguage={v => setLanguage(v)}
    >
      {input}
    </LanguageSelector>
  ) : (
    input
  )
}

export default Model
