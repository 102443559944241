import React, { useState } from 'react'
import {
  useHistory,
  Link,
} from 'react-router-dom'
import Text from './Form/Text'
import Container from './Container'
import flatten from './Lib/flatten'

const Recovery = () => {
  const history = useHistory()
  const [data, setData] = useState({})
  const [errorMessage, setErrorMessage] = useState(false)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [loading, setLoading] = useState(false)
  const [emailSend, setEmailSend] = useState(false)

  const updateData = (key, value, errs = []) => {
    data[key] = value
    setData({ ...data })
    errors[key] = errs
    setErrors({ ...errors })
  }

  const send = () => {
    setErrorMessage(false)
    const errs = flatten(errors)
    if (Object.keys(data).length === 0) {
      setShowErrors(true)
      setErrorMessage('The username field is empty')
      return
    }
    if (Object.keys(errs).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}session-recover-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: data.username,
        })
      })
        .then(response => response.json()
          .then(data => ({ status: response.status, body: data }))
        )
        .then(response => {
          setLoading(false)
          if (response.status === 200) {
            setEmailSend(true)
            history.push('/reset')
          } else {
            setErrorMessage(response.body.error)
          }
        })
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4 bg-white md:w-1/2 lg:w-1/4">
        <h2 className="mb-4 text-xl text-center wght-semibold">Password recovery</h2>
        <div>
          <div className="mb-4">
            <Text
              storedData={{}}
              showErrors={showErrors}
              onChange={updateData}
              name="username"
              translatable={false}
              options={{ label: 'Username' }}
              validations={{ not_blank: 'Field required' }}
            />
          </div>
          {errorMessage && <span className="block mt-4 text-error">{errorMessage}</span>}
        </div>
        <div className="flex items-end justify-center mt-4">
          {loading
            ? <span className="wght-bold">Loading...</span>
            :
            <div>
              { emailSend
                ? <span className="wght-bold">Check your email to continue</span>
                : <span onClick={send} className="cursor-pointer wght-bold hover:wght-semibold hover:text-primary">Recover password &#8599;</span>
              }
            </div>
          }
        </div>
      </div>
      <div className="flex items-end mt-4">
        <Link
          to={`/login`}
          className="text-xs cursor-pointer hover:wght-semibold hover:text-primary"
          >Cancel
        </Link>
      </div>
    </div>
  )
}

export default Recovery