import React, { useState, useEffect } from 'react'
import { Modal } from "./Modal"
import Import from "./Import"
import ShowOptionsForm from "./ShowOptionsForm"
import { useSession } from '../contexts/AuthContext'
import * as CSV from 'csv-string';
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import Button from './Button'

function isCsvValue(csvContent) {
  return Array.isArray(csvContent) && csvContent.every(row => Array.isArray(row));
}

function createCsvFile(csvContent) {
  const csv = CSV.stringify(csvContent);
  const blob = new Blob([csv], { type: 'text/csv' });
  return blob
}

const GuestsInvitationsImport = ({
  showImportModal,
  setShowImportModal,
  uploading,
  setUploading,
  GuestsQuota,
  getData,
  entityId,
  quotasAvailables,
  loading,
  setLoading,
  GuestsEvent,
  filters,
  getParsedPathname,
}) => {
  const [importData, setImportData] = useState()
  const [importErrors, setImportErrors] = useState([])
  const [invitationsCreated, setInvitationsCreated] = useState()
  const [textareaValue, setTextareaValue] = useState('')
  const [url, setUrl] = useState(getParsedPathname())
  const token = useSession()
  const history = useHistory()

  useEffect(() => {
    setInvitationsCreated()
    setImportErrors([])
    if (!showImportModal) {
      setTextareaValue('')
    }
  }, [showImportModal])


  const handleParceTextToCsv = (e) => {
    setTextareaValue(e.target.value)
    const csvContent = CSV.parse(e.target.value);

    if (isCsvValue(csvContent)) {
      const csv = createCsvFile(csvContent)

      setImportData((importDataState) => {
        importDataState.stored.currentFile = csv
        return { ...importDataState }
      })
    }
  }

  const importInvitations = () => {
    setImportErrors([])
    setInvitationsCreated()
    if (!uploading && importData?.stored) {
      const { defaultlanguage, ticketType, currentFile } = importData.stored
      if (!defaultlanguage || ticketType === -1 || !currentFile) {
        return alert('Language, ticket type and CSV file are required')
      } else {
        setUploading(true)
        const formData = new FormData()
        formData.append(`file`, currentFile)
        formData.append(`defaultlanguage`, defaultlanguage)
        formData.append(`ticketType`, ticketType)
        formData.append(`organization`, Cookies.get('organization'))
        formData.append(`event`, GuestsEvent || Cookies.get('event'))
        formData.append(`modelName`, 'GuestsInvitation')
        formData.append('quotaSlug', GuestsQuota)
        formData.append('entity', entityId)
        formData.append('quotasAvailable', quotasAvailables)
        formData.append('filters', filters)
        formData.append('url', url)

        fetch(`${process.env.REACT_APP_API_URL}import-guests-invitation`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData
        }).then(response => response
          .json()
          .then(data => ({ status: response.status, body: data })))
          .then(response => {
            setUploading(false)
            setImportErrors(response.body.errors || [])
            if (response.status === 200) {
              setInvitationsCreated(response.body.result.insertedCount)
              getData(false)
              setTextareaValue('')
              const rawImportData = importData
              rawImportData.stored.currentFile = null
              setImportData(rawImportData)
            } else {
              console.log('Error', response.status, response.body.error)
              if (response.status === 403) {
                Cookies.remove('user')
                history.push('/login')
              } else if (response.status === 401) {
                history.push(`/admin/no-access?url=${window.location.pathname}`)
              } else if (response.body.error === "csv-parser") {
                setImportErrors([{
                  message: "Data format error"
                }])
              } else if (response.body.error === "quotas-limit-exceeded") {

                setImportErrors([{
                  message: `Quotas limit exceeded, only ${quotasAvailables} can be created`
                }])
              }
            }
          }).catch(err => {
            console.log('Error', err)
          })
      }
    }
  }

  if (!showImportModal) return null

  return (
    <Modal setShowModal={setShowImportModal} showModal={showImportModal}>
      <div className="relative w-2/3 pb-0 overflow-auto bg-white z-1 max-h-132">
        <div className="flex items-center justify-between w-full p-8 pb-2 h-fit">
          <h2 className="text-xl wght-semibold">Import invitations</h2>
          {textareaValue.length === 0 &&
            <Import
              importData={importData}
              setImportData={setImportData}
            >
              <span className="text-sm cursor-pointer text-primary hover:wght-semibold">
                Add CSV
              </span>
            </Import>}
        </div>


        <ShowOptionsForm
          modelsToShow={["ticketType", "defaultlanguage"]}
          modelName={'GuestsInvitation'}
          importData={importData}
          setImportData={setImportData}
          setLoading={setLoading}
          url={url}
        >
          <div className="px-4 h-1/2">
            <label>Add Excel Invitations</label>
            <textarea
              rows="8"
              readOnly={false}
              name="Guests Invitations"
              className="w-full px-2 py-1 rounded outline-none resize-y bg-grayLight h-1/2 "
              placeholder="Copy and paste Excel text"
              onChange={handleParceTextToCsv}
              value={textareaValue}
            />
          </div>
        </ShowOptionsForm>


        {
          importErrors.length > 0 &&
          <div className="px-8 text-sm center text-red wght-semibold">
            <p className="">Errors: </p>
            <ul>
              {importErrors.map((error, index) => (
                <li key={index} className="mr-2">{error.message}</li>
              ))}
            </ul>
          </div>
        }

        <div className={`sticky bottom-0 left-0 flex items-end ${typeof invitationsCreated === "number" ? "justify-between" : "justify-end"} p-8 text-xs bg-white border-grayLight`}>
          {typeof invitationsCreated === "number" &&
            < span className="left-0 text-sm text-green-500 wght-semibold">
              {invitationsCreated} invitations created
            </span>
          }
          {
            uploading ?
              <div>
                <span className="px-2 py-1 text-xs border rounded cursor-pointer text-primary bg-primaryLighter border-primary">
                  Uploading...
                </span>
              </div>
              :
              <div className='flex'>
                <Button visibility={!loading} className='mr-1 text-base' onClick={() => {
                  setImportErrors([])
                  setInvitationsCreated(false)
                  setShowImportModal(false)
                }} color="error">Cancel</Button>
                <Button visibility={!loading} className='text-base' onClick={() => importInvitations()}>Import</Button>
              </div>
          }
        </div>
      </div>
    </Modal >
  )
}

export default GuestsInvitationsImport