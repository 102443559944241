import React, { useState } from 'react'
import Cookies from "js-cookie"
import { useSession } from "../contexts/AuthContext"
import { useHistory } from "react-router-dom"


const Import = (props) => {
  const {
    importData,
    setImportData,
  } = props
  const [uploading, setUploading] = useState(false)
  const token = useSession()
  const history = useHistory()

  const handleChange = (e) => {
    const rawData = importData
    rawData.stored.currentFile = e.target.files[0]
    setImportData({ ...rawData })
  }

  const children = uploading && props.loading ? props.uploading : props.children

  return (
    <label className='flex flex-col items-end'>
      {children}
      <span className="block text-xs">{importData?.stored?.currentFile?.name}</span>
      <input
        onChange={handleChange}
        type="file"
        name="file"
        value=""
        title="&nbsp;"
        style={{ display: 'none' }}
      />
    </label>
  )
}

export default Import