import React, { useEffect, useState } from 'react'

const TicketTypeSelector = ({
  storedData,
  onChange: updateStored,
  name,
  translatable,
  options: { label },
  readMode,
  timezone,
  find,
}) => {
  const { TicketType: ticketTypes } = find ?? {}
  const [value, setValue] = useState(storedData[name] || [])

  
  const shouldAddMultipleValue = (event) => {
    const rawValue = [...value]
    rawValue.push(event.target.value)
    setValue(rawValue)
  }

  useEffect(() => {
    updateStored(name, value)
  }, [value])

  const ListItem = () => (
    <div className="w-full p-4 my-2 rounded bg-grayLight" >
      {ticketTypes &&
        <div className="flex w-full">
          <div className="flex flex-col w-1/2 pr-4">
            <p className="pr-4">Ticket Type</p>
            <select
              name="ticketType"
              type="text"
              placeholder="TicketType"
              value={-1}
              onChange={(event) => shouldAddMultipleValue(event)}
              className={`w-full rounded px-2 py-1 outline-none placeholder-gray`}
              disabled={readMode}
            >
              <option value="-1">Select</option>
              {ticketTypes && Array.isArray(ticketTypes) && ticketTypes.map((ticketType, index) =>
                <option
                  key={index}
                  value={ticketType._id}
                  disabled={value?.includes(ticketType._id)}
                >
                  {ticketType.title}
                </option>
              )}
              {ticketTypes && !Array.isArray(ticketTypes) && <option value={ticketTypes._id}>{ticketTypes.title}</option>}
            </select>
            {ticketTypes && value?.length > 0 && ticketTypes.length > 0 && (
              <div className="flex flex-wrap mt-2">
                {value?.map(id => multipleOption(ticketTypes.find(item => item._id === id)))}
              </div>
            )}
          </div>
        </div>
      }
    </div>
  )

  const multipleOption = (option) => {
    if (option) {
      return (
        <div
          key={option}
          className="px-1 mb-1 mr-1 text-xs border border-black rounded grow-0 shrink-0"
        >
          {option ? option.title : `Not found ${option}`}
          <span className="pl-1 cursor-pointer" onClick={() => removeItem(option)}>
            ✕
          </span>
        </div>
      )
    }
  }

  const removeItem = (option) => {
    if (readMode) return
    const rawValue = value.filter(item => item !== option._id)
    setValue(rawValue)
  }


  if (storedData && storedData.eventId) {
    return (
      <div>
        <div className="flex flex-row items-end justify-between">
          <label>{label}</label>
        </div>
        <ListItem />
      </div>
    )
  } 
  return null
}

export default TicketTypeSelector
