import Cookies from 'js-cookie'

const redirectGuestsURL = async ({
  GuestsEvent,
  menuEventsOptions,
  setSelectedMenuEvent,
  getData,
  endpoint,
  token,
  modelName,
  history,
}) => {
  const event = menuEventsOptions.find((event) => event.slug === GuestsEvent)
  if (event) {
    setSelectedMenuEvent(event)
    Cookies.set('event', event.value)
    getData()
  } else {
    // Fetch endpoint to search event slug in all user organizations. If found, save organization to cookies, relist events and save event to cookies.
    fetch(`${process.env.REACT_APP_API_URL}get-guests-event`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        eventSlug: GuestsEvent,
        endpoint,
        modelName,
        url: history.location.pathname,
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        if (response.status === 200) {
          const { event, organization } = response.body.result
          Cookies.set('event', event)
          Cookies.set('organization', organization)
          window.location.reload()
        } else {
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }
}


const normalizeTitle = (title) => {
  return title.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
}

const getModelName = ((params) => {
  const {
    GuestsEvent,
    GuestsCategory,
    GuestsEntity,
    GuestsQuota,
    GuestsInvitation,
  } = params

  if (GuestsInvitation && isNaN(GuestsInvitation)) {
    return {
      initialModelName: 'GuestsInvitation',
      initialTitle: `${normalizeTitle(GuestsQuota)} - Invitation`,
    }
  } else if (GuestsQuota && isNaN(GuestsQuota)) {
    return {
      initialModelName: 'GuestsInvitation',
      initialTitle: `${normalizeTitle(GuestsQuota)} - Invitations`,
    }
  } else if (GuestsEntity && isNaN(GuestsEntity)) {
    return {
      initialModelName: 'GuestsQuota',
      initialTitle: `${normalizeTitle(GuestsEntity)} - Quotas`,
    }
  } else if (GuestsCategory && isNaN(GuestsCategory)) {
    return {
      initialModelName: 'GuestsEntity',
      initialTitle: `${normalizeTitle(GuestsCategory)} - Entities`,
    }
  } else if (GuestsEvent && isNaN(GuestsEvent)) {
    return {
      initialModelName: 'GuestsCategory',
      initialTitle: 'Guests - Entity Categories',
    }
  } else {
    return {
      initialModelName: 'GuestsEvent',
      initialTitle: 'Guests - Events',
    }
  }
})

export {
  redirectGuestsURL,
  getModelName,
}
