import React, { useState, useEffect } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import useLocalStorage from '../../hooks/useLocalStorage'
import dayjs from 'dayjs'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'

const Users = () => {
  const { name: modelName, id, page: pageParam, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(`Users`)
  const [page, setPage] = useState(pageParam - 1 || 0)
  const pageLimit = 50
  const [hasMorePages, setHasMorePages] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const token = useSession()

  const [userSortSettings, setUserSortSettings] = useLocalStorage(
    'user_sort_settings',
    {}
  )
  const [sortedValue, setSortedValue] = useState()
  const [sortAscending, setSortAscending] = useState(false)
  const [sortedItems, setSortedItems] = useState()

  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [role, setRole] = useState(false)


  useEffect(() => {
    if (loading) {
      getData()
    }
  }, [loading])

  useEffect(() => {
    setLoading(true)

    const urlParts = history.location.pathname.split('users')
    if (page) {
      const updatePageUrl = `${urlParts[0]}users/${page + 1}`
      history.replace({ pathname: updatePageUrl })
    } else {
      const updatePageUrl = `${urlParts[0]}users`
      history.replace({ pathname: updatePageUrl })
    }
  }, [page, startDate, endDate])

  const getData = () => {
    const body = JSON.stringify({
      modelName,
      searchValue,
      page,
      pageLimit,
      organization: getOrganization(),
      event: getEvent(),
    })

    fetch(`${process.env.REACT_APP_API_URL}users`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
          setData(response.body)
          if (response.body && response.body.users) {
            setHasMorePages(response.body.users.length === pageLimit)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          setData()
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
          if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
      .catch((error) => {
        console.log('Error', error)
        setLoading(false)
        setData()
      })
  }

  useEffect(() => {
    const settings = { ...userSortSettings }
    if (
      typeof sortedValue === 'undefined' ||
      typeof sortAscending === 'undefined'
    ) {
      delete settings[modelName]
    } else {
      settings[modelName] = {
        value: sortedValue,
        ascending: sortAscending,
      }
    }
    setUserSortSettings(settings)
  }, [sortedValue, sortAscending])

  const handleSort = (key) => {
    if (sortedValue === key) {
      if (sortAscending) {
        setSortAscending(false)
      } else {
        setSortedValue(undefined)
        setSortAscending(undefined)
      }
    } else {
      setSortedValue(key)
      setSortAscending(true)
    }
  }

  useEffect(() => {
    if (
      typeof data === 'object' &&
      typeof data.users !== 'undefined' &&
      data.users
    ) {
      const users = [...data.users]
      if (typeof sortedValue !== 'undefined') {
        const sorted = users.sort((a, b) => {
          const itemA =
            typeof a[sortedValue] == 'string'
              ? a[sortedValue].toLowerCase()
              : undefined
          const itemB =
            typeof b[sortedValue] == 'string'
              ? b[sortedValue].toLowerCase()
              : undefined

          if (typeof itemA === 'undefined' && typeof itemB === 'undefined')
            return 0
          if (typeof itemA === 'undefined') return sortAscending ? 1 : -1
          if (typeof itemB === 'undefined') return sortAscending ? -1 : 1

          if (itemA < itemB) return sortAscending ? -1 : 1
          if (itemA > itemB) return sortAscending ? 1 : -1
          return 0
        })
        setSortedItems(sorted)
      } else {
        setSortedItems(data.users)
      }
    }
  }, [data, sortedValue, sortAscending, modelName])


  const clearFilters = () => {
    setSearchValue('')
    filterUsers()
  }

  const filterUsers = () => {
    setPage(0)
    setLoading(true)
  }
  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      <div className="mt-8">
        <div className="flex flex-wrap items-center my-4">
          <div className="w-full mb-2 sm:mb-0 sm:w-auto">
            <div className="flex flex-col md:flex-row">
              <input
                name="search"
                className={`rounded px-2 bg-white outline-none placeholder-gray mr-2`}
                type="text"
                placeholder="Filter value"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
          {loading ? <div>Loading...</div> : <>
            <span className="mr-2 cursor-pointer hover:wght-semibold" onClick={() => filterUsers()}>Filter</span>
            <span className="mr-2">|</span>
            <span className="mr-2 cursor-pointer hover:wght-semibold" onClick={() => clearFilters()}>Reset filter</span>
          </>}
        </div>
      </div>


      {(!data || !data.users || data.users.length === 0) && !loading && <div className="mt-8">Nothing to list for this event</div>}
      {data && data.users && data.users.length > 0 && (
        <div className="mt-8 overflow-x-scroll">
          <table className="w-full">
            <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
              <tr>
                <th className="px-4 py-2">
                  <span
                    className="relative cursor-pointer wght-semibold"
                    onClick={() => handleSort('name')}
                  >
                    Name
                  </span>
                  {sortedValue === 'name' && (
                    <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                  )}
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer wght-semibold" onClick={() => handleSort('email')}>Email</span>
                  {sortedValue === 'email' && (<span className="ml-2">{sortAscending ? '↑' : '↓'}</span>)}
                </th>
                <th className="px-4 py-2">
                  <span className="relative cursor-pointer wght-semibold" onClick={() => handleSort('email')}>Birthdate</span>
                  {sortedValue === 'phone' && (<span className="ml-2">{sortAscending ? '↑' : '↓'}</span>)}
                </th>
                <th className="px-4 py-2">
                  <span className="relative wght-semibold">Actions</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedItems &&
                sortedItems.map((info, index) => (
                  <tr
                    key={index}
                    className="relative text-sm text-left bg-white border-b border-grayLight hover:bg-primaryLight wght-light"
                  >
                    <th className="px-4 py-2">
                      <Link
                        to={info.link}
                        className="text-xs cursor-pointer hover:wght-semibold hover:text-primary"
                      >
                        {info.name}&nbsp;{info.lastName}
                      </Link>
                    </th>
                    <th className="px-4 py-2">
                      <a href={`mailto:${info.email}`} title={`Mail to ${info.email}`}>{info.email}</a>
                    </th>
                    <th className="px-4 py-2">
                      {dayjs(info.birthDate).format('YYYY-MM-DD')}
                    </th>
                    <th className="px-4 py-2">
                      <Link
                        to={info.link}
                        className="text-xs cursor-pointer hover:wght-semibold hover:text-primary"
                      >
                        View
                      </Link>
                    </th>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {(hasMorePages || page > 0) && !loading && (
        <div className="flex justify-between mt-8">
          <div>
            {page > 0 && (
              <span
                onClick={() => setPage(page - 1)}
                className="ml-1 cursor-pointer hover:wght-semibold"
              >
                Previous page
              </span>
            )}
          </div>
          {hasMorePages && (
            <span
              onClick={() => setPage(page + 1)}
              className="mr-1 cursor-pointer hover:wght-semibold"
            >
              Next page
            </span>
          )}
        </div>
      )}
    </div>
  )
}

export default Users
