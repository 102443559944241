import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const Time = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label },
  validations,
  languages,
  innerForm,
  readMode,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')

  const onChangeText = e => {
    if (translatable) {
      value[language] = e.target.value
      setValue({ ...value })
    } else {
      setValue(e.target.value)
    }
    setCurrentValue(e.target.value)
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [language, translatable, value])

  useEffect(() => {
    setValue(name, storedData, translatable)
  }, [storedData])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const input = (
    <input
      name={name}
      className={`w-full rounded px-2 py-1 ${
        showErrors && errors.length > 0 ? 'border border-error' : ''
      } ${
        translatable && Object.keys(languages)[0] === language
          ? 'rounded-tl-none'
          : ''
      } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
      type="time"
      placeholder={label}
      value={currentValue}
      onChange={onChangeText}
      disabled={readMode}
    />
  )

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {input}
        </LanguageSelector>
      ) : (
        input
      )}
    </div>
  )
}

export default Time
