import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'
import generatePublicId from '../Lib/generatePublicId'

const Text = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label, readOnly = false, randomGenerator = false },
  validations,
  languages,
  innerForm,
  readMode,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')

  const onChangeText = e => {
    if (translatable) {
      value[language] = `${e.target.value}`
      setValue({ ...value })
    } else {
      setValue(`${e.target.value}`)
    }
    setCurrentValue(e.target.value)
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [language, translatable, value])

  useEffect(() => {
    if(storedData[name]) setValue(defaultValue(name, storedData, translatable))
  }, [storedData])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const input = (
    <input
      readOnly={readMode || readOnly}
      name={name}
      className={`w-full rounded ${
        translatable && Object.keys(languages)[0] === language
          ? 'rounded-tl-none'
          : ''
      } px-2 py-1 ${
        showErrors && errors.length > 0 ? 'border border-error' : ''
      } ${innerForm ? 'bg-white' : 'bg-grayLight'} ${readOnly ? 'opacity-50' : ''} outline-none placeholder-gray`}
      type="text"
      placeholder={label}
      value={currentValue}
      onChange={onChangeText}
    />
  )

  const handleGenerateRandom = () => {
    const randomString = generatePublicId()
    if (currentValue && currentValue !== '') {
      setCurrentValue(`${currentValue}.${randomString}`)
      setValue(`${currentValue}.${randomString}`)
    } else {
      setCurrentValue(`${randomString}`)
      setValue(`${randomString}`)
    }
  }

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {input}
        </LanguageSelector>
      ) : (
        input
      )}
      {!readMode && randomGenerator &&
        <div className='flex justify-start'>
          <span onClick={handleGenerateRandom} className='text-xs cursor-pointer hover:wght-semibold hover:text-primary'>Add random id</span>
        </div>
      }
      {showErrors && errors.length > 0 && 
        <p className="text-error">{errors[0]}</p>
      }
    </div>
  )
}

export default Text
